import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  AppBar,
  Toolbar,
  Paper,
  Grid,
  Button,
  TextField,
  Tooltip,
  IconButton,
  Switch,
  FormControlLabel,
  TableFooter,
  TablePagination,
} from "@material-ui/core";
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import { Delete, Edit, Add, Search, Refresh } from "@material-ui/icons";
import Axios from "axios";
import { IdentityConstants } from "../../helpers/IdentityConstants";
import { AuthService } from "../../services/AuthService";
import { MessageInfo } from "../MessageInfo";
import { MessageForAction } from "../MessageForAction";
import Helpers from "../../helpers/Helpers";
import { IBuildingTypeState } from "./componentStates/IBuildingTypeState";
import { AppConstants } from "../../helpers/AppConstants";
import _ from "lodash";

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      maxWidth: 936,
      margin: "auto",
      overflow: "hidden",
    },
    searchBar: {
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    },
    searchInput: {
      fontSize: theme.typography.fontSize,
    },
    block: {
      display: "block",
    },
  });

export interface IBuildingTypeComponentProps
  extends WithStyles<typeof styles> {}

class BuildingType extends React.Component<
  IBuildingTypeComponentProps,
  IBuildingTypeState
> {
  private authService: AuthService;
  constructor(props: IBuildingTypeComponentProps) {
    super(props);
    this.authService = new AuthService();
    this.state = {
      buildingTypes: [],
      newBuildingTypeData: {
        name: "",
        isActive: true,
      },
      editBuildingTypeData: {
        id: "",
        name: "",
        isActive: true,
      },
      deleteBuildingTypeData: {
        id: "",
        name: "",
      },
      isNewBuildingTypeModalOpen: false,
      isEditBuildingTypeModalOpen: false,
      isBuildingTypeDeleteConfirmModalOpen: false,
      pageNumber: 0,
      buildingTypesCount: 0,
      searchText: "",
      BuildingTypeMessages: [],
      isErrorDialogOpen: false,
    };
    this.debounceRefreshBuildingTypes = _.debounce(
      this.debounceRefreshBuildingTypes,
      1000
    );
  }

  debounceRefreshBuildingTypes() {
    this.refreshBuildingTypes();
  }

  componentDidMount() {
    this.refreshTableData();
  }

  refreshTableData() {
    this.refreshBuildingTypes();
  }

  refreshBuildingTypes() {
    this.setState({ BuildingTypeMessages: [] });
    this.authService.getUser().then((user) => {
      if (user && user.access_token) {
        const headers = Helpers.getHeaders(user.access_token);
        let searchDTO = {
          searchText: this.state.searchText,
          pageNumber: this.state.pageNumber,
          pageSize: AppConstants.TablePageSize,
        };
        Axios.post(
          `${IdentityConstants.apiRoot}BuildingType/GetItems`,
          searchDTO,
          { headers }
        )
          .then((response) => {
            this.setState({
              buildingTypes: response.data.items,
              buildingTypesCount: response.data.searchItemsCount,
            });
          })
          .catch((error) => {
            this.handleCatch(error, MessageForAction.Read);
            this.setState({ isErrorDialogOpen: true });
          });
      }
    });
  }

  removeMessages(actionType: MessageForAction) {
    let filteredMessages = this.state.BuildingTypeMessages.filter(
      (m) => m.messageForAction !== actionType
    );
    this.setState({ BuildingTypeMessages: filteredMessages });
  }

  toggleNewBuildingTypeModal() {
    this.setState({
      isNewBuildingTypeModalOpen: !this.state.isNewBuildingTypeModalOpen,
    });
  }

  toggleEditBuildingTypeModal() {
    this.setState({
      isEditBuildingTypeModalOpen: !this.state.isEditBuildingTypeModalOpen,
    });
    this.removeMessages(MessageForAction.Update);
  }

  toggleDeleteBuildingTypeModal() {
    this.setState({
      isBuildingTypeDeleteConfirmModalOpen: !this.state
        .isBuildingTypeDeleteConfirmModalOpen,
    });
    this.removeMessages(MessageForAction.Delete);
  }

  addNewBuildingType() {
    this.removeMessages(MessageForAction.Create);
    this.authService.getUser().then((user) => {
      if (user && user.access_token) {
        const headers = Helpers.getHeaders(user.access_token);
        Axios.post(
          `${IdentityConstants.apiRoot}BuildingType`,
          this.state.newBuildingTypeData,
          { headers }
        )
          .then((_) => {
            this.refreshTableData();
            this.setState({
              isNewBuildingTypeModalOpen: false,
              newBuildingTypeData: {
                name: "",
                isActive: true,
              },
            });
          })
          .catch((error) => {
            this.handleCatch(error, MessageForAction.Create);
          });
      }
    });
  }

  updateBuildingType() {
    this.removeMessages(MessageForAction.Update);
    this.authService.getUser().then((user) => {
      if (user && user.access_token) {
        const headers = Helpers.getHeaders(user.access_token);
        let { id, name, isActive } = this.state.editBuildingTypeData;
        Axios.put(
          `${IdentityConstants.apiRoot}BuildingType/${id}`,
          { name, isActive },
          { headers }
        )
          .then((_) => {
            this.refreshBuildingTypes();
            this.setState({
              isEditBuildingTypeModalOpen: false,
              editBuildingTypeData: {
                id: "",
                name: "",
                isActive: true,
              },
            });
          })
          .catch((error) => {
            this.handleCatch(error, MessageForAction.Update);
          });
      }
    });
  }

  deleteBuildingType() {
    this.removeMessages(MessageForAction.Delete);
    this.authService.getUser().then((user) => {
      if (user && user.access_token) {
        const headers = Helpers.getHeaders(user.access_token);
        let { id } = this.state.deleteBuildingTypeData;
        Axios.delete(`${IdentityConstants.apiRoot}BuildingType/${id}`, {
          headers,
        })
          .then((_) => {
            this.refreshTableData();
            this.setState({
              isBuildingTypeDeleteConfirmModalOpen: false,
              deleteBuildingTypeData: {
                id: "",
                name: "",
              },
            });
          })
          .catch((error) => {
            this.handleCatch(error, MessageForAction.Delete);
          });
      }
    });
  }

  editBuildingTypeSetState(id: any, name: any, isActive: boolean) {
    let { editBuildingTypeData } = this.state;
    editBuildingTypeData.id = id;
    editBuildingTypeData.name = name;
    editBuildingTypeData.isActive = isActive;
    this.setState({
      editBuildingTypeData,
      isEditBuildingTypeModalOpen: !this.state.isEditBuildingTypeModalOpen,
    });
  }

  deleteBuildingTypeSetState(id: any, name: any) {
    let { deleteBuildingTypeData } = this.state;
    deleteBuildingTypeData.id = id;
    deleteBuildingTypeData.name = name;
    this.setState({
      deleteBuildingTypeData,
      isBuildingTypeDeleteConfirmModalOpen: !this.state
        .isBuildingTypeDeleteConfirmModalOpen,
    });
  }

  handleChangePage(
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) {
    this.setState({ pageNumber: newPage });
    this.debounceRefreshBuildingTypes();
  }

  public render() {
    const { classes } = this.props;
    let BuildingTypes = this.state.buildingTypes.map((BuildingType) => {
      return (
        <TableRow key={BuildingType.id}>
          <TableCell>
            <Tooltip title="Edit">
              <IconButton
                onClick={this.editBuildingTypeSetState.bind(
                  this,
                  BuildingType.id,
                  BuildingType.name,
                  BuildingType.isActive
                )}
              >
                <Edit cursor="pointer" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton
                onClick={this.deleteBuildingTypeSetState.bind(
                  this,
                  BuildingType.id,
                  BuildingType.name
                )}
              >
                <Delete cursor="pointer" />
              </IconButton>
            </Tooltip>
          </TableCell>
          <TableCell>{BuildingType.name}</TableCell>
          <TableCell>
            <Switch
              checked={BuildingType.isActive}
              color="primary"
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </TableCell>
        </TableRow>
      );
    });
    return (
      <Paper className={classes.paper}>
        <AppBar
          className={classes.searchBar}
          position="static"
          color="default"
          elevation={0}
        >
          <Toolbar>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <Search className={classes.block} color="inherit" />
              </Grid>
              <Grid item xs>
                <TextField
                  fullWidth
                  placeholder="Search by name"
                  value={this.state.searchText}
                  onChange={(e) => {
                    this.setState({ searchText: e.target.value });
                  }}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      this.setState({ pageNumber: 0 });
                      this.refreshTableData();
                      e.preventDefault();
                    }
                  }}
                  InputProps={{
                    disableUnderline: true,
                    className: classes.searchInput,
                  }}
                />
              </Grid>
              <Grid item>
                <Tooltip title="Add">
                  <IconButton
                    onClick={this.toggleNewBuildingTypeModal.bind(this)}
                  >
                    <Add />
                  </IconButton>
                </Tooltip>
                <Dialog
                  open={this.state.isNewBuildingTypeModalOpen}
                  onClose={this.toggleNewBuildingTypeModal.bind(this)}
                  aria-labelledby="form-dialog-title"
                >
                  <DialogTitle id="form-dialog-title">
                    Add BuildingType
                  </DialogTitle>
                  <DialogContent>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <TextField
                          autoFocus
                          margin="dense"
                          id="addBuildingName"
                          label="Building name"
                          fullWidth
                          value={this.state.newBuildingTypeData.name}
                          onChange={(e) => {
                            let { newBuildingTypeData } = this.state;
                            newBuildingTypeData.name = e.target.value;
                            this.setState({ newBuildingTypeData });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          label="IsActive"
                          labelPlacement="start"
                          style={{ marginLeft: 0 }}
                          control={
                            <Switch
                              checked={this.state.newBuildingTypeData.isActive}
                              value={this.state.newBuildingTypeData.isActive}
                              onChange={(e) => {
                                let { newBuildingTypeData } = this.state;
                                newBuildingTypeData.isActive = e.target.checked;
                                this.setState({ newBuildingTypeData });
                              }}
                              color="primary"
                              inputProps={{ "aria-label": "primary checkbox" }}
                            />
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        {Helpers.handleError(
                          MessageForAction.Create,
                          this.state.BuildingTypeMessages
                        )}
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      color="primary"
                      onClick={this.addNewBuildingType.bind(this)}
                    >
                      Add
                    </Button>
                    <Button
                      color="secondary"
                      onClick={this.toggleNewBuildingTypeModal.bind(this)}
                    >
                      Cancel
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  open={this.state.isBuildingTypeDeleteConfirmModalOpen}
                  onClose={this.toggleDeleteBuildingTypeModal.bind(this)}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  fullWidth={true}
                  maxWidth={"sm"}
                >
                  <DialogTitle id="form-dialog-title">
                    Delete BuildingType
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Delete {this.state.deleteBuildingTypeData.name}{" "}
                      BuildingType?
                    </DialogContentText>
                    {Helpers.handleError(
                      MessageForAction.Delete,
                      this.state.BuildingTypeMessages
                    )}
                  </DialogContent>
                  <DialogActions>
                    <Button
                      color="primary"
                      onClick={this.deleteBuildingType.bind(this)}
                    >
                      Yes, delete
                    </Button>
                    <Button
                      color="secondary"
                      onClick={this.toggleDeleteBuildingTypeModal.bind(this)}
                    >
                      Cancel
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  open={this.state.isEditBuildingTypeModalOpen}
                  onClose={this.toggleEditBuildingTypeModal.bind(this)}
                  aria-labelledby="form-dialog-title"
                >
                  <DialogTitle id="form-dialog-title">
                    Edit BuildingType
                  </DialogTitle>
                  <DialogContent>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <TextField
                          autoFocus
                          margin="dense"
                          id="editBuildingName"
                          label="Building name"
                          fullWidth
                          value={this.state.editBuildingTypeData.name}
                          onChange={(e) => {
                            let { editBuildingTypeData } = this.state;
                            editBuildingTypeData.name = e.target.value;
                            this.setState({ editBuildingTypeData });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          label="IsActive"
                          labelPlacement="start"
                          style={{ marginLeft: 0 }}
                          control={
                            <Switch
                              checked={this.state.editBuildingTypeData.isActive}
                              value={this.state.editBuildingTypeData.isActive}
                              onChange={(e) => {
                                let { editBuildingTypeData } = this.state;
                                editBuildingTypeData.isActive =
                                  e.target.checked;
                                this.setState({ editBuildingTypeData });
                              }}
                              color="primary"
                              inputProps={{ "aria-label": "primary checkbox" }}
                            />
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        {Helpers.handleError(
                          MessageForAction.Update,
                          this.state.BuildingTypeMessages
                        )}
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      color="primary"
                      onClick={this.updateBuildingType.bind(this)}
                    >
                      Update
                    </Button>
                    <Button
                      color="secondary"
                      onClick={this.toggleEditBuildingTypeModal.bind(this)}
                    >
                      Cancel
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  open={this.state.isErrorDialogOpen}
                  onClose={() => {
                    this.setState({
                      isErrorDialogOpen: false,
                      BuildingTypeMessages: [],
                    });
                  }}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  fullWidth={true}
                  maxWidth={"sm"}
                >
                  <DialogTitle id="form-dialog-title">
                    Error occurred
                  </DialogTitle>
                  <DialogContent>
                    {Helpers.handleError(
                      MessageForAction.Read,
                      this.state.BuildingTypeMessages
                    )}
                  </DialogContent>
                  <DialogActions>
                    <Button
                      color="secondary"
                      onClick={() => {
                        this.setState({
                          isErrorDialogOpen: false,
                          BuildingTypeMessages: [],
                        });
                      }}
                    >
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>
                <Tooltip title="Reload">
                  <IconButton onClick={this.refreshTableData.bind(this)}>
                    <Refresh className={classes.block} color="inherit" />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Action</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>IsActive</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{BuildingTypes}</TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10]}
                  count={this.state.buildingTypesCount}
                  rowsPerPage={10}
                  page={this.state.pageNumber}
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    native: true,
                  }}
                  onChangePage={this.handleChangePage.bind(this)}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Paper>
    );
  }

  private handleCatch(error: any, messageForAction: MessageForAction) {
    let { BuildingTypeMessages } = this.state;
    if (error.toString().includes("Network Error")) {
      BuildingTypeMessages.push(
        new MessageInfo(
          messageForAction,
          "Server unavailable, try again later",
          503
        )
      );
    } else if (
      error.toString().includes("Request failed with status code 401")
    ) {
      this.authService.login();
    } else if (
      error.toString().includes("Request failed with status code 500")
    ) {
      BuildingTypeMessages.push(
        new MessageInfo(messageForAction, error.toString(), 500)
      );
    } else if (error.response.data.hasOwnProperty("errors")) {
      error.response.data.errors.forEach((errorItem: any) => {
        BuildingTypeMessages.push(
          new MessageInfo(
            messageForAction,
            errorItem.message,
            error.response.status
          )
        );
      });
    } else if (error.response.data.hasOwnProperty("Message")) {
      BuildingTypeMessages.push(
        new MessageInfo(
          messageForAction,
          error.response.data.Message,
          error.response.status
        )
      );
    } else {
      BuildingTypeMessages.push(
        new MessageInfo(messageForAction, error, error.response.status)
      );
    }
    this.setState({ BuildingTypeMessages: BuildingTypeMessages });
  }
}

export default withStyles(styles)(BuildingType);
