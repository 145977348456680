import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  AppBar,
  Toolbar,
  Paper,
  Grid,
  Button,
  TextField,
  Tooltip,
  IconButton,
  Switch,
  FormControlLabel,
  TableFooter,
  TablePagination,
} from "@material-ui/core";
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Delete, Edit, Add, Search, Refresh } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import Axios from "axios";
import { IdentityConstants } from "../../helpers/IdentityConstants";
import { AuthService } from "../../services/AuthService";
import { MessageInfo } from "../MessageInfo";
import { MessageForAction } from "../MessageForAction";
import { ReferenceEntitySelector } from "../ReferenceEntitySelector";
import StateAndCities from "../../StateAndCities.json";
import Helpers from "../../helpers/Helpers";
import { IBuilding } from "./dataTransferableObjects/IBuilding";
import { IBuildingSave } from "./dataTransferableObjects/IBuildingSave";
import { IBuildingState } from "./componentStates/IBuildingState";
import { AppConstants } from "../../helpers/AppConstants";
import _ from "lodash";

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      margin: "auto",
      overflow: "hidden",
    },
    searchBar: {
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    },
    searchInput: {
      fontSize: theme.typography.fontSize,
    },
    block: {
      display: "block",
    },
  });

export interface IBuildingComponentProps extends WithStyles<typeof styles> {}

class Building extends React.Component<
  IBuildingComponentProps,
  IBuildingState
> {
  private authService: AuthService;
  constructor(props: IBuildingComponentProps) {
    super(props);
    this.authService = new AuthService();
    this.state = {
      buildings: [],
      newBuildingData: this.resetBuilding(),
      editBuildingData: this.resetBuilding(),
      deleteBuildingData: {
        id: "",
        name: "",
      },
      isNewBuildingModalOpen: false,
      isEditBuildingModalOpen: false,
      isBuildingDeleteConfirmModalOpen: false,
      isStateDropDownOpenNew: false,
      isCityDropDownOpenNew: false,
      isStateDropDownOpenEdit: false,
      isCityDropDownOpenEdit: false,
      pageNumber: 0,
      buildingsCount: 0,
      searchText: "",
      BuildingMessages: [],
      isErrorDialogOpen: false,
    };
    this.debounceRefreshBuildings = _.debounce(
      this.debounceRefreshBuildings,
      1000
    );
  }

  debounceRefreshBuildings() {
    this.refreshBuildings();
  }

  resetBuilding() {
    let data: IBuilding = {
      id: "",
      name: "",
      establishmentDate: null,
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      postal: "",
      phoneNumber: "",
      latitude: 0,
      longitude: 0,
      district: null,
      buildingType: null,
      isActive: true,
    };
    return data;
  }

  componentDidMount() {
    this.refreshTableData();
  }

  removeMessages(actionType: MessageForAction) {
    let filteredMessages = this.state.BuildingMessages.filter(
      (m) => m.messageForAction !== actionType
    );
    this.setState({ BuildingMessages: filteredMessages });
  }

  handleNewBuildingTypeSelect = (selectedBuildingType: any) => {
    let { newBuildingData } = this.state;
    newBuildingData.buildingType = selectedBuildingType;
    this.setState({ newBuildingData });
  };

  handleNewDistrictSelect = (selectedDistrictType: any) => {
    let { newBuildingData } = this.state;
    newBuildingData.district = selectedDistrictType;
    this.setState({ newBuildingData });
  };

  handleEditBuildingTypeSelect = (selectedBuildingType: any) => {
    let { editBuildingData } = this.state;
    editBuildingData.buildingType = selectedBuildingType;
    this.setState({ editBuildingData });
  };

  handleEditDistrictSelect = (selectedDistrictType: any) => {
    let { editBuildingData } = this.state;
    editBuildingData.district = selectedDistrictType;
    this.setState({ editBuildingData });
  };

  refreshTableData() {
    this.refreshBuildings();
  }

  refreshBuildings() {
    this.setState({ BuildingMessages: [] });
    this.authService.getUser().then((user) => {
      if (user && user.access_token) {
        const headers = Helpers.getHeaders(user.access_token);
        let searchDTO = {
          searchText: this.state.searchText,
          pageNumber: this.state.pageNumber,
          pageSize: AppConstants.TablePageSize,
        };
        Axios.post(`${IdentityConstants.apiRoot}Building/GetItems`, searchDTO, {
          headers,
        })
          .then((response) => {
            this.setState({
              buildings: response.data.items,
              buildingsCount: response.data.searchItemsCount,
            });
          })
          .catch((error) => {
            this.handleCatch(error, MessageForAction.Read);
            this.setState({ isErrorDialogOpen: true });
          });
      }
    });
  }

  toggleNewBuildingModal() {
    this.setState({
      isNewBuildingModalOpen: !this.state.isNewBuildingModalOpen,
    });
  }

  toggleEditBuildingModal() {
    this.setState({
      isEditBuildingModalOpen: !this.state.isEditBuildingModalOpen,
    });
    this.removeMessages(MessageForAction.Update);
  }

  toggleDeleteBuildingModal() {
    this.setState({
      isBuildingDeleteConfirmModalOpen: !this.state
        .isBuildingDeleteConfirmModalOpen,
    });
    this.removeMessages(MessageForAction.Delete);
  }

  addNewBuilding() {
    this.removeMessages(MessageForAction.Create);
    this.authService.getUser().then((user) => {
      if (user && user.access_token) {
        const headers = Helpers.getHeaders(user.access_token);
        Axios.post(
          `${IdentityConstants.apiRoot}Building`,
          this.CopyFrom(this.state.newBuildingData),
          { headers }
        )
          .then((_) => {
            this.refreshTableData();
            this.setState({
              isNewBuildingModalOpen: false,
              newBuildingData: this.resetBuilding(),
            });
          })
          .catch((error) => {
            this.handleCatch(error, MessageForAction.Create);
          });
      }
    });
  }

  CopyFrom(building: IBuilding): IBuildingSave {
    return {
      name: building.name,
      establishmentDate: building.establishmentDate,
      addressLine1: building.addressLine1,
      addressLine2: building.addressLine2,
      city: building.city,
      state: building.state,
      postal: building.postal,
      phoneNumber: building.phoneNumber,
      latitude: building.latitude,
      longitude: building.longitude,
      districtId: building.district?.id,
      buildingTypeId: building.buildingType?.id,
      isActive: building.isActive,
    };
  }

  updateBuilding() {
    this.removeMessages(MessageForAction.Update);
    this.authService.getUser().then((user) => {
      if (user && user.access_token) {
        const headers = Helpers.getHeaders(user.access_token);
        Axios.put(
          `${IdentityConstants.apiRoot}Building/${this.state.editBuildingData.id}`,
          this.CopyFrom(this.state.editBuildingData),
          { headers }
        )
          .then((_) => {
            this.refreshBuildings();
            this.setState({
              isEditBuildingModalOpen: false,
              editBuildingData: this.resetBuilding(),
            });
          })
          .catch((error) => {
            this.handleCatch(error, MessageForAction.Update);
          });
      }
    });
  }

  deleteBuilding() {
    this.removeMessages(MessageForAction.Delete);
    this.authService.getUser().then((user) => {
      if (user && user.access_token) {
        const headers = Helpers.getHeaders(user.access_token);
        Axios.delete(
          `${IdentityConstants.apiRoot}Building/${this.state.deleteBuildingData.id}`,
          { headers }
        )
          .then(() => {
            this.refreshTableData();
            this.setState({
              isBuildingDeleteConfirmModalOpen: false,
              deleteBuildingData: {
                id: "",
                name: "",
              },
            });
          })
          .catch((error) => {
            this.handleCatch(error, MessageForAction.Delete);
          });
      }
    });
  }

  editBuildingSetState(
    id: string,
    name: string,
    establishmentDate: Date,
    addressLine1: string,
    addressLine2: string,
    city: string,
    state: string,
    postal: string,
    phoneNumber: string,
    latitude: number,
    longitude: number,
    district: any,
    buildingType: any,
    isActive: boolean
  ) {
    let { editBuildingData } = this.state;
    editBuildingData.id = id;
    editBuildingData.name = name;
    editBuildingData.establishmentDate = establishmentDate;
    editBuildingData.addressLine1 = addressLine1;
    editBuildingData.addressLine2 = addressLine2;
    editBuildingData.city = city;
    editBuildingData.state = state;
    editBuildingData.postal = postal;
    editBuildingData.phoneNumber = phoneNumber;
    editBuildingData.latitude = latitude;
    editBuildingData.longitude = longitude;
    editBuildingData.district = district;
    editBuildingData.buildingType = buildingType;
    editBuildingData.isActive = isActive;
    this.setState({
      editBuildingData,
      isEditBuildingModalOpen: !this.state.isEditBuildingModalOpen,
    });
  }

  deleteBuildingSetState(id: any, name: any) {
    let { deleteBuildingData } = this.state;
    deleteBuildingData.id = id;
    deleteBuildingData.name = name;
    this.setState({
      deleteBuildingData,
      isBuildingDeleteConfirmModalOpen: !this.state
        .isBuildingDeleteConfirmModalOpen,
    });
  }

  handleChangePage(
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) {
    this.setState({ pageNumber: newPage });
    this.debounceRefreshBuildings();
  }

  public render() {
    const { classes } = this.props;
    let buildings = this.state.buildings.map((building) => {
      return (
        <TableRow key={building.id}>
          <TableCell>
            <Tooltip title="Edit">
              <IconButton
                onClick={this.editBuildingSetState.bind(
                  this,
                  building.id,
                  building.name,
                  building.establishmentDate,
                  building.addressLine1,
                  building.addressLine2,
                  building.city,
                  building.state,
                  building.postal,
                  building.phoneNumber,
                  building.latitude,
                  building.longitude,
                  building.district,
                  building.buildingType,
                  building.isActive
                )}
              >
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton
                onClick={this.deleteBuildingSetState.bind(
                  this,
                  building.id,
                  building.name
                )}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          </TableCell>
          <TableCell>{building.name}</TableCell>
          <TableCell>
            {building.establishmentDate === null
              ? null
              : new Date(building.establishmentDate).toDateString()}
          </TableCell>
          <TableCell>{building.addressLine1}</TableCell>
          <TableCell>{building.addressLine2}</TableCell>
          <TableCell>{building.city}</TableCell>
          <TableCell>{building.state}</TableCell>
          <TableCell>{building.postal}</TableCell>
          <TableCell>{building.phoneNumber}</TableCell>
          <TableCell>{building.latitude}</TableCell>
          <TableCell>{building.longitude}</TableCell>
          <TableCell>{building.district?.name}</TableCell>
          <TableCell>{building.buildingType?.name}</TableCell>
          <TableCell>
            <Switch
              checked={building.isActive}
              color="primary"
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </TableCell>
        </TableRow>
      );
    });
    return (
      <Paper className={classes.paper}>
        <AppBar
          className={classes.searchBar}
          position="static"
          color="default"
          elevation={0}
        >
          <Toolbar>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <Search className={classes.block} color="inherit" />
              </Grid>
              <Grid item xs>
                <TextField
                  fullWidth
                  placeholder="Search by name, state, city, district name, or building type"
                  value={this.state.searchText}
                  onChange={(e) => {
                    this.setState({ searchText: e.target.value });
                  }}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      this.setState({ pageNumber: 0 });
                      this.refreshTableData();
                      e.preventDefault();
                    }
                  }}
                  InputProps={{
                    disableUnderline: true,
                    className: classes.searchInput,
                  }}
                />
              </Grid>
              <Grid item>
                <Tooltip title="Add">
                  <IconButton onClick={this.toggleNewBuildingModal.bind(this)}>
                    <Add />
                  </IconButton>
                </Tooltip>
                <Dialog
                  open={this.state.isNewBuildingModalOpen}
                  onClose={this.toggleNewBuildingModal.bind(this)}
                  aria-labelledby="form-dialog-title"
                >
                  <DialogTitle id="form-dialog-title">Add Building</DialogTitle>
                  <DialogContent>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          autoFocus
                          id="addBuildingName"
                          label="Name"
                          required
                          fullWidth
                          value={this.state.newBuildingData.name}
                          onChange={(e) => {
                            let { newBuildingData } = this.state;
                            newBuildingData.name = e.target.value;
                            this.setState({ newBuildingData });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <ReferenceEntitySelector
                          componentId="addDistrictSelector"
                          selectedItem={this.state.newBuildingData.district}
                          label="District"
                          url="District/GetItemsForReferenceControl"
                          onReferenceEntitySelectEvent={
                            this.handleNewDistrictSelect
                          }
                          handleException={(error) =>
                            this.handleCatch(error, MessageForAction.Create)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Autocomplete
                          id="State"
                          freeSolo
                          includeInputInList
                          autoSelect
                          onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                            let { newBuildingData } = this.state;
                            newBuildingData.state = e.target.value;
                            newBuildingData.city = "";
                            this.setState({ newBuildingData });
                          }}
                          onChange={(event: any, value: any) => {
                            let { newBuildingData } = this.state;
                            newBuildingData.state = value;
                            newBuildingData.city = "";
                            this.setState({ newBuildingData });
                          }}
                          open={this.state.isStateDropDownOpenNew}
                          onOpen={() => {
                            this.setState({ isStateDropDownOpenNew: true });
                          }}
                          onClose={() => {
                            this.setState({ isStateDropDownOpenNew: false });
                          }}
                          options={Object.keys(StateAndCities)}
                          value={this.state.newBuildingData.state}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="State"
                              fullWidth
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                ),
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Autocomplete
                          id="City"
                          freeSolo
                          includeInputInList
                          autoSelect
                          onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                            let { newBuildingData } = this.state;
                            newBuildingData.city = e.target.value;
                            this.setState({ newBuildingData });
                          }}
                          onChange={(event: any, value: any) => {
                            let { newBuildingData } = this.state;
                            newBuildingData.city = value;
                            this.setState({ newBuildingData });
                          }}
                          open={this.state.isCityDropDownOpenNew}
                          onOpen={() => {
                            this.setState({ isCityDropDownOpenNew: true });
                          }}
                          onClose={() => {
                            this.setState({ isCityDropDownOpenNew: false });
                          }}
                          options={
                            StateAndCities[this.state.newBuildingData.state]
                          }
                          value={this.state.newBuildingData.city}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="City"
                              fullWidth
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                ),
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="Postal"
                          label="Postal"
                          fullWidth
                          value={this.state.newBuildingData.postal}
                          onChange={(e) => {
                            let { newBuildingData } = this.state;
                            newBuildingData.postal = e.target.value;
                            this.setState({ newBuildingData });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="PhoneNumber"
                          label="PhoneNumber"
                          fullWidth
                          value={this.state.newBuildingData.phoneNumber}
                          onChange={(e) => {
                            let { newBuildingData } = this.state;
                            newBuildingData.phoneNumber = e.target.value;
                            this.setState({ newBuildingData });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="Latitude"
                          label="Latitude"
                          type="number"
                          fullWidth
                          value={this.state.newBuildingData.latitude}
                          onChange={(e) => {
                            let { newBuildingData } = this.state;
                            newBuildingData.latitude = +e.target.value;
                            this.setState({ newBuildingData });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="Longitude"
                          label="Longitude"
                          type="number"
                          fullWidth
                          value={this.state.newBuildingData.longitude}
                          onChange={(e) => {
                            let { newBuildingData } = this.state;
                            newBuildingData.longitude = +e.target.value;
                            this.setState({ newBuildingData });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="AddressLine1"
                          label="AddressLine1"
                          fullWidth
                          value={this.state.newBuildingData.addressLine1}
                          onChange={(e) => {
                            let { newBuildingData } = this.state;
                            newBuildingData.addressLine1 = e.target.value;
                            this.setState({ newBuildingData });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="AddressLine2"
                          label="AddressLine2"
                          fullWidth
                          value={this.state.newBuildingData.addressLine2}
                          onChange={(e) => {
                            let { newBuildingData } = this.state;
                            newBuildingData.addressLine2 = e.target.value;
                            this.setState({ newBuildingData });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <ReferenceEntitySelector
                          componentId="addBuildingTypeSelector"
                          selectedItem={this.state.newBuildingData.buildingType}
                          label="BuildingType"
                          url="BuildingType/GetItemsForReferenceControl"
                          required={true}
                          onReferenceEntitySelectEvent={
                            this.handleNewBuildingTypeSelect
                          }
                          handleException={(error) =>
                            this.handleCatch(error, MessageForAction.Create)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            autoOk
                            disableFuture
                            openTo="year"
                            views={["year", "month", "date"]}
                            variant="inline"
                            format="MM/dd/yyyy"
                            id="addEstablishmentDate"
                            label="EstablishmentDate"
                            value={this.state.newBuildingData.establishmentDate}
                            onChange={(e) => {
                              let { newBuildingData } = this.state;
                              newBuildingData.establishmentDate = Helpers.getDateWithUTCZero(
                                e
                              );
                              this.setState({ newBuildingData });
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          label="Is active"
                          labelPlacement="start"
                          style={{ marginLeft: 0 }}
                          control={
                            <Switch
                              checked={this.state.newBuildingData.isActive}
                              value={this.state.newBuildingData.isActive}
                              onChange={(e) => {
                                let { newBuildingData } = this.state;
                                newBuildingData.isActive = e.target.checked;
                                this.setState({ newBuildingData });
                              }}
                              color="primary"
                              inputProps={{ "aria-label": "primary checkbox" }}
                            />
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        {Helpers.handleError(
                          MessageForAction.Create,
                          this.state.BuildingMessages
                        )}
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      color="primary"
                      onClick={this.addNewBuilding.bind(this)}
                    >
                      Add
                    </Button>
                    <Button
                      color="secondary"
                      onClick={this.toggleNewBuildingModal.bind(this)}
                    >
                      Cancel
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  open={this.state.isBuildingDeleteConfirmModalOpen}
                  onClose={this.toggleDeleteBuildingModal.bind(this)}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  fullWidth={true}
                  maxWidth={"sm"}
                >
                  <DialogTitle id="form-dialog-title">
                    Delete Building
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Delete {this.state.deleteBuildingData.name} Building?
                    </DialogContentText>
                    {Helpers.handleError(
                      MessageForAction.Delete,
                      this.state.BuildingMessages
                    )}
                  </DialogContent>
                  <DialogActions>
                    <Button
                      color="primary"
                      onClick={this.deleteBuilding.bind(this)}
                    >
                      Yes, delete
                    </Button>
                    <Button
                      color="secondary"
                      onClick={this.toggleDeleteBuildingModal.bind(this)}
                    >
                      Cancel
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  open={this.state.isEditBuildingModalOpen}
                  onClose={this.toggleEditBuildingModal.bind(this)}
                  aria-labelledby="form-dialog-title"
                >
                  <DialogTitle id="form-dialog-title">
                    Edit Building
                  </DialogTitle>
                  <DialogContent>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          autoFocus
                          id="BuildingName"
                          label="Name"
                          required
                          fullWidth
                          value={this.state.editBuildingData.name}
                          onChange={(e) => {
                            let { editBuildingData } = this.state;
                            editBuildingData.name = e.target.value;
                            this.setState({ editBuildingData });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <ReferenceEntitySelector
                          componentId="editDistrictSelector"
                          selectedItem={this.state.editBuildingData.district}
                          label="District"
                          url="District/GetItemsForReferenceControl"
                          required={true}
                          onReferenceEntitySelectEvent={
                            this.handleEditDistrictSelect
                          }
                          handleException={(error) =>
                            this.handleCatch(error, MessageForAction.Update)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Autocomplete
                          id="State"
                          freeSolo
                          includeInputInList
                          autoSelect
                          onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                            let { editBuildingData } = this.state;
                            editBuildingData.state = e.target.value;
                            editBuildingData.city = "";
                            this.setState({ editBuildingData });
                          }}
                          onChange={(event: any, value: any) => {
                            let { editBuildingData } = this.state;
                            editBuildingData.state = value;
                            editBuildingData.city = "";
                            this.setState({ editBuildingData });
                          }}
                          open={this.state.isStateDropDownOpenEdit}
                          onOpen={() => {
                            this.setState({ isStateDropDownOpenEdit: true });
                          }}
                          onClose={() => {
                            this.setState({ isStateDropDownOpenEdit: false });
                          }}
                          options={Object.keys(StateAndCities)}
                          value={this.state.editBuildingData.state}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="State"
                              fullWidth
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                ),
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Autocomplete
                          id="City"
                          freeSolo
                          includeInputInList
                          autoSelect
                          onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                            let { editBuildingData } = this.state;
                            editBuildingData.city = e.target.value;
                            this.setState({ editBuildingData });
                          }}
                          onChange={(event: any, value: any) => {
                            let { editBuildingData } = this.state;
                            editBuildingData.city = value;
                            this.setState({ editBuildingData });
                          }}
                          open={this.state.isCityDropDownOpenEdit}
                          onOpen={() => {
                            this.setState({ isCityDropDownOpenEdit: true });
                          }}
                          onClose={() => {
                            this.setState({ isCityDropDownOpenEdit: false });
                          }}
                          options={
                            StateAndCities[this.state.editBuildingData.state]
                          }
                          value={this.state.editBuildingData.city}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="City"
                              fullWidth
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                ),
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="Postal"
                          label="Postal"
                          fullWidth
                          value={this.state.editBuildingData.postal}
                          onChange={(e) => {
                            let { editBuildingData } = this.state;
                            editBuildingData.postal = e.target.value;
                            this.setState({ editBuildingData });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="PhoneNumber"
                          label="PhoneNumber"
                          fullWidth
                          value={this.state.editBuildingData.phoneNumber}
                          onChange={(e) => {
                            let { editBuildingData } = this.state;
                            editBuildingData.phoneNumber = e.target.value;
                            this.setState({ editBuildingData });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="Latitude"
                          label="Latitude"
                          fullWidth
                          type="number"
                          value={this.state.editBuildingData.latitude}
                          onChange={(e) => {
                            let { editBuildingData } = this.state;
                            editBuildingData.latitude = +e.target.value;
                            this.setState({ editBuildingData });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="Longitude"
                          label="Longitude"
                          fullWidth
                          type="number"
                          value={this.state.editBuildingData.longitude}
                          onChange={(e) => {
                            let { editBuildingData } = this.state;
                            editBuildingData.longitude = +e.target.value;
                            this.setState({ editBuildingData });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="AddressLine1"
                          label="AddressLine1"
                          fullWidth
                          value={this.state.editBuildingData.addressLine1}
                          onChange={(e) => {
                            let { editBuildingData } = this.state;
                            editBuildingData.addressLine1 = e.target.value;
                            this.setState({ editBuildingData });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="AddressLine2"
                          label="AddressLine2"
                          fullWidth
                          value={this.state.editBuildingData.addressLine2}
                          onChange={(e) => {
                            let { editBuildingData } = this.state;
                            editBuildingData.addressLine2 = e.target.value;
                            this.setState({ editBuildingData });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <ReferenceEntitySelector
                          componentId="editBuildingTypeSelector"
                          selectedItem={
                            this.state.editBuildingData.buildingType
                          }
                          label="BuildingType"
                          url="BuildingType/GetItemsForReferenceControl"
                          required={true}
                          onReferenceEntitySelectEvent={
                            this.handleEditBuildingTypeSelect
                          }
                          handleException={(error) =>
                            this.handleCatch(error, MessageForAction.Update)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            autoOk
                            disableFuture
                            openTo="year"
                            views={["year", "month", "date"]}
                            variant="inline"
                            format="MM/dd/yyyy"
                            id="editEstablishmentDate"
                            label="EstablishmentDate"
                            value={
                              this.state.editBuildingData.establishmentDate
                            }
                            onChange={(e) => {
                              let { editBuildingData } = this.state;
                              editBuildingData.establishmentDate = Helpers.getDateWithUTCZero(
                                e
                              );
                              this.setState({ editBuildingData });
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          label="Is active"
                          labelPlacement="start"
                          style={{ marginLeft: 0 }}
                          control={
                            <Switch
                              checked={this.state.editBuildingData.isActive}
                              value={this.state.editBuildingData.isActive}
                              onChange={(e) => {
                                let { editBuildingData } = this.state;
                                editBuildingData.isActive = e.target.checked;
                                this.setState({ editBuildingData });
                              }}
                              color="primary"
                              inputProps={{ "aria-label": "primary checkbox" }}
                            />
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        {Helpers.handleError(
                          MessageForAction.Update,
                          this.state.BuildingMessages
                        )}
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      color="primary"
                      onClick={this.updateBuilding.bind(this)}
                    >
                      Update
                    </Button>
                    <Button
                      color="secondary"
                      onClick={this.toggleEditBuildingModal.bind(this)}
                    >
                      Cancel
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  open={this.state.isErrorDialogOpen}
                  onClose={() => {
                    this.setState({
                      isErrorDialogOpen: false,
                      BuildingMessages: [],
                    });
                  }}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  fullWidth={true}
                  maxWidth={"sm"}
                >
                  <DialogTitle id="form-dialog-title">
                    Error occurred
                  </DialogTitle>
                  <DialogContent>
                    {Helpers.handleError(
                      MessageForAction.Read,
                      this.state.BuildingMessages
                    )}
                  </DialogContent>
                  <DialogActions>
                    <Button
                      color="secondary"
                      onClick={() => {
                        this.setState({
                          isErrorDialogOpen: false,
                          BuildingMessages: [],
                        });
                      }}
                    >
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>
                <Tooltip title="Reload">
                  <IconButton onClick={this.refreshTableData.bind(this)}>
                    <Refresh className={classes.block} color="inherit" />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Action</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>EstablishmentDate</TableCell>
                <TableCell>AddressLine1</TableCell>
                <TableCell>AddressLine2</TableCell>
                <TableCell>City</TableCell>
                <TableCell>State</TableCell>
                <TableCell>Postal</TableCell>
                <TableCell>PhoneNumber</TableCell>
                <TableCell>Latitude</TableCell>
                <TableCell>Longitude</TableCell>
                <TableCell>District</TableCell>
                <TableCell>BuildingType</TableCell>
                <TableCell>IsActive</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{buildings}</TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10]}
                  count={this.state.buildingsCount}
                  rowsPerPage={10}
                  page={this.state.pageNumber}
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    native: true,
                  }}
                  onChangePage={this.handleChangePage.bind(this)}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Paper>
    );
  }

  private handleCatch(error: any, messageForAction: MessageForAction) {
    let { BuildingMessages } = this.state;
    if (error.toString().includes("Network Error")) {
      BuildingMessages.push(
        new MessageInfo(
          messageForAction,
          "Server unavailable, try again later",
          503
        )
      );
    } else if (
      error.toString().includes("Request failed with status code 401")
    ) {
      this.authService.login();
    } else if (
      error.toString().includes("Request failed with status code 500")
    ) {
      BuildingMessages.push(
        new MessageInfo(messageForAction, error.toString(), 500)
      );
    } else if (error.response.data.hasOwnProperty("errors")) {
      error.response.data.errors.forEach((errorItem: any) => {
        BuildingMessages.push(
          new MessageInfo(
            messageForAction,
            errorItem.message,
            error.response.status
          )
        );
      });
    } else if (error.response.data.hasOwnProperty("Message")) {
      BuildingMessages.push(
        new MessageInfo(
          messageForAction,
          error.response.data.Message,
          error.response.status
        )
      );
    } else {
      BuildingMessages.push(
        new MessageInfo(messageForAction, error, error.response.status)
      );
    }
    this.setState({ BuildingMessages: BuildingMessages });
  }
}

export default withStyles(styles)(Building);
