export enum PersonCreatedFor {
  None,
  NewMarriageNewHusband,
  NewMarriageNewWife,
  NewHouseholdNewPrimaryPerson,
  NewHouseholdNewSecondaryPerson,
  EditHouseholdAddChild,
  EditHouseholdNewHouseholdMemberNewPerson,
  EditHouseholdNewPrimaryPerson,
  EditHouseholdNewSecondaryPerson,
}
