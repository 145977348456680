import * as React from 'react';
import AppContent from '../components/AppContent';

class App extends React.Component {
  public render() {
    return (
      <div className="App">
        <AppContent />
      </div>
    );
  }
}

export default App;
