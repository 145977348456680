import { MessageForAction } from "./MessageForAction";
export class MessageInfo {
    public message: string;
    public messageForAction: MessageForAction;
    public status: number;
    constructor(actionType: MessageForAction, message: string, status: number) {
        this.message = message;
        this.messageForAction = actionType;
        this.status = status;
    }
}
