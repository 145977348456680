import { MessageForAction } from "../components/MessageForAction";
import { MessageInfo } from "../components/MessageInfo";
import React from "react";
import { Alert } from "@material-ui/lab";

export default class Helpers {
  static getHeaders(access_token: any) {
    return {
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
    };
  }

  static getDateWithUTCZero(e: any) {
    if (e) {
      return new Date(e.getTime());
    } else {
      return null;
    }
  }

  static handleError(
    messageForAction: MessageForAction,
    messages: MessageInfo[]
  ): React.ReactNode {
    messages = Array.from(
      new Map(messages.map((item) => [item.message, item])).values()
    );
    return messages
      .filter((m) => m.messageForAction === messageForAction)
      .map((message: MessageInfo) => {
        let messageText = message.message;
        if (message.status >= 500) {
          messageText = "Contact administrator: " + messageText;
        }
        return (
          <Alert style={{ marginTop: 2 }} key={messageText} severity="error">
            {messageText}
          </Alert>
        );
      });
  }
}
