import { Log, User, UserManager } from "oidc-client";

import { IdentityConstants } from "../helpers/IdentityConstants";

export class AuthService {
  public userManager: UserManager;

  constructor() {
    const settings = {
      authority: IdentityConstants.authority,
      client_id: IdentityConstants.clientId,
      redirect_uri: `${IdentityConstants.clientRoot}callback.html`,
      post_logout_redirect_uri: `${IdentityConstants.clientRoot}index.html`,
      response_type: "code",
      scope: IdentityConstants.clientScope
    };
    this.userManager = new UserManager(settings);

    Log.logger = console;
    Log.level = Log.INFO;
  }

  public getUser(): Promise<User | null> {
    return this.userManager.getUser();
  }

  public login(): Promise<void> {
    return this.userManager.signinRedirect();
  }

  public renewToken(): Promise<User> {
    return this.userManager.signinSilent();
  }

  public logout(): Promise<void> {
    return this.userManager.signoutRedirect();
  }
}
