import React from "react";
import Axios from "axios";
import { IdentityConstants } from "../helpers/IdentityConstants";
import { AuthService } from "../services/AuthService";
import { Autocomplete } from "@material-ui/lab";
import { CircularProgress, TextField } from "@material-ui/core";
import Helpers from "../helpers/Helpers";
import _ from "lodash";
import { AppConstants } from "../helpers/AppConstants";

export interface IReferenceEntitySelectorProps {
  onReferenceEntitySelectEvent: any;
  handleException: any;
  componentId: string;
  label: string;
  url: string;
  selectedItem: any;
  required?: boolean;
}

export interface IReferenceEntitySelectorState {
  open: boolean;
  isLoadingOptions: boolean;
  options: any[];
  selectedItem: any;
}

export class ReferenceEntitySelector extends React.Component<
  IReferenceEntitySelectorProps,
  IReferenceEntitySelectorState
> {
  authService: AuthService;
  constructor(props: IReferenceEntitySelectorProps) {
    super(props);
    this.authService = new AuthService();
    this.state = {
      open: false,
      isLoadingOptions: false,
      options: [],
      selectedItem: props.selectedItem,
    };
    this.refreshOptions = _.debounce(this.refreshOptions, 700);
  }

  refreshOptions(value: string) {
    this.authService.getUser().then((user) => {
      if (user && user.access_token) {
        this.setState({ isLoadingOptions: true });
        const headers = Helpers.getHeaders(user.access_token);
        let searchDTO = {
          searchText: value,
          pageNumber: 0,
          pageSize: AppConstants.ReferenceControlItemsSize,
          onlyIsActive: true,
        };
        Axios.post(`${IdentityConstants.apiRoot}${this.props.url}`, searchDTO, {
          headers,
        })
          .then((response) => {
            this.setState({
              options: response.data,
              isLoadingOptions: false,
            });
          })
          .catch((error) => {
            this.props.handleException(error);
            this.setState({
              isLoadingOptions: false,
            });
          });
      }
    });
  }

  public render() {
    return (
      <Autocomplete
        filterOptions={(options) => options}
        id={this.props.componentId}
        freeSolo
        open={this.state.open}
        onOpen={() => {
          this.setState({ open: true });
          this.refreshOptions(this.state.selectedItem?.title);
        }}
        onClose={() => {
          this.setState({ open: false });
        }}
        onChange={(event: any, value: any) => {
          this.setState({ open: false, selectedItem: value });
          this.props.onReferenceEntitySelectEvent(value);
        }}
        getOptionLabel={(option) => option.title}
        onInputChange={(event: object, value: string, reason: string) => {
          if (this.state.open && value) {
            this.refreshOptions(value);
          }
        }}
        options={this.state.options}
        value={this.state.selectedItem}
        loading={this.state.isLoadingOptions}
        renderInput={(params) => (
          <TextField
            {...params}
            label={this.props.label}
            required={this.props.required}
            fullWidth
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {this.state.isLoadingOptions ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    );
  }
}
