import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  AppBar,
  Toolbar,
  Paper,
  Grid,
  Button,
  TextField,
  Tooltip,
  IconButton,
  Switch,
  FormControlLabel,
  TableFooter,
  TablePagination,
  Typography,
  Menu,
  MenuItem,
} from "@material-ui/core";
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import {
  Delete,
  Edit,
  Add,
  Search,
  Refresh,
  Visibility,
} from "@material-ui/icons";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Autocomplete } from "@material-ui/lab";
import Axios from "axios";
import { IdentityConstants } from "../../../helpers/IdentityConstants";
import { AuthService } from "../../../services/AuthService";
import { MessageInfo } from "../../MessageInfo";
import { MessageForAction } from "../../MessageForAction";
import { ReferenceEntitySelector } from "../../ReferenceEntitySelector";
import Helpers from "../../../helpers/Helpers";
import { IHousehold } from "./IHousehold";
import { IHouseholdSave } from "./IHouseholdSave";
import { IHouseholdState } from "./IHouseholdState";
import { IMarriage } from "../dataTransferableObjects/IMarriage";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { IMarriageSave } from "../dataTransferableObjects/IMarriageSave";
import { IPerson } from "../dataTransferableObjects/IPerson";
import { IPersonSave } from "../dataTransferableObjects/IPersonSave";
import { PersonCreatedFor } from "./PersonCreatedFor";
import { PersonEditedFor } from "./PersonEditedFor";
import { IHouseholdMember } from "../dataTransferableObjects/IHouseholdMember";
import { IHouseholdMemberSave } from "../dataTransferableObjects/IHouseholdMemberSave";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { AppConstants } from "../../../helpers/AppConstants";
import _ from "lodash";

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      margin: "auto",
      overflow: "hidden",
    },
    searchBar: {
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    },
    searchInput: {
      fontSize: theme.typography.fontSize,
    },
    block: {
      display: "block",
    },
  });

export interface IHouseholdComponentProps extends WithStyles<typeof styles> {
  match: any;
}

class Household extends React.Component<
  IHouseholdComponentProps,
  IHouseholdState
> {
  private authService: AuthService;
  private stateAndCities: any;
  constructor(props: IHouseholdComponentProps) {
    super(props);
    this.stateAndCities = require("../../../StateAndCities.json");
    this.authService = new AuthService();
    this.state = {
      households: [],
      newHouseholdData: this.resetHousehold(),
      editHouseholdData: this.resetHousehold(),
      viewHouseholdData: null,
      deleteHouseholdData: {
        id: "",
        deleteMessage: "",
      },
      isNewHouseholdModalOpen: false,
      isEditHouseholdModalOpen: false,
      isViewHouseholdModalOpen: false,
      isDeleteHouseholdConfirmModalOpen: false,
      isStateDropDownOpenNew: false,
      isStateDropDownOpenEdit: false,
      isCityDropDownOpenNew: false,
      isCityDropDownOpenEdit: false,
      isHouseholdTableActionsMenuOpen: false,
      householdDataForActionsMenu: this.resetHousehold(),
      pageNumber: 0,
      householdsCount: 0,
      searchText: "",
      householdMessages: [],
      isErrorDialogOpen: false,
      //Marriage
      marriageData: this.resetMarriage(),
      marriageMessages: [],
      isNewMarriageQuestionModalOpen: false,
      isNewMarriageModalOpen: false,
      editMarriageData: this.resetMarriage(),
      isHouseholdsMarriageInformationShow: false,
      //Person
      isNewPersonModalOpen: false,
      isEditPersonModalOpen: false,
      personData: this.resetPerson(),
      personMessages: [],
      personCreatedFor: PersonCreatedFor.None,
      personEditedFor: PersonEditedFor.None,
      anchorElement: null,
      isNewHouseholdPrimaryPersonActionsMenuOpen: false,
      isNewHouseholdSecondaryPersonActionsMenuOpen: false,
      isEditHouseholdPrimaryPersonActionsMenuOpen: false,
      isEditHouseholdSecondaryPersonActionsMenuOpen: false,
      //HouseholdMember
      newHouseholdMemberData: this.resetHouseholdMember(),
      editHouseholdMemberData: this.resetHouseholdMember(),
      householdMemberMessages: [],
      isNewHouseholdMemberModalOpen: false,
      isDeleteHouseholdMemberConfirmModalOpen: false,
      deleteHouseholdMemberData: {
        id: "",
        deleteMessage: "",
      },
    };
    this.debounceRefreshHouseholds = _.debounce(
      this.debounceRefreshHouseholds,
      1000
    );
  }

  debounceRefreshHouseholds() {
    this.refreshHouseholds();
  }

  resetHouseholdMember() {
    let data: IHouseholdMember = {
      id: "",
      comment: "",
      person: null,
      household: null,
      isActive: true,
    };
    return data;
  }

  resetPerson() {
    let data: IPerson = {
      id: "",
      title: "",
      firstName: "",
      preferredFirstName: "",
      lastName: "",
      middleName: "",
      isMiddleNameShortened: true,
      gender: false,
      birthDate: null,
      deathDate: null,
      deathComment: "",
      comment: "",
      phone1: "",
      phone2: "",
      email: "",
      memberOfChurchId: "",
      memberOfChurch: null,
      father: null,
      mother: null,
      isActive: true,
      householdMembers: [],
      primaryHouseholds: [],
      secondaryHouseholds: [],
    };
    return data;
  }

  resetHousehold() {
    let data: IHousehold = {
      id: "",
      number: "",
      phone: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      postal: "",
      comment1: "",
      comment2: "",
      comment3: "",
      comment4: "",
      primaryPerson: null,
      secondaryPerson: null,
      householdMembers: [],
      isActive: true,
    };
    return data;
  }

  resetMarriage() {
    let data: IMarriage = {
      id: "",
      husband: null,
      wife: null,
      startDate: null,
      endDate: null,
      isActive: true,
    };
    return data;
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.editHouseholdByParamId(this.props.match.params.id);
    }
    this.refreshTableData();
  }

  editHouseholdByParamId(id: string) {
    this.authService.getUser().then((user) => {
      if (user && user.access_token) {
        const headers = Helpers.getHeaders(user.access_token);
        Axios.get(`${IdentityConstants.apiRoot}Household/${id}`, { headers })
          .then((response) => {
            this.setState({ householdDataForActionsMenu: response.data });
            this.editHouseholdSetState();
          })
          .catch((error) => {
            this.handleHouseholdCatch(error, MessageForAction.Read);
          });
      }
    });
  }

  refreshTableData() {
    this.refreshHouseholds();
  }

  refreshHouseholds() {
    this.setState({ householdMessages: [] });
    this.authService.getUser().then((user) => {
      if (user && user.access_token) {
        const headers = Helpers.getHeaders(user.access_token);
        let searchDTO = {
          searchText: this.state.searchText,
          pageNumber: this.state.pageNumber,
          pageSize: AppConstants.TablePageSize,
        };
        Axios.post(
          `${IdentityConstants.apiRoot}Household/GetItems`,
          searchDTO,
          { headers }
        )
          .then((response) => {
            this.setState({
              households: response.data.items,
              householdsCount: response.data.searchItemsCount,
            });
          })
          .catch((error) => {
            this.handleHouseholdCatch(error, MessageForAction.Read);
            this.setState({ isErrorDialogOpen: true });
          });
      }
    });
  }

  CopyHouseholdFrom(household: IHousehold): IHouseholdSave {
    return {
      number: household.number,
      phone: household.phone,
      addressLine1: household.addressLine1,
      addressLine2: household.addressLine2,
      city: household.city,
      state: household.state,
      postal: household.postal,
      comment1: household.comment1 ?? "",
      comment2: household.comment2 ?? "",
      comment3: household.comment3 ?? "",
      comment4: household.comment4 ?? "",
      primaryPersonId: household.primaryPerson?.id,
      secondaryPersonId: household.secondaryPerson?.id,
      isActive: household.isActive,
    };
  }

  CopyMarriageFrom(marriage: IMarriage): IMarriageSave {
    return {
      husbandId: marriage.husband?.id,
      wifeId: marriage.wife?.id,
      startDate: marriage.startDate,
      endDate: marriage.endDate,
      isActive: marriage.isActive,
    };
  }

  CopyPersonFrom(person: IPerson): IPersonSave {
    return {
      firstName: person.firstName,
      preferredFirstName: person.preferredFirstName,
      lastName: person.lastName,
      middleName: person.middleName,
      isMiddleNameShortened: person.isMiddleNameShortened,
      gender: person.gender,
      birthDate: person.birthDate,
      deathDate: person.deathDate,
      deathComment: person.deathComment ?? "",
      comment: person.comment ?? "",
      phone1: person.phone1,
      phone2: person.phone2,
      email: person.email,
      memberOfChurchId: person.memberOfChurch?.id,
      fatherId: person.father?.id,
      motherId: person.mother?.id,
      isActive: person.isActive,
    };
  }

  CopyHouseholdMemberFrom(
    householdMember: IHouseholdMember
  ): IHouseholdMemberSave {
    return {
      comment: householdMember.comment ?? "",
      personId: householdMember.person?.id,
      householdId: householdMember.household?.id,
      isActive: householdMember.isActive,
    };
  }

  addNewMarriage() {
    this.removeMarriageMessages(MessageForAction.Create);
    this.authService.getUser().then((user) => {
      if (user && user.access_token) {
        const headers = Helpers.getHeaders(user.access_token);
        Axios.post(
          `${IdentityConstants.apiRoot}Marriage`,
          this.CopyMarriageFrom(this.state.marriageData),
          { headers }
        )
          .then((response) => {
            let { newHouseholdData } = this.state;
            let { marriageData } = this.state;
            if (marriageData.husband.isActive) {
              newHouseholdData.primaryPerson = marriageData.husband;
            }
            if (marriageData.wife.isActive) {
              newHouseholdData.secondaryPerson = marriageData.wife;
            }

            this.setState({
              isNewMarriageModalOpen: false,
              isNewHouseholdModalOpen: true,
              newHouseholdData,
              marriageData: this.resetMarriage(),
            });
          })
          .catch((error) => {
            this.handleMarriageCatch(error, MessageForAction.Create);
          });
      }
    });
  }

  addHouseholdMemberForNewChildToEditingHousehold() {
    let { personData } = this.state;
    this.removeHouseholdMemberMessages(MessageForAction.Create);
    this.authService.getUser().then((user) => {
      if (user && user.access_token) {
        const headers = Helpers.getHeaders(user.access_token);
        Axios.post(
          `${IdentityConstants.apiRoot}HouseholdMember`,
          this.CopyHouseholdMemberFrom(this.state.newHouseholdMemberData),
          { headers }
        )
          .then((response) => {
            let { editHouseholdData } = this.state;
            let householdMember = response.data;
            householdMember.person = personData;
            editHouseholdData.householdMembers.push(householdMember);
            this.setState({
              editHouseholdData,
              newHouseholdMemberData: this.resetHouseholdMember(),
            });
          })
          .catch((error) => {
            this.handleHouseholdMemberCatch(error, MessageForAction.Create);
          });
      }
    });
  }

  addNewHouseholdMemberToEditingHousehold() {
    this.removeHouseholdMemberMessages(MessageForAction.Create);
    this.authService.getUser().then((user) => {
      if (user && user.access_token) {
        const headers = Helpers.getHeaders(user.access_token);
        let { newHouseholdMemberData: householdMemberData } = this.state;
        householdMemberData.household = this.state.editHouseholdData;
        this.setState({ newHouseholdMemberData: householdMemberData });
        Axios.post(
          `${IdentityConstants.apiRoot}HouseholdMember`,
          this.CopyHouseholdMemberFrom(this.state.newHouseholdMemberData),
          { headers }
        )
          .then((response) => {
            let { editHouseholdData } = this.state;
            let householdMember = response.data;
            householdMember.person = householdMemberData.person;
            editHouseholdData.householdMembers.push(householdMember);
            this.setState({
              editHouseholdData,
              newHouseholdMemberData: this.resetHouseholdMember(),
              isNewHouseholdMemberModalOpen: false,
              isEditHouseholdModalOpen: true,
            });
          })
          .catch((error) => {
            this.handleHouseholdMemberCatch(error, MessageForAction.Create);
          });
      }
    });
  }

  addNewPerson() {
    this.removePersonMessages(MessageForAction.Create);
    this.authService.getUser().then((user) => {
      if (user && user.access_token) {
        const headers = Helpers.getHeaders(user.access_token);
        Axios.post(
          `${IdentityConstants.apiRoot}Person`,
          this.CopyPersonFrom(this.state.personData),
          { headers }
        )
          .then((response) => {
            this.setState({
              isNewPersonModalOpen: false,
            });

            let {
              marriageData,
              newHouseholdData,
              editHouseholdData,
              newHouseholdMemberData,
            } = this.state;

            switch (this.state.personCreatedFor) {
              case PersonCreatedFor.NewMarriageNewHusband:
                if (response.data.isActive)
                  marriageData.husband = response.data;

                this.setState({ marriageData, isNewMarriageModalOpen: true });
                break;
              case PersonCreatedFor.NewMarriageNewWife:
                if (response.data.isActive) marriageData.wife = response.data;

                this.setState({ marriageData, isNewMarriageModalOpen: true });
                break;
              case PersonCreatedFor.NewHouseholdNewPrimaryPerson:
                if (response.data.isActive)
                  newHouseholdData.primaryPerson = response.data;

                this.setState({
                  newHouseholdData,
                  isNewHouseholdModalOpen: true,
                });
                break;
              case PersonCreatedFor.NewHouseholdNewSecondaryPerson:
                if (response.data.isActive)
                  newHouseholdData.secondaryPerson = response.data;

                this.setState({
                  newHouseholdData,
                  isNewHouseholdModalOpen: true,
                });
                break;
              case PersonCreatedFor.EditHouseholdAddChild:
                let { personData } = this.state;
                personData = response.data;
                newHouseholdMemberData.household = editHouseholdData;
                newHouseholdMemberData.person = personData;
                newHouseholdMemberData.isActive = personData?.isActive;
                this.setState({
                  isEditHouseholdModalOpen: true,
                  newHouseholdMemberData,
                  personData,
                });
                this.addHouseholdMemberForNewChildToEditingHousehold();
                break;
              case PersonCreatedFor.EditHouseholdNewHouseholdMemberNewPerson:
                if (response.data.isActive)
                  newHouseholdMemberData.person = response.data;

                this.setState({
                  newHouseholdMemberData,
                  isNewHouseholdMemberModalOpen: true,
                });
                break;
              case PersonCreatedFor.EditHouseholdNewPrimaryPerson:
                if (response.data.isActive)
                  editHouseholdData.primaryPerson = response.data;

                this.setState({
                  editHouseholdData,
                  isEditHouseholdModalOpen: true,
                });
                break;
              case PersonCreatedFor.EditHouseholdNewSecondaryPerson:
                if (response.data.isActive)
                  editHouseholdData.secondaryPerson = response.data;

                this.setState({
                  editHouseholdData,
                  isEditHouseholdModalOpen: true,
                });
                break;
              default:
                break;
            }
            this.setState({
              personData: this.resetPerson(),
            });
          })
          .catch((error) => {
            this.handlePersonCatch(error, MessageForAction.Create);
          });
      }
    });
  }

  removeHouseholdMessages(actionType: MessageForAction) {
    let filteredMessages = this.state.householdMessages.filter(
      (m) => m.messageForAction !== actionType
    );
    this.setState({ householdMessages: filteredMessages });
  }

  removeMarriageMessages(actionType: MessageForAction) {
    let filteredMessages = this.state.marriageMessages.filter(
      (m) => m.messageForAction !== actionType
    );
    this.setState({ marriageMessages: filteredMessages });
  }

  removePersonMessages(actionType: MessageForAction) {
    let filteredMessages = this.state.personMessages.filter(
      (m) => m.messageForAction !== actionType
    );
    this.setState({ personMessages: filteredMessages });
  }

  removeHouseholdMemberMessages(actionType: MessageForAction) {
    let filteredMessages = this.state.householdMemberMessages.filter(
      (m) => m.messageForAction !== actionType
    );
    this.setState({ householdMemberMessages: filteredMessages });
  }

  handleNewPrimaryPersonSelect = (selectedPrimaryPerson: any) => {
    let { newHouseholdData } = this.state;
    newHouseholdData.primaryPerson = selectedPrimaryPerson;
    this.setState({ newHouseholdData });
  };

  handleSelectExistingMarriage = (selectExistingMarriage: any) => {
    this.setState({ marriageData: selectExistingMarriage });
  };

  handleNewSecondaryPersonSelect = (selectedSecondaryPerson: any) => {
    let { newHouseholdData } = this.state;
    newHouseholdData.secondaryPerson = selectedSecondaryPerson;
    this.setState({ newHouseholdData });
  };

  handleEditPrimaryPersonSelect = (selectedPrimaryPerson: any) => {
    let { editHouseholdData } = this.state;
    editHouseholdData.primaryPerson = selectedPrimaryPerson;
    this.setState({ editHouseholdData });
  };

  handleEditSecondaryPersonSelect = (selectedSecondaryPerson: any) => {
    let { editHouseholdData } = this.state;
    editHouseholdData.secondaryPerson = selectedSecondaryPerson;
    this.setState({ editHouseholdData });
  };

  handleNewMemberOfChurchSelect = (selectedMemberOfChurch: any) => {
    let { personData } = this.state;
    personData.memberOfChurch = selectedMemberOfChurch;
    this.setState({ personData });
  };

  handleNewFatherSelect = (selectedFather: any) => {
    let { personData } = this.state;
    personData.father = selectedFather;
    this.setState({ personData });
  };

  handleNewMotherSelect = (selectedMother: any) => {
    let { personData } = this.state;
    personData.mother = selectedMother;
    this.setState({ personData });
  };

  handleEditMemberOfChurchSelect = (selectedMemberOfChurch: any) => {
    let { personData } = this.state;
    personData.memberOfChurch = selectedMemberOfChurch;
    this.setState({ personData });
  };

  handleEditFatherSelect = (selectedFather: any) => {
    let { personData } = this.state;
    personData.father = selectedFather;
    this.setState({ personData });
  };

  handleEditMotherSelect = (selectedMother: any) => {
    let { personData } = this.state;
    personData.mother = selectedMother;
    this.setState({ personData });
  };

  handleActionsClick = (
    event: React.MouseEvent<HTMLElement>,
    booleanPropertyName: string
  ) => {
    this.setState({
      anchorElement: event.currentTarget,
    });
    this.setState((prevState) => ({
      ...prevState,
      [booleanPropertyName]: true,
    }));
  };

  handleHouseholdTableActionsClick = (
    event: React.MouseEvent<HTMLElement>,
    household: any
  ) => {
    this.setState({
      anchorElement: event.currentTarget,
      householdDataForActionsMenu: household,
      isHouseholdTableActionsMenuOpen: true,
    });
  };

  toggleEditHouseholdModal() {
    this.setState({
      isEditHouseholdModalOpen: !this.state.isEditHouseholdModalOpen,
    });
    this.removeHouseholdMessages(MessageForAction.Update);
  }

  toggleDeleteHouseholdModal() {
    this.setState({
      isDeleteHouseholdConfirmModalOpen: !this.state
        .isDeleteHouseholdConfirmModalOpen,
    });
    this.removeHouseholdMessages(MessageForAction.Delete);
  }

  addNewHousehold() {
    this.removeHouseholdMessages(MessageForAction.Create);
    this.authService.getUser().then((user) => {
      if (user && user.access_token) {
        const headers = Helpers.getHeaders(user.access_token);
        Axios.post(
          `${IdentityConstants.apiRoot}Household`,
          this.CopyHouseholdFrom(this.state.newHouseholdData),
          { headers }
        )
          .then((_) => {
            this.refreshTableData();
            this.setState({
              isNewHouseholdModalOpen: false,
              newHouseholdData: this.resetHousehold(),
              marriageData: this.resetMarriage(),
              personData: this.resetPerson(),
            });
          })
          .catch((error) => {
            this.handleHouseholdCatch(error, MessageForAction.Create);
          });
      }
    });
  }

  updateHousehold() {
    this.removeHouseholdMessages(MessageForAction.Update);
    this.removeMarriageMessages(MessageForAction.Update);
    this.authService.getUser().then((user) => {
      if (user && user.access_token) {
        const headers = Helpers.getHeaders(user.access_token);
        Axios.put(
          `${IdentityConstants.apiRoot}Household/${this.state.editHouseholdData.id}`,
          this.CopyHouseholdFrom(this.state.editHouseholdData),
          { headers }
        )
          .then((_) => {
            this.refreshHouseholds();
            this.setState({
              editHouseholdData: this.resetHousehold(),
            });
          })
          .catch((error) => {
            this.handleHouseholdCatch(error, MessageForAction.Update);
          });

        if (this.state.editMarriageData.id) {
          Axios.put(
            `${IdentityConstants.apiRoot}Marriage/${this.state.editMarriageData.id}`,
            this.CopyMarriageFrom(this.state.editMarriageData),
            { headers }
          )
            .then((_) => {
              this.setState({
                editMarriageData: this.resetMarriage(),
              });
            })
            .catch((error) => {
              this.handleMarriageCatch(error, MessageForAction.Update);
            });
        }
        this.setState({
          isEditHouseholdModalOpen: false,
        });
      }
    });
  }

  updatePerson() {
    this.removePersonMessages(MessageForAction.Update);
    this.authService.getUser().then((user) => {
      if (user && user.access_token) {
        const headers = Helpers.getHeaders(user.access_token);
        Axios.put(
          `${IdentityConstants.apiRoot}Person/${this.state.personData.id}`,
          this.CopyPersonFrom(this.state.personData),
          { headers }
        )
          .then((response) => {
            this.setState({
              isEditPersonModalOpen: false,
            });
            let { newHouseholdData, editHouseholdData } = this.state;

            switch (this.state.personEditedFor) {
              case PersonEditedFor.NewHouseholdEditPrimaryPerson:
                if (response.data.isActive)
                  newHouseholdData.primaryPerson = response.data;

                this.setState({
                  newHouseholdData,
                  isNewHouseholdModalOpen: true,
                });
                break;
              case PersonEditedFor.NewHouseholdEditSecondaryPerson:
                if (response.data.isActive)
                  newHouseholdData.secondaryPerson = response.data;

                this.setState({
                  newHouseholdData,
                  isNewHouseholdModalOpen: true,
                });
                break;
              case PersonEditedFor.EditHouseholdEditPrimaryPerson:
                if (response.data.isActive)
                  editHouseholdData.primaryPerson = response.data;

                this.setState({
                  editHouseholdData,
                  isEditHouseholdModalOpen: true,
                });
                break;
              case PersonEditedFor.EditHouseholdEditSecondaryPerson:
                if (response.data.isActive)
                  editHouseholdData.secondaryPerson = response.data;

                this.setState({
                  editHouseholdData,
                  isEditHouseholdModalOpen: true,
                });
                break;
              case PersonEditedFor.EditHouseholdEditHouseholdMemberEditPerson:
                let index = editHouseholdData.householdMembers.findIndex(
                  (x) => x.id === this.state.editHouseholdMemberData.id
                );
                if (index !== -1) {
                  editHouseholdData.householdMembers[index].person =
                    response.data;
                }

                this.setState({
                  editHouseholdData,
                  isEditHouseholdModalOpen: true,
                  editHouseholdMemberData: this.resetHouseholdMember(),
                });
                break;
              default:
                break;
            }
            this.setState({
              personData: this.resetPerson(),
            });
          })
          .catch((error) => {
            this.handlePersonCatch(error, MessageForAction.Update);
          });
      }
    });
  }

  addChildButtonClickHandle() {
    let { editHouseholdData, personData } = this.state;
    personData.father = editHouseholdData.primaryPerson;
    personData.mother = editHouseholdData.secondaryPerson;
    personData.lastName = editHouseholdData.primaryPerson?.lastName;
    this.setState({
      personData,
      isEditHouseholdModalOpen: false,
      isNewPersonModalOpen: true,
      personCreatedFor: PersonCreatedFor.EditHouseholdAddChild,
    });
  }

  handleAddPersonToHouseholdMember() {
    let { personData, editHouseholdData } = this.state;
    personData.father = editHouseholdData.primaryPerson;
    personData.mother = editHouseholdData.secondaryPerson;
    personData.lastName = editHouseholdData.primaryPerson?.lastName;
    this.setState({
      personData,
      isNewHouseholdMemberModalOpen: false,
      isNewPersonModalOpen: true,
      personCreatedFor:
        PersonCreatedFor.EditHouseholdNewHouseholdMemberNewPerson,
    });
  }

  deleteHousehold() {
    this.removeHouseholdMessages(MessageForAction.Delete);
    this.authService.getUser().then((user) => {
      if (user && user.access_token) {
        const headers = Helpers.getHeaders(user.access_token);
        Axios.delete(
          `${IdentityConstants.apiRoot}Household/${this.state.deleteHouseholdData.id}`,
          { headers }
        )
          .then(() => {
            this.refreshTableData();
            this.setState({
              isDeleteHouseholdConfirmModalOpen: false,
              deleteHouseholdData: { id: "", deleteMessage: "" },
            });
          })
          .catch((error) => {
            this.handleHouseholdCatch(error, MessageForAction.Delete);
          });
      }
    });
  }

  deleteHouseholdMemberFromEditingHousehold() {
    this.removeHouseholdMemberMessages(MessageForAction.Delete);
    this.authService.getUser().then((user) => {
      if (user && user.access_token) {
        const headers = Helpers.getHeaders(user.access_token);
        Axios.delete(
          `${IdentityConstants.apiRoot}HouseholdMember/${this.state.deleteHouseholdMemberData.id}`,
          {
            headers,
          }
        )
          .then(() => {
            let { editHouseholdData } = this.state;
            editHouseholdData.householdMembers = editHouseholdData.householdMembers.filter(
              (x) => x.id !== this.state.deleteHouseholdMemberData.id
            );
            this.setState({
              editHouseholdData,
              isDeleteHouseholdMemberConfirmModalOpen: false,
              isEditHouseholdModalOpen: true,
              deleteHouseholdMemberData: {
                id: "",
                deleteMessage: "",
              },
            });
          })
          .catch((error) => {
            this.handleHouseholdMemberCatch(error, MessageForAction.Delete);
          });
      }
    });
  }

  deleteHouseholdMemberSetState(id: any, deleteMessage: any) {
    let { deleteHouseholdMemberData } = this.state;
    deleteHouseholdMemberData.id = id;
    deleteHouseholdMemberData.deleteMessage = deleteMessage;
    this.setState({
      deleteHouseholdMemberData,
      isDeleteHouseholdMemberConfirmModalOpen: true,
      isEditHouseholdModalOpen: false,
    });
  }

  editHouseholdSetState() {
    let household = this.state.householdDataForActionsMenu;
    let { editHouseholdData } = this.state;
    editHouseholdData = household;
    editHouseholdData.addressLine2 = household.addressLine2 ?? "";
    editHouseholdData.comment1 = household.comment1 ?? "";
    editHouseholdData.comment2 = household.comment2 ?? "";
    editHouseholdData.comment3 = household.comment3 ?? "";
    editHouseholdData.comment4 = household.comment4 ?? "";
    this.setState({
      isHouseholdsMarriageInformationShow: false,
    });
    this.removeMarriageMessages(MessageForAction.Read);
    this.authService.getUser().then((user) => {
      if (user && user.access_token) {
        const headers = Helpers.getHeaders(user.access_token);
        let url = `${IdentityConstants.apiRoot}Marriage/GetByHusbandAndWife?husbandId=${household.primaryPerson?.id}`;
        if (household.secondaryPerson) {
          url += `&wifeId=${household.secondaryPerson?.id}`;
        }
        Axios.get(url, {
          headers,
        })
          .then((response) => {
            if (response.data) {
              this.setState({
                editMarriageData: response.data,
                isHouseholdsMarriageInformationShow: true,
              });
            }
          })
          .catch((error) => {
            this.handleMarriageCatch(error, MessageForAction.Read);
          });
      }
    });

    this.setState({
      editHouseholdData,
      householdDataForActionsMenu: this.resetHousehold(),
      isEditHouseholdModalOpen: true,
    });
  }

  editHouseholdMemberSetState(householdMember: any) {
    this.authService.getUser().then((user) => {
      if (user && user.access_token) {
        const headers = Helpers.getHeaders(user.access_token);
        Axios.get(
          `${IdentityConstants.apiRoot}Person/${householdMember.person.id}`,
          {
            headers,
          }
        )
          .then((response) => {
            let person = response.data;
            person.deathComment = response.data.deathComment ?? "";
            person.comment = response.data.comment ?? "";
            person.phone1 = response.data.phone1 ?? "";
            person.phone2 = response.data.phone2 ?? "";
            person.email = response.data.email ?? "";
            person.middleName = response.data.middleName ?? "";
            this.setState({
              editHouseholdMemberData: householdMember,
              personData: person,
              personEditedFor:
                PersonEditedFor.EditHouseholdEditHouseholdMemberEditPerson,
              isEditHouseholdModalOpen: false,
              isEditPersonModalOpen: true,
            });
          })
          .catch((error) => {
            this.handleHouseholdCatch(error, MessageForAction.Read);
          });
      }
    });
  }

  deleteHouseholdSetState() {
    let { deleteHouseholdData } = this.state;
    let household = this.state.householdDataForActionsMenu;
    deleteHouseholdData.id = household.id;
    deleteHouseholdData.deleteMessage = `${household.number} ${household.primaryPerson?.title} ${household.secondaryPerson?.title}`;
    this.setState({
      deleteHouseholdData,
      householdDataForActionsMenu: this.resetHousehold(),
      isDeleteHouseholdConfirmModalOpen: true,
    });
  }

  viewHouseholdSetState(id: string) {
    this.setState({ viewHouseholdData: {} });
    this.authService.getUser().then((user) => {
      if (user && user.access_token) {
        const headers = Helpers.getHeaders(user.access_token);
        Axios.get(
          `${IdentityConstants.apiRoot}Household/GetHouseholdViewById/${id}`,
          { headers }
        )
          .then((response) => {
            let { viewHouseholdData } = this.state;
            viewHouseholdData = response.data;
            viewHouseholdData.comment1 = viewHouseholdData.comment1 ?? "";
            viewHouseholdData.comment2 = viewHouseholdData.comment2 ?? "";
            viewHouseholdData.comment3 = viewHouseholdData.comment3 ?? "";
            viewHouseholdData.comment4 = viewHouseholdData.comment4 ?? "";
            this.setState({ viewHouseholdData });
          })
          .catch((error) => {
            this.handleHouseholdCatch(error, MessageForAction.Read);
          });
      }
    });
    this.setState({
      isViewHouseholdModalOpen: true,
    });
  }

  handleChangePage(
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) {
    this.setState({ pageNumber: newPage });
    this.debounceRefreshHouseholds();
  }

  public render() {
    const { classes } = this.props;
    let households = this.state.households.map((household) => {
      return (
        <TableRow key={household.id}>
          <TableCell>
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={(e) =>
                this.handleHouseholdTableActionsClick(e, household)
              }
            >
              <MoreVertIcon />
            </IconButton>
          </TableCell>
          <TableCell
            onClick={() => {
              this.onRowClick(household);
            }}
          >
            {household.number}
          </TableCell>
          <TableCell
            onClick={() => {
              this.onRowClick(household);
            }}
          >
            {household.primaryPerson?.title}
          </TableCell>
          <TableCell
            onClick={() => {
              this.onRowClick(household);
            }}
          >
            {household.secondaryPerson?.title}
          </TableCell>
          <TableCell
            onClick={() => {
              this.onRowClick(household);
            }}
          >
            {household.phone}
          </TableCell>
          <TableCell
            onClick={() => {
              this.onRowClick(household);
            }}
          >
            {household.addressLine1}
          </TableCell>
          <TableCell
            onClick={() => {
              this.onRowClick(household);
            }}
          >
            {household.addressLine2}
          </TableCell>
          <TableCell
            onClick={() => {
              this.onRowClick(household);
            }}
          >
            {household.state}
          </TableCell>
          <TableCell
            onClick={() => {
              this.onRowClick(household);
            }}
          >
            {household.city}
          </TableCell>
          <TableCell
            onClick={() => {
              this.onRowClick(household);
            }}
          >
            {household.postal}
          </TableCell>
          <TableCell
            onClick={() => {
              this.onRowClick(household);
            }}
          >
            {household.comment1}
          </TableCell>
          <TableCell>
            <Switch
              checked={household.isActive}
              color="primary"
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </TableCell>
        </TableRow>
      );
    });
    return (
      <Paper className={classes.paper}>
        <AppBar
          className={classes.searchBar}
          position="static"
          color="default"
          elevation={0}
        >
          <Toolbar>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <Search className={classes.block} color="inherit" />
              </Grid>
              <Grid item xs>
                <TextField
                  fullWidth
                  placeholder="Search by PrimaryPerson and SecondaryPerson's first and last name"
                  value={this.state.searchText}
                  onChange={(e) => {
                    this.setState({ searchText: e.target.value });
                  }}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      this.setState({ pageNumber: 0 });
                      this.refreshTableData();
                      e.preventDefault();
                    }
                  }}
                  InputProps={{
                    disableUnderline: true,
                    className: classes.searchInput,
                  }}
                />
              </Grid>
              <Grid item>
                <Tooltip title="Add">
                  <IconButton
                    onClick={() => {
                      this.setState({ isNewMarriageQuestionModalOpen: true });
                    }}
                  >
                    <Add />
                  </IconButton>
                </Tooltip>
                <Menu
                  id="householdTableMenu"
                  anchorEl={this.state.anchorElement}
                  keepMounted
                  open={this.state.isHouseholdTableActionsMenuOpen}
                  onClose={() => {
                    this.setState({
                      isHouseholdTableActionsMenuOpen: false,
                    });
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      this.setState({ isHouseholdTableActionsMenuOpen: false });
                      this.editHouseholdSetState();
                    }}
                  >
                    <ListItemIcon>
                      <Edit fontSize="small" />
                    </ListItemIcon>
                    Edit
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      this.setState({ isHouseholdTableActionsMenuOpen: false });
                      this.deleteHouseholdSetState();
                    }}
                  >
                    <ListItemIcon>
                      <Delete fontSize="small" />
                    </ListItemIcon>
                    Delete
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      this.setState({ isHouseholdTableActionsMenuOpen: false });
                      this.viewHouseholdSetState(
                        this.state.householdDataForActionsMenu.id
                      );
                    }}
                  >
                    <ListItemIcon>
                      <Visibility fontSize="small" />
                    </ListItemIcon>
                    View
                  </MenuItem>
                </Menu>
                <Dialog
                  open={this.state.isViewHouseholdModalOpen}
                  onClose={() => {
                    this.setState({
                      isViewHouseholdModalOpen: false,
                    });
                  }}
                  aria-labelledby="form-dialog-title"
                  maxWidth={"md"}
                  fullWidth={true}
                >
                  <DialogTitle id="form-dialog-title">
                    {this.state.viewHouseholdData?.primaryPerson ?? ""}
                  </DialogTitle>
                  <DialogContent>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        {this.state.viewHouseholdData?.primaryPersonBirthDate
                          ? "b. " +
                            this.state.viewHouseholdData?.primaryPersonBirthDate
                          : ""}
                        {this.state.viewHouseholdData?.marriageDate ? (
                          <span style={{ paddingLeft: 15 }}>
                            {" m. " +
                              this.state.viewHouseholdData?.marriageDate}
                          </span>
                        ) : (
                          ""
                        )}
                        {this.state.viewHouseholdData?.primaryPersonPhone1 ? (
                          <span style={{ paddingLeft: 15 }}>
                            {" phone: " +
                              this.state.viewHouseholdData?.primaryPersonPhone1}
                          </span>
                        ) : (
                          ""
                        )}

                        {this.state.viewHouseholdData?.primaryPersonPhone2 ? (
                          <span style={{ paddingLeft: 15 }}>
                            {this.state.viewHouseholdData?.primaryPersonPhone1
                              ? this.state.viewHouseholdData
                                  ?.primaryPersonPhone2
                              : " phone: " +
                                this.state.viewHouseholdData
                                  ?.primaryPersonPhone2}
                          </span>
                        ) : (
                          ""
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        {this.state.viewHouseholdData?.secondaryPerson ? (
                          <span style={{ fontWeight: "bold" }}>
                            {this.state.viewHouseholdData?.secondaryPerson}
                          </span>
                        ) : (
                          ""
                        )}
                        {this.state.viewHouseholdData?.secondaryPersonBirthDate
                          ? " b. " +
                            this.state.viewHouseholdData
                              ?.secondaryPersonBirthDate
                          : ""}
                        {this.state.viewHouseholdData?.secondaryPersonPhone1 ? (
                          <span style={{ paddingLeft: 15 }}>
                            {" phone: " +
                              this.state.viewHouseholdData
                                ?.secondaryPersonPhone1}
                          </span>
                        ) : (
                          ""
                        )}

                        {this.state.viewHouseholdData?.secondaryPersonPhone2 ? (
                          <span style={{ paddingLeft: 15 }}>
                            {this.state.viewHouseholdData?.secondaryPersonPhone1
                              ? this.state.viewHouseholdData
                                  ?.secondaryPersonPhone2
                              : " phone: " +
                                this.state.viewHouseholdData
                                  ?.secondaryPersonPhone2}
                          </span>
                        ) : (
                          ""
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        {this.state.viewHouseholdData?.addressLine1 ||
                        this.state.viewHouseholdData?.city ||
                        this.state.viewHouseholdData?.state ||
                        this.state.viewHouseholdData?.postal ? (
                          <>
                            <span style={{ fontStyle: "italic" }}>
                              Address:{" "}
                            </span>
                            {this.state.viewHouseholdData?.addressLine1}
                            {" " + this.state.viewHouseholdData?.city}
                            {" " + this.state.viewHouseholdData?.state}
                            {" " + this.state.viewHouseholdData?.postal}
                          </>
                        ) : (
                          ""
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        {this.state.viewHouseholdData?.phone ? (
                          <>
                            <span style={{ fontStyle: "italic" }}>Phone: </span>
                            {this.state.viewHouseholdData?.phone}
                          </>
                        ) : (
                          ""
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        {this.state.viewHouseholdData?.children ? (
                          <>
                            <span style={{ fontStyle: "italic" }}>
                              Members:{" "}
                            </span>
                            {this.state.viewHouseholdData?.church}
                          </>
                        ) : (
                          ""
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <Table
                          aria-label="simple table"
                          style={{ marginBottom: 20 }}
                        >
                          <TableBody>
                            {this.state.viewHouseholdData?.children
                              ? this.state.viewHouseholdData.children.map(
                                  (householdMember) => {
                                    return (
                                      <TableRow key={householdMember.id}>
                                        <TableCell>
                                          <span style={{ fontWeight: "bold" }}>
                                            {householdMember.isMemberOfChurch
                                              ? "* "
                                              : ""}
                                            {householdMember.title}
                                          </span>
                                        </TableCell>
                                        <TableCell>
                                          {householdMember.birthDate
                                            ? "b. " + householdMember.birthDate
                                            : ""}
                                          {householdMember.deathDate ? (
                                            <Typography
                                              variant="body2"
                                              gutterBottom
                                            >
                                              {"d. " +
                                                householdMember.deathDate}
                                            </Typography>
                                          ) : null}
                                          {householdMember.deathComment ? (
                                            <Typography
                                              variant="body2"
                                              gutterBottom
                                            >
                                              {householdMember.deathComment}
                                            </Typography>
                                          ) : null}
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            whiteSpace: "pre-line",
                                          }}
                                        >
                                          {householdMember.spouses
                                            ? `m. ${householdMember.spouses}`
                                            : ""}
                                        </TableCell>
                                      </TableRow>
                                    );
                                  }
                                )
                              : null}
                          </TableBody>
                        </Table>
                      </Grid>
                      {this.state.viewHouseholdData
                        ?.primaryPersonPreviousMarriages
                        ? this.state.viewHouseholdData.primaryPersonPreviousMarriages.map(
                            (primaryPersonPreviousMarriage) => {
                              return (
                                <Grid
                                  key={
                                    primaryPersonPreviousMarriage.previousMarriageInformation
                                  }
                                >
                                  <Grid item xs={12}>
                                    <span style={{ fontStyle: "italic" }}>
                                      {
                                        primaryPersonPreviousMarriage.previousMarriageInformation
                                      }
                                    </span>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Table
                                      aria-label="simple table"
                                      style={{ marginBottom: 20 }}
                                    >
                                      <TableBody>
                                        {primaryPersonPreviousMarriage.children
                                          ? primaryPersonPreviousMarriage.children.map(
                                              (child) => {
                                                return (
                                                  <TableRow key={child.id}>
                                                    <TableCell>
                                                      <span
                                                        style={{
                                                          fontWeight: "bold",
                                                        }}
                                                      >
                                                        {child.isMemberOfChurch
                                                          ? "* "
                                                          : ""}
                                                        {child.title}
                                                      </span>
                                                    </TableCell>
                                                    <TableCell>
                                                      {child.birthDate
                                                        ? "b. " +
                                                          child.birthDate
                                                        : ""}
                                                      {child.deathDate ? (
                                                        <Typography
                                                          variant="body2"
                                                          gutterBottom
                                                        >
                                                          {"d. " +
                                                            child.deathDate}
                                                        </Typography>
                                                      ) : null}
                                                      {child.deathComment ? (
                                                        <Typography
                                                          variant="body2"
                                                          gutterBottom
                                                        >
                                                          {child.deathComment}
                                                        </Typography>
                                                      ) : null}
                                                    </TableCell>
                                                    <TableCell
                                                      style={{
                                                        whiteSpace: "pre-line",
                                                      }}
                                                    >
                                                      {child.spouses
                                                        ? `m. ${child.spouses}`
                                                        : ""}
                                                    </TableCell>
                                                  </TableRow>
                                                );
                                              }
                                            )
                                          : null}
                                      </TableBody>
                                    </Table>
                                  </Grid>
                                </Grid>
                              );
                            }
                          )
                        : null}
                      {this.state.viewHouseholdData
                        ?.secondaryPersonPreviousMarriages
                        ? this.state.viewHouseholdData.secondaryPersonPreviousMarriages.map(
                            (secondaryPersonPreviousMarriage) => {
                              return (
                                <Grid
                                  key={
                                    secondaryPersonPreviousMarriage.previousMarriageInformation
                                  }
                                >
                                  <Grid item xs={12}>
                                    <span style={{ fontStyle: "italic" }}>
                                      {
                                        secondaryPersonPreviousMarriage.previousMarriageInformation
                                      }
                                    </span>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Table
                                      aria-label="simple table"
                                      style={{ marginBottom: 20 }}
                                    >
                                      <TableBody>
                                        {secondaryPersonPreviousMarriage.children
                                          ? secondaryPersonPreviousMarriage.children.map(
                                              (child) => {
                                                return (
                                                  <TableRow key={child.id}>
                                                    <TableCell>
                                                      <span
                                                        style={{
                                                          fontWeight: "bold",
                                                        }}
                                                      >
                                                        {child.isMemberOfChurch
                                                          ? "* "
                                                          : ""}
                                                        {child.title}
                                                      </span>
                                                    </TableCell>
                                                    <TableCell>
                                                      {child.birthDate
                                                        ? "b. " +
                                                          child.birthDate
                                                        : ""}
                                                      {child.deathDate ? (
                                                        <Typography
                                                          variant="body2"
                                                          gutterBottom
                                                        >
                                                          {"d. " +
                                                            child.deathDate}
                                                        </Typography>
                                                      ) : null}
                                                      {child.deathComment ? (
                                                        <Typography
                                                          variant="body2"
                                                          gutterBottom
                                                        >
                                                          {child.deathComment}
                                                        </Typography>
                                                      ) : null}
                                                    </TableCell>
                                                    <TableCell
                                                      style={{
                                                        whiteSpace: "pre-line",
                                                      }}
                                                    >
                                                      {child.spouses
                                                        ? `m. ${child.spouses}`
                                                        : ""}
                                                    </TableCell>
                                                  </TableRow>
                                                );
                                              }
                                            )
                                          : null}
                                      </TableBody>
                                    </Table>
                                  </Grid>
                                </Grid>
                              );
                            }
                          )
                        : null}
                      {this.state.viewHouseholdData?.comment1 ? (
                        <Grid item xs={12}>
                          {"Comment 1: " +
                            this.state.viewHouseholdData.comment1}
                        </Grid>
                      ) : null}
                      {this.state.viewHouseholdData?.comment2 ? (
                        <Grid item xs={12}>
                          {"Comment 2: " +
                            this.state.viewHouseholdData.comment2}
                        </Grid>
                      ) : null}
                      {this.state.viewHouseholdData?.comment3 ? (
                        <Grid item xs={12}>
                          {"Comment 3: " +
                            this.state.viewHouseholdData.comment3}
                        </Grid>
                      ) : null}
                      {this.state.viewHouseholdData?.comment4 ? (
                        <Grid item xs={12}>
                          {"Comment 4: " +
                            this.state.viewHouseholdData.comment4}
                        </Grid>
                      ) : null}
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      color="primary"
                      onClick={() => {
                        this.setState({ isViewHouseholdModalOpen: false });
                        this.editHouseholdSetState();
                      }}
                    >
                      Edit
                    </Button>
                    <Button
                      color="secondary"
                      onClick={() => {
                        this.setState({
                          isViewHouseholdModalOpen: false,
                          householdDataForActionsMenu: this.resetHousehold(),
                        });
                      }}
                    >
                      Cancel
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  open={this.state.isNewHouseholdModalOpen}
                  onClose={() => {
                    this.setState({ isNewHouseholdModalOpen: false });
                  }}
                  aria-labelledby="form-dialog-title"
                >
                  <DialogTitle id="form-dialog-title">
                    Add Household
                  </DialogTitle>
                  <DialogContent>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={5}>
                        <ReferenceEntitySelector
                          componentId="addPrimaryPersonSelector"
                          selectedItem={
                            this.state.newHouseholdData.primaryPerson
                          }
                          label="PrimaryPerson"
                          url="Person/GetItemsForReferenceControl"
                          required={true}
                          onReferenceEntitySelectEvent={
                            this.handleNewPrimaryPersonSelect
                          }
                          handleException={(error) =>
                            this.handleHouseholdCatch(
                              error,
                              MessageForAction.Create
                            )
                          }
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={1}
                        style={{
                          paddingTop: 20,
                          paddingLeft: 0,
                          paddingRight: 0,
                        }}
                      >
                        <IconButton
                          aria-label="more"
                          aria-controls="long-menu"
                          aria-haspopup="true"
                          onClick={(e) =>
                            this.handleActionsClick(
                              e,
                              "isNewHouseholdPrimaryPersonActionsMenuOpen"
                            )
                          }
                        >
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          id="newHouseholdEditPrimaryPerson"
                          anchorEl={this.state.anchorElement}
                          keepMounted
                          open={
                            this.state
                              .isNewHouseholdPrimaryPersonActionsMenuOpen
                          }
                          onClose={() => {
                            this.setState({
                              isNewHouseholdPrimaryPersonActionsMenuOpen: false,
                            });
                          }}
                        >
                          <MenuItem
                            onClick={() =>
                              this.setState({
                                personData: this.resetPerson(),
                                isNewHouseholdModalOpen: false,
                                isNewHouseholdPrimaryPersonActionsMenuOpen: false,
                                isNewPersonModalOpen: true,
                                personCreatedFor:
                                  PersonCreatedFor.NewHouseholdNewPrimaryPerson,
                              })
                            }
                          >
                            <ListItemIcon>
                              <Add fontSize="small" />
                            </ListItemIcon>
                            Add PrimaryPerson
                          </MenuItem>
                          {this.state.newHouseholdData.primaryPerson ? (
                            <MenuItem
                              onClick={this.handleHouseholdEditPerson.bind(
                                this,
                                this.state.newHouseholdData.primaryPerson,
                                PersonEditedFor.NewHouseholdEditPrimaryPerson,
                                "isNewHouseholdPrimaryPersonActionsMenuOpen",
                                "isNewHouseholdModalOpen"
                              )}
                            >
                              <ListItemIcon>
                                <Edit fontSize="small" />
                              </ListItemIcon>
                              Edit PrimaryPerson
                            </MenuItem>
                          ) : null}
                        </Menu>
                      </Grid>
                      <Grid item xs={12} sm={5}>
                        <ReferenceEntitySelector
                          componentId="addSecondaryPersonSelector"
                          selectedItem={
                            this.state.newHouseholdData.secondaryPerson
                          }
                          label="SecondaryPerson"
                          url="Person/GetItemsForReferenceControl"
                          onReferenceEntitySelectEvent={
                            this.handleNewSecondaryPersonSelect
                          }
                          handleException={(error) =>
                            this.handleHouseholdCatch(
                              error,
                              MessageForAction.Create
                            )
                          }
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={1}
                        style={{
                          paddingTop: 20,
                          paddingLeft: 0,
                          paddingRight: 0,
                        }}
                      >
                        <IconButton
                          aria-label="more"
                          aria-controls="long-menu"
                          aria-haspopup="true"
                          onClick={(e) =>
                            this.handleActionsClick(
                              e,
                              "isNewHouseholdSecondaryPersonActionsMenuOpen"
                            )
                          }
                        >
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          id="newHouseholdEditSecondaryPerson"
                          anchorEl={this.state.anchorElement}
                          keepMounted
                          open={
                            this.state
                              .isNewHouseholdSecondaryPersonActionsMenuOpen
                          }
                          onClose={() => {
                            this.setState({
                              isNewHouseholdSecondaryPersonActionsMenuOpen: false,
                            });
                          }}
                        >
                          <MenuItem
                            onClick={() =>
                              this.setState({
                                personData: this.resetPerson(),
                                isNewHouseholdModalOpen: false,
                                isNewHouseholdSecondaryPersonActionsMenuOpen: false,
                                isNewPersonModalOpen: true,
                                personCreatedFor:
                                  PersonCreatedFor.NewHouseholdNewSecondaryPerson,
                              })
                            }
                          >
                            <ListItemIcon>
                              <Add fontSize="small" />
                            </ListItemIcon>
                            Add SecondaryPerson
                          </MenuItem>
                          {this.state.newHouseholdData.secondaryPerson ? (
                            <MenuItem
                              onClick={this.handleHouseholdEditPerson.bind(
                                this,
                                this.state.newHouseholdData.secondaryPerson,
                                PersonEditedFor.NewHouseholdEditSecondaryPerson,
                                "isNewHouseholdSecondaryPersonActionsMenuOpen",
                                "isNewHouseholdModalOpen"
                              )}
                            >
                              <ListItemIcon>
                                <Edit fontSize="small" />
                              </ListItemIcon>
                              Edit SecondaryPerson
                            </MenuItem>
                          ) : null}
                        </Menu>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="addNumber"
                          label="Number"
                          fullWidth
                          value={this.state.newHouseholdData.number}
                          onChange={(e) => {
                            let { newHouseholdData } = this.state;
                            newHouseholdData.number = e.target.value;
                            this.setState({ newHouseholdData });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="addPhone"
                          label="Phone"
                          fullWidth
                          value={this.state.newHouseholdData.phone}
                          onChange={(e) => {
                            let { newHouseholdData } = this.state;
                            newHouseholdData.phone = e.target.value;
                            this.setState({ newHouseholdData });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="addAddressLine1"
                          label="AddressLine1"
                          fullWidth
                          value={this.state.newHouseholdData.addressLine1}
                          onChange={(e) => {
                            let { newHouseholdData } = this.state;
                            newHouseholdData.addressLine1 = e.target.value;
                            this.setState({ newHouseholdData });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="addAddressLine2"
                          label="AddressLine2"
                          fullWidth
                          value={this.state.newHouseholdData.addressLine2}
                          onChange={(e) => {
                            let { newHouseholdData } = this.state;
                            newHouseholdData.addressLine2 = e.target.value;
                            this.setState({ newHouseholdData });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Autocomplete
                          id="addState"
                          freeSolo
                          includeInputInList
                          autoSelect
                          onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                            let { newHouseholdData } = this.state;
                            newHouseholdData.state = e.target.value;
                            newHouseholdData.city = "";
                            this.setState({ newHouseholdData });
                          }}
                          onChange={(event: any, value: any) => {
                            let { newHouseholdData } = this.state;
                            newHouseholdData.state = value;
                            newHouseholdData.city = "";
                            this.setState({ newHouseholdData });
                          }}
                          open={this.state.isStateDropDownOpenNew}
                          onOpen={() => {
                            this.setState({ isStateDropDownOpenNew: true });
                          }}
                          onClose={() => {
                            this.setState({ isStateDropDownOpenNew: false });
                          }}
                          options={Object.keys(this.stateAndCities)}
                          value={this.state.newHouseholdData.state}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="State"
                              fullWidth
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                ),
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Autocomplete
                          id="addCity"
                          freeSolo
                          includeInputInList
                          autoSelect
                          onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                            let { newHouseholdData } = this.state;
                            newHouseholdData.city = e.target.value;
                            this.setState({ newHouseholdData });
                          }}
                          onChange={(event: any, value: any) => {
                            let { newHouseholdData } = this.state;
                            newHouseholdData.city = value;
                            this.setState({ newHouseholdData });
                          }}
                          open={this.state.isCityDropDownOpenNew}
                          onOpen={() => {
                            this.setState({ isCityDropDownOpenNew: true });
                          }}
                          onClose={() => {
                            this.setState({ isCityDropDownOpenNew: false });
                          }}
                          options={
                            this.stateAndCities[
                              this.state.newHouseholdData.state
                            ]
                          }
                          value={this.state.newHouseholdData.city}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="City"
                              fullWidth
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                ),
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="addPostal"
                          label="Postal"
                          fullWidth
                          value={this.state.newHouseholdData.postal}
                          onChange={(e) => {
                            let { newHouseholdData } = this.state;
                            newHouseholdData.postal = e.target.value;
                            this.setState({ newHouseholdData });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControlLabel
                          label="IsActive"
                          labelPlacement="start"
                          style={{ marginLeft: 0 }}
                          control={
                            <Switch
                              checked={this.state.newHouseholdData.isActive}
                              value={this.state.newHouseholdData.isActive}
                              onChange={(e) => {
                                let { newHouseholdData } = this.state;
                                newHouseholdData.isActive = e.target.checked;
                                this.setState({ newHouseholdData });
                              }}
                              color="primary"
                              inputProps={{ "aria-label": "primary checkbox" }}
                            />
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="addComment1"
                          label="Comment1"
                          fullWidth
                          multiline
                          rows="2"
                          value={this.state.newHouseholdData.comment1}
                          onChange={(e) => {
                            let { newHouseholdData } = this.state;
                            newHouseholdData.comment1 = e.target.value;
                            this.setState({ newHouseholdData });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="addComment2"
                          label="Comment2"
                          fullWidth
                          multiline
                          rows="2"
                          value={this.state.newHouseholdData.comment2}
                          onChange={(e) => {
                            let { newHouseholdData } = this.state;
                            newHouseholdData.comment2 = e.target.value;
                            this.setState({ newHouseholdData });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="addComment3"
                          label="Comment3"
                          fullWidth
                          multiline
                          rows="2"
                          value={this.state.newHouseholdData.comment3}
                          onChange={(e) => {
                            let { newHouseholdData } = this.state;
                            newHouseholdData.comment3 = e.target.value;
                            this.setState({ newHouseholdData });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="addComment4"
                          label="Comment4"
                          fullWidth
                          multiline
                          rows="2"
                          value={this.state.newHouseholdData.comment4}
                          onChange={(e) => {
                            let { newHouseholdData } = this.state;
                            newHouseholdData.comment4 = e.target.value;
                            this.setState({ newHouseholdData });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        {Helpers.handleError(
                          MessageForAction.Create,
                          this.state.householdMessages
                        )}
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      color="primary"
                      onClick={this.addNewHousehold.bind(this)}
                    >
                      Add
                    </Button>
                    <Button
                      color="secondary"
                      onClick={() => {
                        this.setState({ isNewHouseholdModalOpen: false });
                      }}
                    >
                      Cancel
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  open={this.state.isDeleteHouseholdConfirmModalOpen}
                  onClose={this.toggleDeleteHouseholdModal.bind(this)}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  fullWidth={true}
                  maxWidth={"sm"}
                >
                  <DialogTitle id="form-dialog-title">
                    Delete Household
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Delete {this.state.deleteHouseholdData.deleteMessage}{" "}
                      Household?
                    </DialogContentText>
                    {Helpers.handleError(
                      MessageForAction.Delete,
                      this.state.householdMessages
                    )}
                  </DialogContent>
                  <DialogActions>
                    <Button
                      color="primary"
                      onClick={this.deleteHousehold.bind(this)}
                    >
                      Yes, delete
                    </Button>
                    <Button
                      color="secondary"
                      onClick={this.toggleDeleteHouseholdModal.bind(this)}
                    >
                      Cancel
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  open={this.state.isEditHouseholdModalOpen}
                  onClose={this.toggleEditHouseholdModal.bind(this)}
                  aria-labelledby="form-dialog-title"
                  maxWidth={"md"}
                >
                  <DialogTitle id="form-dialog-title">
                    Edit Household
                  </DialogTitle>
                  <DialogContent>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={5}>
                        <ReferenceEntitySelector
                          componentId="editPrimaryPersonSelector"
                          selectedItem={
                            this.state.editHouseholdData.primaryPerson
                          }
                          label="PrimaryPerson"
                          url="Person/GetItemsForReferenceControl"
                          required={true}
                          onReferenceEntitySelectEvent={
                            this.handleEditPrimaryPersonSelect
                          }
                          handleException={(error) =>
                            this.handleHouseholdCatch(
                              error,
                              MessageForAction.Update
                            )
                          }
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={1}
                        style={{
                          paddingTop: 20,
                          paddingLeft: 0,
                          paddingRight: 0,
                        }}
                      >
                        <IconButton
                          aria-label="more"
                          aria-controls="long-menu"
                          aria-haspopup="true"
                          onClick={(e) =>
                            this.handleActionsClick(
                              e,
                              "isEditHouseholdPrimaryPersonActionsMenuOpen"
                            )
                          }
                        >
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          id="editHouseholdEditPrimaryPerson"
                          anchorEl={this.state.anchorElement}
                          keepMounted
                          open={
                            this.state
                              .isEditHouseholdPrimaryPersonActionsMenuOpen
                          }
                          onClose={() => {
                            this.setState({
                              isEditHouseholdPrimaryPersonActionsMenuOpen: false,
                            });
                          }}
                        >
                          <MenuItem
                            onClick={() =>
                              this.setState({
                                personData: this.resetPerson(),
                                isEditHouseholdModalOpen: false,
                                isEditHouseholdPrimaryPersonActionsMenuOpen: false,
                                isNewPersonModalOpen: true,
                                personCreatedFor:
                                  PersonCreatedFor.EditHouseholdNewPrimaryPerson,
                              })
                            }
                          >
                            <ListItemIcon>
                              <Add fontSize="small" />
                            </ListItemIcon>
                            Add PrimaryPerson
                          </MenuItem>
                          {this.state.editHouseholdData.primaryPerson ? (
                            <MenuItem
                              onClick={this.handleHouseholdEditPerson.bind(
                                this,
                                this.state.editHouseholdData.primaryPerson,
                                PersonEditedFor.EditHouseholdEditPrimaryPerson,
                                "isEditHouseholdPrimaryPersonActionsMenuOpen",
                                "isEditHouseholdModalOpen"
                              )}
                            >
                              <ListItemIcon>
                                <Edit fontSize="small" />
                              </ListItemIcon>
                              Edit PrimaryPerson
                            </MenuItem>
                          ) : null}
                        </Menu>
                      </Grid>
                      <Grid item xs={12} sm={5}>
                        <ReferenceEntitySelector
                          componentId="editSecondaryPersonSelector"
                          selectedItem={
                            this.state.editHouseholdData.secondaryPerson
                          }
                          label="SecondaryPerson"
                          url="Person/GetItemsForReferenceControl"
                          onReferenceEntitySelectEvent={
                            this.handleEditSecondaryPersonSelect
                          }
                          handleException={(error) =>
                            this.handleHouseholdCatch(
                              error,
                              MessageForAction.Update
                            )
                          }
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={1}
                        style={{
                          paddingTop: 20,
                          paddingLeft: 0,
                          paddingRight: 0,
                        }}
                      >
                        <IconButton
                          aria-label="more"
                          aria-controls="long-menu"
                          aria-haspopup="true"
                          onClick={(e) =>
                            this.handleActionsClick(
                              e,
                              "isEditHouseholdSecondaryPersonActionsMenuOpen"
                            )
                          }
                        >
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          id="editHouseholdEditSecondaryPerson"
                          anchorEl={this.state.anchorElement}
                          keepMounted
                          open={
                            this.state
                              .isEditHouseholdSecondaryPersonActionsMenuOpen
                          }
                          onClose={() => {
                            this.setState({
                              isEditHouseholdSecondaryPersonActionsMenuOpen: false,
                            });
                          }}
                        >
                          <MenuItem
                            onClick={() =>
                              this.setState({
                                personData: this.resetPerson(),
                                isEditHouseholdModalOpen: false,
                                isEditHouseholdSecondaryPersonActionsMenuOpen: false,
                                isNewPersonModalOpen: true,
                                personCreatedFor:
                                  PersonCreatedFor.EditHouseholdNewSecondaryPerson,
                              })
                            }
                          >
                            <ListItemIcon>
                              <Add fontSize="small" />
                            </ListItemIcon>
                            Add SecondaryPerson
                          </MenuItem>
                          {this.state.editHouseholdData.secondaryPerson ? (
                            <MenuItem
                              onClick={this.handleHouseholdEditPerson.bind(
                                this,
                                this.state.editHouseholdData.secondaryPerson,
                                PersonEditedFor.EditHouseholdEditSecondaryPerson,
                                "isEditHouseholdSecondaryPersonActionsMenuOpen",
                                "isEditHouseholdModalOpen"
                              )}
                            >
                              <ListItemIcon>
                                <Edit fontSize="small" />
                              </ListItemIcon>
                              Edit SecondaryPerson
                            </MenuItem>
                          ) : null}
                        </Menu>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="editNumber"
                          label="Number"
                          fullWidth
                          value={this.state.editHouseholdData.number}
                          onChange={(e) => {
                            let { editHouseholdData } = this.state;
                            editHouseholdData.number = e.target.value;
                            this.setState({ editHouseholdData });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="editPhone"
                          label="Phone"
                          fullWidth
                          value={this.state.editHouseholdData.phone}
                          onChange={(e) => {
                            let { editHouseholdData } = this.state;
                            editHouseholdData.phone = e.target.value;
                            this.setState({ editHouseholdData });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="editAddressLine1"
                          label="AddressLine1"
                          fullWidth
                          value={this.state.editHouseholdData.addressLine1}
                          onChange={(e) => {
                            let { editHouseholdData } = this.state;
                            editHouseholdData.addressLine1 = e.target.value;
                            this.setState({ editHouseholdData });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="editAddressLine2"
                          label="AddressLine2"
                          fullWidth
                          value={this.state.editHouseholdData.addressLine2}
                          onChange={(e) => {
                            let { editHouseholdData } = this.state;
                            editHouseholdData.addressLine2 = e.target.value;
                            this.setState({ editHouseholdData });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Autocomplete
                          id="editState"
                          freeSolo
                          includeInputInList
                          autoSelect
                          onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                            let { editHouseholdData } = this.state;
                            editHouseholdData.state = e.target.value;
                            editHouseholdData.city = "";
                            this.setState({ editHouseholdData });
                          }}
                          onChange={(event: any, value: any) => {
                            let { editHouseholdData } = this.state;
                            editHouseholdData.state = value;
                            editHouseholdData.city = "";
                            this.setState({ editHouseholdData });
                          }}
                          open={this.state.isStateDropDownOpenEdit}
                          onOpen={() => {
                            this.setState({ isStateDropDownOpenEdit: true });
                          }}
                          onClose={() => {
                            this.setState({ isStateDropDownOpenEdit: false });
                          }}
                          options={Object.keys(this.stateAndCities)}
                          value={this.state.editHouseholdData.state}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="State"
                              fullWidth
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                ),
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Autocomplete
                          id="editCity"
                          freeSolo
                          includeInputInList
                          autoSelect
                          onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                            let { editHouseholdData } = this.state;
                            editHouseholdData.city = e.target.value;
                            this.setState({ editHouseholdData });
                          }}
                          onChange={(event: any, value: any) => {
                            let { editHouseholdData } = this.state;
                            editHouseholdData.city = value;
                            this.setState({ editHouseholdData });
                          }}
                          open={this.state.isCityDropDownOpenEdit}
                          onOpen={() => {
                            this.setState({ isCityDropDownOpenEdit: true });
                          }}
                          onClose={() => {
                            this.setState({ isCityDropDownOpenEdit: false });
                          }}
                          options={
                            this.stateAndCities[
                              this.state.editHouseholdData.state
                            ]
                          }
                          value={this.state.editHouseholdData.city}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="City"
                              fullWidth
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                ),
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="editPostal"
                          label="Postal"
                          fullWidth
                          value={this.state.editHouseholdData.postal}
                          onChange={(e) => {
                            let { editHouseholdData } = this.state;
                            editHouseholdData.postal = e.target.value;
                            this.setState({ editHouseholdData });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControlLabel
                          label="IsActive"
                          labelPlacement="start"
                          style={{ marginLeft: 0 }}
                          control={
                            <Switch
                              checked={this.state.editHouseholdData.isActive}
                              value={this.state.editHouseholdData.isActive}
                              onChange={(e) => {
                                let { editHouseholdData } = this.state;
                                editHouseholdData.isActive = e.target.checked;
                                this.setState({ editHouseholdData });
                              }}
                              color="primary"
                              inputProps={{ "aria-label": "primary checkbox" }}
                            />
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="editComment1"
                          label="Comment1"
                          fullWidth
                          multiline
                          rows="2"
                          value={this.state.editHouseholdData.comment1}
                          onChange={(e) => {
                            let { editHouseholdData } = this.state;
                            editHouseholdData.comment1 = e.target.value;
                            this.setState({ editHouseholdData });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="editComment2"
                          label="Comment2"
                          fullWidth
                          multiline
                          rows="2"
                          value={this.state.editHouseholdData.comment2}
                          onChange={(e) => {
                            let { editHouseholdData } = this.state;
                            editHouseholdData.comment2 = e.target.value;
                            this.setState({ editHouseholdData });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="editComment3"
                          label="Comment3"
                          fullWidth
                          multiline
                          rows="2"
                          value={this.state.editHouseholdData.comment3}
                          onChange={(e) => {
                            let { editHouseholdData } = this.state;
                            editHouseholdData.comment3 = e.target.value;
                            this.setState({ editHouseholdData });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="editComment4"
                          label="Comment4"
                          fullWidth
                          multiline
                          rows="2"
                          value={this.state.editHouseholdData.comment4}
                          onChange={(e) => {
                            let { editHouseholdData } = this.state;
                            editHouseholdData.comment4 = e.target.value;
                            this.setState({ editHouseholdData });
                          }}
                        />
                      </Grid>
                      <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                      >
                        <Typography variant="h6" gutterBottom>
                          Household members
                        </Typography>
                        <Tooltip title="Add household members">
                          <IconButton
                            onClick={() => {
                              this.setState({
                                isEditHouseholdModalOpen: false,
                                isNewHouseholdMemberModalOpen: true,
                              });
                            }}
                          >
                            <Add />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                      <Grid item xs={12}>
                        <Table aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell>Action</TableCell>
                              <TableCell>Person</TableCell>
                              <TableCell>Birthday</TableCell>
                              <TableCell>Comment</TableCell>
                              <TableCell>IsActive</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {this.state.editHouseholdData.householdMembers.map(
                              (householdMember) => {
                                return (
                                  <TableRow key={householdMember.id}>
                                    <TableCell>
                                      <Tooltip title="Edit">
                                        <IconButton
                                          onClick={this.editHouseholdMemberSetState.bind(
                                            this,
                                            householdMember
                                          )}
                                        >
                                          <Edit />
                                        </IconButton>
                                      </Tooltip>
                                      <Tooltip title="Delete">
                                        <IconButton
                                          onClick={this.deleteHouseholdMemberSetState.bind(
                                            this,
                                            householdMember.id,
                                            `${householdMember.person?.fullName}`
                                          )}
                                        >
                                          <Delete />
                                        </IconButton>
                                      </Tooltip>
                                    </TableCell>
                                    <TableCell>
                                      {householdMember.person
                                        ?.memberOfChurchId === null
                                        ? ""
                                        : "* "}
                                      {householdMember.person?.fullName}
                                    </TableCell>
                                    <TableCell>
                                      {householdMember.person?.birthDate ===
                                      null
                                        ? null
                                        : new Date(
                                            householdMember.person.birthDate
                                          ).toDateString()}
                                    </TableCell>
                                    <TableCell>
                                      {householdMember.comment}
                                    </TableCell>
                                    <TableCell>
                                      <Switch
                                        checked={householdMember.isActive}
                                        color="primary"
                                        inputProps={{
                                          "aria-label": "primary checkbox",
                                        }}
                                      />
                                    </TableCell>
                                  </TableRow>
                                );
                              }
                            )}
                          </TableBody>
                        </Table>
                      </Grid>
                      {this.state.isHouseholdsMarriageInformationShow ? (
                        <Grid
                          container
                          direction="row"
                          justify="flex-start"
                          alignItems="center"
                        >
                          <Grid item xs={12}>
                            <Typography variant="h6" gutterBottom>
                              Marriage
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                autoOk
                                disableFuture
                                openTo="year"
                                views={["year", "month", "date"]}
                                variant="inline"
                                format="MM/dd/yyyy"
                                id="editStartDate"
                                label="StartDate"
                                value={this.state.editMarriageData.startDate}
                                onChange={(e) => {
                                  let { editMarriageData } = this.state;
                                  editMarriageData.startDate = Helpers.getDateWithUTCZero(
                                    e
                                  );
                                  this.setState({ editMarriageData });
                                }}
                              />
                            </MuiPickersUtilsProvider>
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                autoOk
                                disableFuture
                                openTo="year"
                                views={["year", "month", "date"]}
                                variant="inline"
                                format="MM/dd/yyyy"
                                id="editEndDate"
                                label="EndDate"
                                value={this.state.editMarriageData.endDate}
                                onChange={(e) => {
                                  let { editMarriageData } = this.state;
                                  editMarriageData.endDate = Helpers.getDateWithUTCZero(
                                    e
                                  );
                                  this.setState({ editMarriageData });
                                }}
                              />
                            </MuiPickersUtilsProvider>
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <FormControlLabel
                              label="IsActive"
                              labelPlacement="start"
                              style={{ marginLeft: 0 }}
                              control={
                                <Switch
                                  checked={this.state.editMarriageData.isActive}
                                  value={this.state.editMarriageData.isActive}
                                  onChange={(e) => {
                                    let { editMarriageData } = this.state;
                                    editMarriageData.isActive =
                                      e.target.checked;
                                    this.setState({ editMarriageData });
                                  }}
                                  color="primary"
                                  inputProps={{
                                    "aria-label": "primary checkbox",
                                  }}
                                />
                              }
                            />
                          </Grid>
                          <Grid item xs={12}>
                            {Helpers.handleError(
                              MessageForAction.Update,
                              this.state.marriageMessages
                            )}
                          </Grid>
                        </Grid>
                      ) : null}
                      <Grid item xs={12}>
                        {Helpers.handleError(
                          MessageForAction.Update,
                          this.state.householdMessages
                        )}
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      color="primary"
                      onClick={this.addChildButtonClickHandle.bind(this)}
                    >
                      Add child
                    </Button>
                    <Button
                      color="primary"
                      onClick={this.updateHousehold.bind(this)}
                    >
                      Update
                    </Button>
                    <Button
                      color="secondary"
                      onClick={this.toggleEditHouseholdModal.bind(this)}
                    >
                      Cancel
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  open={this.state.isNewMarriageQuestionModalOpen}
                  onClose={() =>
                    this.setState({
                      isNewMarriageQuestionModalOpen: false,
                    })
                  }
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  fullWidth={true}
                  maxWidth={"sm"}
                >
                  <DialogTitle id="form-dialog-title">
                    Household is new marriage?
                  </DialogTitle>
                  <DialogActions>
                    <Button
                      color="primary"
                      onClick={() =>
                        this.setState({
                          isNewMarriageQuestionModalOpen: false,
                          isNewMarriageModalOpen: true,
                        })
                      }
                    >
                      Yes, create new marriage
                    </Button>
                    <Button
                      color="secondary"
                      onClick={() =>
                        this.setState({
                          isNewMarriageQuestionModalOpen: false,
                          isNewHouseholdModalOpen: true,
                        })
                      }
                    >
                      No marriage
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  open={this.state.isNewMarriageModalOpen}
                  onClose={() =>
                    this.setState({
                      isNewMarriageModalOpen: false,
                    })
                  }
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  fullWidth={true}
                  maxWidth={"sm"}
                >
                  <DialogTitle id="form-dialog-title">New Marriage</DialogTitle>
                  <DialogContent>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={5}>
                        <ReferenceEntitySelector
                          componentId="addHusbandSelector"
                          selectedItem={this.state.marriageData?.husband}
                          label="Husband"
                          url="Person/GetItemsForReferenceControl"
                          required={true}
                          onReferenceEntitySelectEvent={(selectedHusband) => {
                            let { marriageData } = this.state;
                            marriageData.husband = selectedHusband;
                            this.setState({
                              marriageData,
                            });
                          }}
                          handleException={(error) =>
                            this.handleMarriageCatch(
                              error,
                              MessageForAction.Create
                            )
                          }
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={1}
                        style={{ paddingTop: 20, paddingLeft: 0 }}
                      >
                        <Tooltip title="Add husband">
                          <IconButton
                            onClick={() =>
                              this.setState({
                                personData: this.resetPerson(),
                                isNewMarriageModalOpen: false,
                                isNewPersonModalOpen: true,
                                personCreatedFor:
                                  PersonCreatedFor.NewMarriageNewHusband,
                              })
                            }
                          >
                            <Add />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                      <Grid item xs={12} sm={5}>
                        <ReferenceEntitySelector
                          componentId="addWifeSelector"
                          selectedItem={this.state.marriageData?.wife}
                          label="Wife"
                          url="Person/GetItemsForReferenceControl"
                          required={true}
                          onReferenceEntitySelectEvent={(selectedWife) => {
                            let { marriageData } = this.state;
                            marriageData.wife = selectedWife;
                            this.setState({ marriageData });
                          }}
                          handleException={(error) =>
                            this.handleMarriageCatch(
                              error,
                              MessageForAction.Create
                            )
                          }
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={1}
                        style={{ paddingTop: 20, paddingLeft: 0 }}
                      >
                        <Tooltip title="Add wife">
                          <IconButton
                            onClick={() =>
                              this.setState({
                                personData: this.resetPerson(),
                                isNewMarriageModalOpen: false,
                                isNewPersonModalOpen: true,
                                personCreatedFor:
                                  PersonCreatedFor.NewMarriageNewWife,
                              })
                            }
                          >
                            <Add />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            autoOk
                            disableFuture
                            openTo="year"
                            views={["year", "month", "date"]}
                            variant="inline"
                            format="MM/dd/yyyy"
                            id="addStartDate"
                            label="StartDate"
                            value={this.state.marriageData?.startDate}
                            onChange={(e) => {
                              let { marriageData } = this.state;
                              marriageData.startDate = Helpers.getDateWithUTCZero(
                                e
                              );
                              this.setState({
                                marriageData,
                              });
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            autoOk
                            disableFuture
                            openTo="year"
                            views={["year", "month", "date"]}
                            variant="inline"
                            format="MM/dd/yyyy"
                            id="addEndDate"
                            label="EndDate"
                            value={this.state.marriageData?.endDate}
                            onChange={(e) => {
                              let { marriageData } = this.state;
                              marriageData.endDate = Helpers.getDateWithUTCZero(
                                e
                              );
                              this.setState({
                                marriageData,
                              });
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControlLabel
                          label="IsActive"
                          labelPlacement="start"
                          style={{ marginLeft: 0 }}
                          control={
                            <Switch
                              checked={this.state.marriageData?.isActive}
                              value={this.state.marriageData?.isActive}
                              onChange={(e) => {
                                let { marriageData } = this.state;
                                marriageData.isActive = e.target.checked;
                                this.setState({
                                  marriageData,
                                });
                              }}
                              color="primary"
                              inputProps={{ "aria-label": "primary checkbox" }}
                            />
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        {Helpers.handleError(
                          MessageForAction.Create,
                          this.state.marriageMessages
                        )}
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      color="primary"
                      onClick={this.addNewMarriage.bind(this)}
                    >
                      Create
                    </Button>
                    <Button
                      color="secondary"
                      onClick={() =>
                        this.setState({
                          isNewMarriageModalOpen: false,
                        })
                      }
                    >
                      Cancel
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  open={this.state.isNewPersonModalOpen}
                  onClose={() =>
                    this.setState({
                      isNewPersonModalOpen: false,
                    })
                  }
                  aria-labelledby="form-dialog-title"
                >
                  <DialogTitle id="form-dialog-title">Add Person</DialogTitle>
                  <DialogContent>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          autoFocus
                          id="addFirstName"
                          label="FirstName"
                          required
                          fullWidth
                          value={this.state.personData.firstName}
                          onChange={(e) => {
                            let { personData } = this.state;
                            personData.firstName = e.target.value;
                            this.setState({ personData });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          autoFocus
                          id="addPreferredFirstName"
                          label="PreferredFirstName"
                          fullWidth
                          value={this.state.personData.preferredFirstName}
                          onChange={(e) => {
                            let { personData } = this.state;
                            personData.preferredFirstName = e.target.value;
                            this.setState({ personData });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="addLastName"
                          label="LastName"
                          required
                          fullWidth
                          value={this.state.personData.lastName}
                          onChange={(e) => {
                            let { personData } = this.state;
                            personData.lastName = e.target.value;
                            this.setState({ personData });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="addMiddleName"
                          label="MiddleName"
                          fullWidth
                          value={this.state.personData.middleName}
                          onChange={(e) => {
                            let { personData } = this.state;
                            personData.middleName = e.target.value;
                            this.setState({ personData });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControlLabel
                          label="IsMiddleNameShortened"
                          labelPlacement="start"
                          style={{ marginLeft: 0 }}
                          control={
                            <Switch
                              checked={
                                this.state.personData.isMiddleNameShortened
                              }
                              value={
                                this.state.personData.isMiddleNameShortened
                              }
                              onChange={(e) => {
                                let { personData } = this.state;
                                personData.isMiddleNameShortened =
                                  e.target.checked;
                                this.setState({ personData });
                              }}
                              color="primary"
                              inputProps={{ "aria-label": "primary checkbox" }}
                            />
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography component="div">
                          <Grid
                            component="label"
                            container
                            alignItems="center"
                            spacing={1}
                          >
                            <Grid item>Female</Grid>
                            <Grid item>
                              <Switch
                                checked={this.state.personData.gender}
                                value={this.state.personData.gender}
                                onChange={(e) => {
                                  let { personData } = this.state;
                                  personData.gender = e.target.checked;
                                  this.setState({ personData });
                                }}
                                color="primary"
                                inputProps={{
                                  "aria-label": "primary checkbox",
                                }}
                              />
                            </Grid>
                            <Grid item>Male</Grid>
                          </Grid>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            autoOk
                            disableFuture
                            openTo="year"
                            views={["year", "month", "date"]}
                            variant="inline"
                            format="MM/dd/yyyy"
                            id="addBirthDate"
                            label="BirthDate"
                            value={this.state.personData.birthDate}
                            onChange={(e) => {
                              let { personData } = this.state;
                              personData.birthDate = Helpers.getDateWithUTCZero(
                                e
                              );
                              this.setState({ personData });
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            autoOk
                            disableFuture
                            openTo="year"
                            views={["year", "month", "date"]}
                            variant="inline"
                            format="MM/dd/yyyy"
                            id="addDeathDate"
                            label="DeathDate"
                            value={this.state.personData.deathDate}
                            onChange={(e) => {
                              let { personData } = this.state;
                              personData.deathDate = Helpers.getDateWithUTCZero(
                                e
                              );
                              this.setState({ personData });
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <TextField
                          id="addDeathComment"
                          label="DeathComment"
                          fullWidth
                          value={this.state.personData.deathComment}
                          onChange={(e) => {
                            let { personData } = this.state;
                            personData.deathComment = e.target.value;
                            this.setState({ personData });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="addComment"
                          label="Comment"
                          fullWidth
                          value={this.state.personData.comment}
                          onChange={(e) => {
                            let { personData } = this.state;
                            personData.comment = e.target.value;
                            this.setState({ personData });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="addPhone1"
                          label="Phone1"
                          fullWidth
                          value={this.state.personData.phone1}
                          onChange={(e) => {
                            let { personData } = this.state;
                            personData.phone1 = e.target.value;
                            this.setState({ personData });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="addPhone2"
                          label="Phone2"
                          fullWidth
                          value={this.state.personData.phone2}
                          onChange={(e) => {
                            let { personData } = this.state;
                            personData.phone2 = e.target.value;
                            this.setState({ personData });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="addEmail"
                          label="Email"
                          fullWidth
                          value={this.state.personData.email}
                          onChange={(e) => {
                            let { personData } = this.state;
                            personData.email = e.target.value;
                            this.setState({ personData });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <ReferenceEntitySelector
                          componentId="addMemberOfChurchSelector"
                          selectedItem={this.state.personData.memberOfChurch}
                          label="MemberOfChurch"
                          url="Building/GetAllActiveChurchesForReferenceControl"
                          onReferenceEntitySelectEvent={
                            this.handleNewMemberOfChurchSelect
                          }
                          handleException={(error) =>
                            this.handlePersonCatch(
                              error,
                              MessageForAction.Create
                            )
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <ReferenceEntitySelector
                          componentId="addFatherSelector"
                          selectedItem={this.state.personData.father}
                          label="Father"
                          url="Person/GetItemsForReferenceControl"
                          onReferenceEntitySelectEvent={
                            this.handleNewFatherSelect
                          }
                          handleException={(error) =>
                            this.handlePersonCatch(
                              error,
                              MessageForAction.Create
                            )
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <ReferenceEntitySelector
                          componentId="addMotherSelector"
                          selectedItem={this.state.personData.mother}
                          label="Mother"
                          url="Person/GetItemsForReferenceControl"
                          onReferenceEntitySelectEvent={
                            this.handleNewMotherSelect
                          }
                          handleException={(error) =>
                            this.handlePersonCatch(
                              error,
                              MessageForAction.Create
                            )
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControlLabel
                          label="IsActive"
                          labelPlacement="start"
                          style={{ marginLeft: 0 }}
                          control={
                            <Switch
                              checked={this.state.personData.isActive}
                              value={this.state.personData.isActive}
                              onChange={(e) => {
                                let { personData } = this.state;
                                personData.isActive = e.target.checked;
                                this.setState({ personData });
                              }}
                              color="primary"
                              inputProps={{ "aria-label": "primary checkbox" }}
                            />
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        {Helpers.handleError(
                          MessageForAction.Create,
                          this.state.personMessages
                        )}
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      color="primary"
                      onClick={this.addNewPerson.bind(this)}
                    >
                      Add
                    </Button>
                    <Button
                      color="secondary"
                      onClick={() => {
                        this.setState({
                          isNewPersonModalOpen: false,
                        });
                        switch (this.state.personCreatedFor) {
                          case PersonCreatedFor.NewHouseholdNewPrimaryPerson:
                          case PersonCreatedFor.NewHouseholdNewSecondaryPerson:
                          case PersonCreatedFor.EditHouseholdAddChild:
                            this.setState({
                              isEditHouseholdModalOpen: true,
                            });
                            break;
                          case PersonCreatedFor.EditHouseholdNewHouseholdMemberNewPerson:
                          case PersonCreatedFor.EditHouseholdNewPrimaryPerson:
                          case PersonCreatedFor.EditHouseholdNewSecondaryPerson:
                            this.setState({
                              isEditHouseholdModalOpen: true,
                            });
                            break;
                          case PersonCreatedFor.NewMarriageNewHusband:
                          case PersonCreatedFor.NewMarriageNewWife:
                            this.setState({
                              isNewMarriageModalOpen: true,
                            });
                            break;
                          default:
                            break;
                        }
                        this.removePersonMessages(MessageForAction.Create);
                      }}
                    >
                      Cancel
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  open={this.state.isNewHouseholdMemberModalOpen}
                  onClose={() => {
                    this.setState({ isNewHouseholdMemberModalOpen: false });
                  }}
                  aria-labelledby="form-dialog-title"
                >
                  <DialogTitle id="form-dialog-title">
                    Add HouseholdMember
                  </DialogTitle>
                  <DialogContent>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={8}>
                        <ReferenceEntitySelector
                          componentId="addPersonSelector"
                          selectedItem={
                            this.state.newHouseholdMemberData.person
                          }
                          label="Person"
                          url="Person/GetItemsForReferenceControl"
                          required={true}
                          onReferenceEntitySelectEvent={(selectedPerson) => {
                            let {
                              newHouseholdMemberData: householdMemberData,
                            } = this.state;
                            householdMemberData.person = selectedPerson;
                            this.setState({
                              newHouseholdMemberData: householdMemberData,
                            });
                          }}
                          handleException={(error) =>
                            this.handleHouseholdMemberCatch(
                              error,
                              MessageForAction.Create
                            )
                          }
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={1}
                        style={{ paddingTop: 20, paddingLeft: 0 }}
                      >
                        <Tooltip title="Add Person">
                          <IconButton
                            onClick={this.handleAddPersonToHouseholdMember.bind(
                              this
                            )}
                          >
                            <Add />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={3}
                        container
                        direction="row"
                        justify="center"
                        alignItems="flex-end"
                      >
                        <FormControlLabel
                          label="IsActive"
                          labelPlacement="start"
                          style={{ marginLeft: 0 }}
                          control={
                            <Switch
                              checked={
                                this.state.newHouseholdMemberData.isActive
                              }
                              value={this.state.newHouseholdMemberData.isActive}
                              onChange={(e) => {
                                let {
                                  newHouseholdMemberData: householdMemberData,
                                } = this.state;
                                householdMemberData.isActive = e.target.checked;
                                this.setState({
                                  newHouseholdMemberData: householdMemberData,
                                });
                              }}
                              color="primary"
                              inputProps={{ "aria-label": "primary checkbox" }}
                            />
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="newComment"
                          label="Comment"
                          multiline
                          rows="2"
                          fullWidth
                          value={this.state.newHouseholdMemberData.comment}
                          onChange={(e) => {
                            let {
                              newHouseholdMemberData: householdMemberData,
                            } = this.state;
                            householdMemberData.comment = e.target.value;
                            this.setState({
                              newHouseholdMemberData: householdMemberData,
                            });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        {Helpers.handleError(
                          MessageForAction.Create,
                          this.state.householdMemberMessages
                        )}
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      color="primary"
                      onClick={this.addNewHouseholdMemberToEditingHousehold.bind(
                        this
                      )}
                    >
                      Add
                    </Button>
                    <Button
                      color="secondary"
                      onClick={() =>
                        this.setState({
                          isNewHouseholdMemberModalOpen: false,
                          isEditHouseholdModalOpen: true,
                        })
                      }
                    >
                      Cancel
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  open={this.state.isDeleteHouseholdMemberConfirmModalOpen}
                  onClose={() =>
                    this.setState({
                      isDeleteHouseholdMemberConfirmModalOpen: false,
                      isEditHouseholdModalOpen: true,
                    })
                  }
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  fullWidth={true}
                  maxWidth={"sm"}
                >
                  <DialogTitle id="form-dialog-title">
                    Delete HouseholdMember
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Delete{" "}
                      {this.state.deleteHouseholdMemberData.deleteMessage}{" "}
                      HouseholdMember?
                    </DialogContentText>
                    {Helpers.handleError(
                      MessageForAction.Delete,
                      this.state.householdMemberMessages
                    )}
                  </DialogContent>
                  <DialogActions>
                    <Button
                      color="primary"
                      onClick={this.deleteHouseholdMemberFromEditingHousehold.bind(
                        this
                      )}
                    >
                      Yes, delete
                    </Button>
                    <Button
                      color="secondary"
                      onClick={() =>
                        this.setState({
                          isDeleteHouseholdMemberConfirmModalOpen: false,
                          isEditHouseholdModalOpen: true,
                        })
                      }
                    >
                      Cancel
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  open={this.state.isEditPersonModalOpen}
                  onClose={() => {
                    this.setState({
                      isEditPersonModalOpen: false,
                    });
                    this.removePersonMessages(MessageForAction.Update);
                  }}
                  aria-labelledby="form-dialog-title"
                >
                  <DialogTitle id="form-dialog-title">Edit Person</DialogTitle>
                  <DialogContent>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          autoFocus
                          id="editFirstName"
                          label="FirstName"
                          required
                          fullWidth
                          value={this.state.personData.firstName}
                          onChange={(e) => {
                            let { personData } = this.state;
                            personData.firstName = e.target.value;
                            this.setState({ personData });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          autoFocus
                          id="editPreferredFirstName"
                          label="PreferredFirstName"
                          fullWidth
                          value={this.state.personData.preferredFirstName}
                          onChange={(e) => {
                            let { personData } = this.state;
                            personData.preferredFirstName = e.target.value;
                            this.setState({ personData });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="editLastName"
                          label="LastName"
                          required
                          fullWidth
                          value={this.state.personData.lastName}
                          onChange={(e) => {
                            let { personData } = this.state;
                            personData.lastName = e.target.value;
                            this.setState({ personData });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="editMiddleName"
                          label="MiddleName"
                          fullWidth
                          value={this.state.personData.middleName}
                          onChange={(e) => {
                            let { personData } = this.state;
                            personData.middleName = e.target.value;
                            this.setState({ personData });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControlLabel
                          label="IsMiddleNameShortened"
                          labelPlacement="start"
                          style={{ marginLeft: 0 }}
                          control={
                            <Switch
                              checked={
                                this.state.personData.isMiddleNameShortened
                              }
                              value={
                                this.state.personData.isMiddleNameShortened
                              }
                              onChange={(e) => {
                                let { personData } = this.state;
                                personData.isMiddleNameShortened =
                                  e.target.checked;
                                this.setState({ personData });
                              }}
                              color="primary"
                              inputProps={{ "aria-label": "primary checkbox" }}
                            />
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography component="div">
                          <Grid
                            component="label"
                            container
                            alignItems="center"
                            spacing={1}
                          >
                            <Grid item>Female</Grid>
                            <Grid item>
                              <Switch
                                checked={this.state.personData.gender}
                                value={this.state.personData.gender}
                                onChange={(e) => {
                                  let { personData } = this.state;
                                  personData.gender = e.target.checked;
                                  this.setState({ personData });
                                }}
                                color="primary"
                                inputProps={{
                                  "aria-label": "primary checkbox",
                                }}
                              />
                            </Grid>
                            <Grid item>Male</Grid>
                          </Grid>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            autoOk
                            disableFuture
                            openTo="year"
                            views={["year", "month", "date"]}
                            variant="inline"
                            format="MM/dd/yyyy"
                            id="editBirthDate"
                            label="BirthDate"
                            value={this.state.personData.birthDate}
                            onChange={(e) => {
                              let { personData } = this.state;
                              personData.birthDate = Helpers.getDateWithUTCZero(
                                e
                              );
                              this.setState({ personData });
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            autoOk
                            disableFuture
                            openTo="year"
                            views={["year", "month", "date"]}
                            variant="inline"
                            format="MM/dd/yyyy"
                            id="editDeathDate"
                            label="DeathDate"
                            value={this.state.personData.deathDate}
                            onChange={(e) => {
                              let { personData } = this.state;
                              personData.deathDate = Helpers.getDateWithUTCZero(
                                e
                              );
                              this.setState({ personData });
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <TextField
                          id="editDeathComment"
                          label="DeathComment"
                          fullWidth
                          value={this.state.personData.deathComment}
                          onChange={(e) => {
                            let { personData } = this.state;
                            personData.deathComment = e.target.value;
                            this.setState({ personData });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="editComment"
                          label="Comment"
                          fullWidth
                          value={this.state.personData.comment}
                          onChange={(e) => {
                            let { personData } = this.state;
                            personData.comment = e.target.value;
                            this.setState({ personData });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="editPhone1"
                          label="Phone1"
                          fullWidth
                          value={this.state.personData.phone1}
                          onChange={(e) => {
                            let { personData } = this.state;
                            personData.phone1 = e.target.value;
                            this.setState({ personData });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="editPhone2"
                          label="Phone2"
                          fullWidth
                          value={this.state.personData.phone2}
                          onChange={(e) => {
                            let { personData } = this.state;
                            personData.phone2 = e.target.value;
                            this.setState({ personData });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="editEmail"
                          label="Email"
                          fullWidth
                          value={this.state.personData.email}
                          onChange={(e) => {
                            let { personData } = this.state;
                            personData.email = e.target.value;
                            this.setState({ personData });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <ReferenceEntitySelector
                          componentId="editMemberOfChurchSelector"
                          selectedItem={this.state.personData.memberOfChurch}
                          label="MemberOfChurch"
                          url="Building/GetAllActiveChurchesForReferenceControl"
                          onReferenceEntitySelectEvent={
                            this.handleEditMemberOfChurchSelect
                          }
                          handleException={(error) =>
                            this.handlePersonCatch(
                              error,
                              MessageForAction.Update
                            )
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <ReferenceEntitySelector
                          componentId="editFatherSelector"
                          selectedItem={this.state.personData.father}
                          label="Father"
                          url="Person/GetItemsForReferenceControl"
                          onReferenceEntitySelectEvent={
                            this.handleEditFatherSelect
                          }
                          handleException={(error) =>
                            this.handlePersonCatch(
                              error,
                              MessageForAction.Update
                            )
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <ReferenceEntitySelector
                          componentId="editMotherSelector"
                          selectedItem={this.state.personData.mother}
                          label="Mother"
                          url="Person/GetItemsForReferenceControl"
                          onReferenceEntitySelectEvent={
                            this.handleEditMotherSelect
                          }
                          handleException={(error) =>
                            this.handlePersonCatch(
                              error,
                              MessageForAction.Update
                            )
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControlLabel
                          label="IsActive"
                          labelPlacement="start"
                          style={{ marginLeft: 0 }}
                          control={
                            <Switch
                              checked={this.state.personData.isActive}
                              value={this.state.personData.isActive}
                              onChange={(e) => {
                                let { personData } = this.state;
                                personData.isActive = e.target.checked;
                                this.setState({ personData });
                              }}
                              color="primary"
                              inputProps={{ "aria-label": "primary checkbox" }}
                            />
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        {Helpers.handleError(
                          MessageForAction.Update,
                          this.state.personMessages
                        )}
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      color="primary"
                      onClick={this.updatePerson.bind(this)}
                    >
                      Update
                    </Button>
                    <Button
                      color="secondary"
                      onClick={() => {
                        this.setState({
                          isEditPersonModalOpen: false,
                        });
                        switch (this.state.personEditedFor) {
                          case PersonEditedFor.NewHouseholdEditPrimaryPerson:
                          case PersonEditedFor.NewHouseholdEditSecondaryPerson:
                            this.setState({
                              isNewHouseholdModalOpen: true,
                            });
                            break;
                          case PersonEditedFor.EditHouseholdEditHouseholdMemberEditPerson:
                          case PersonEditedFor.EditHouseholdEditPrimaryPerson:
                          case PersonEditedFor.EditHouseholdEditSecondaryPerson:
                            this.setState({
                              isEditHouseholdModalOpen: true,
                            });
                            break;
                          default:
                            break;
                        }
                        this.removePersonMessages(MessageForAction.Update);
                      }}
                    >
                      Cancel
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  open={this.state.isErrorDialogOpen}
                  onClose={() => {
                    this.setState({
                      isErrorDialogOpen: false,
                      householdMessages: [],
                    });
                  }}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  fullWidth={true}
                  maxWidth={"sm"}
                >
                  <DialogTitle id="form-dialog-title">
                    Error occurred
                  </DialogTitle>
                  <DialogContent>
                    {Helpers.handleError(
                      MessageForAction.Read,
                      this.state.householdMessages
                    )}
                  </DialogContent>
                  <DialogActions>
                    <Button
                      color="secondary"
                      onClick={() => {
                        this.setState({
                          isErrorDialogOpen: false,
                          householdMessages: [],
                        });
                      }}
                    >
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>
                <Tooltip title="Reload">
                  <IconButton onClick={this.refreshTableData.bind(this)}>
                    <Refresh className={classes.block} color="inherit" />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Action</TableCell>
                <TableCell>Number</TableCell>
                <TableCell>PrimaryPerson</TableCell>
                <TableCell>SecondaryPerson</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>AddressLine1</TableCell>
                <TableCell>AddressLine2</TableCell>
                <TableCell>State</TableCell>
                <TableCell>City</TableCell>
                <TableCell>Postal</TableCell>
                <TableCell>Comment1</TableCell>
                <TableCell>IsActive</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{households}</TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10]}
                  count={this.state.householdsCount}
                  rowsPerPage={10}
                  page={this.state.pageNumber}
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    native: true,
                  }}
                  onChangePage={this.handleChangePage.bind(this)}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Paper>
    );
  }

  onRowClick(household: IHousehold) {
    this.setState({ householdDataForActionsMenu: household });
    this.viewHouseholdSetState(household.id);
  }

  handleHouseholdEditPerson(
    person: any,
    personEditedFor: PersonEditedFor,
    isMenuOpenProperty: string,
    isHouseholdModalOpenProperty: string
  ) {
    this.authService.getUser().then((user) => {
      if (user && user.access_token) {
        const headers = Helpers.getHeaders(user.access_token);
        Axios.get(`${IdentityConstants.apiRoot}Person/${person.id}`, {
          headers,
        })
          .then((response) => {
            let person = response.data;
            person.deathComment = response.data.deathComment ?? "";
            person.comment = response.data.comment ?? "";
            person.phone1 = response.data.phone1 ?? "";
            person.phone2 = response.data.phone2 ?? "";
            person.email = response.data.email ?? "";
            person.middleName = response.data.middleName ?? "";
            this.setState((prevState) => ({
              ...prevState,
              personData: person,
              [isHouseholdModalOpenProperty]: false,
              personEditedFor: personEditedFor,
              isEditPersonModalOpen: true,
              [isMenuOpenProperty]: false,
            }));
          })
          .catch((error) => {
            this.handleHouseholdCatch(error, MessageForAction.Read);
          });
      }
    });
  }

  handleHouseholdCatch(error: any, messageForAction: MessageForAction) {
    let { householdMessages } = this.state;
    if (error.toString().includes("Network Error")) {
      householdMessages.push(
        new MessageInfo(
          messageForAction,
          "Server unavailable, try again later",
          503
        )
      );
    } else if (
      error.toString().includes("Request failed with status code 401")
    ) {
      this.authService.login();
    } else if (
      error.toString().includes("Request failed with status code 500")
    ) {
      householdMessages.push(
        new MessageInfo(messageForAction, error.toString(), 500)
      );
    } else if (error.response.data.hasOwnProperty("errors")) {
      error.response.data.errors.forEach((errorItem: any) => {
        householdMessages.push(
          new MessageInfo(
            messageForAction,
            errorItem.message,
            error.response.status
          )
        );
      });
    } else if (error.response.data.hasOwnProperty("Message")) {
      householdMessages.push(
        new MessageInfo(
          messageForAction,
          error.response.data.Message,
          error.response.status
        )
      );
    } else {
      householdMessages.push(
        new MessageInfo(messageForAction, error, error.response.status)
      );
    }
    this.setState({ householdMessages });
  }

  handleMarriageCatch(error: any, messageForAction: MessageForAction) {
    let { marriageMessages } = this.state;
    if (error.toString().includes("Network Error")) {
      marriageMessages.push(
        new MessageInfo(
          messageForAction,
          "Server unavailable, try again later",
          503
        )
      );
    } else if (
      error.toString().includes("Request failed with status code 401")
    ) {
      this.authService.login();
    } else if (
      error.toString().includes("Request failed with status code 500")
    ) {
      marriageMessages.push(
        new MessageInfo(messageForAction, error.toString(), 500)
      );
    } else if (error.response.data.hasOwnProperty("errors")) {
      error.response.data.errors.forEach((errorItem: any) => {
        marriageMessages.push(
          new MessageInfo(
            messageForAction,
            errorItem.message,
            error.response.status
          )
        );
      });
    } else if (error.response.data.hasOwnProperty("Message")) {
      marriageMessages.push(
        new MessageInfo(
          messageForAction,
          error.response.data.Message,
          error.response.status
        )
      );
    } else {
      marriageMessages.push(
        new MessageInfo(messageForAction, error, error.response.status)
      );
    }
    this.setState({ marriageMessages });
  }

  handlePersonCatch(error: any, messageForAction: MessageForAction) {
    let { personMessages } = this.state;
    if (error.toString().includes("Network Error")) {
      personMessages.push(
        new MessageInfo(
          messageForAction,
          "Server unavailable, try again later",
          503
        )
      );
    } else if (
      error.toString().includes("Request failed with status code 401")
    ) {
      this.authService.login();
    } else if (
      error.toString().includes("Request failed with status code 500")
    ) {
      personMessages.push(
        new MessageInfo(messageForAction, error.toString(), 500)
      );
    } else if (error.response.data.hasOwnProperty("errors")) {
      error.response.data.errors.forEach((errorItem: any) => {
        personMessages.push(
          new MessageInfo(
            messageForAction,
            errorItem.message,
            error.response.status
          )
        );
      });
    } else if (error.response.data.hasOwnProperty("Message")) {
      personMessages.push(
        new MessageInfo(
          messageForAction,
          error.response.data.Message,
          error.response.status
        )
      );
    } else {
      personMessages.push(
        new MessageInfo(messageForAction, error, error.response.status)
      );
    }
    this.setState({ personMessages });
  }

  handleHouseholdMemberCatch(error: any, messageForAction: MessageForAction) {
    let { householdMemberMessages } = this.state;
    if (error.toString().includes("Network Error")) {
      householdMemberMessages.push(
        new MessageInfo(
          messageForAction,
          "Server unavailable, try again later",
          503
        )
      );
    } else if (
      error.toString().includes("Request failed with status code 401")
    ) {
      this.authService.login();
    } else if (
      error.toString().includes("Request failed with status code 500")
    ) {
      householdMemberMessages.push(
        new MessageInfo(messageForAction, error.toString(), 500)
      );
    } else if (error.response.data.hasOwnProperty("errors")) {
      error.response.data.errors.forEach((errorItem: any) => {
        householdMemberMessages.push(
          new MessageInfo(
            messageForAction,
            errorItem.message,
            error.response.status
          )
        );
      });
    } else if (error.response.data.hasOwnProperty("Message")) {
      householdMemberMessages.push(
        new MessageInfo(
          messageForAction,
          error.response.data.Message,
          error.response.status
        )
      );
    } else {
      householdMemberMessages.push(
        new MessageInfo(messageForAction, error, error.response.status)
      );
    }
    this.setState({ householdMemberMessages });
  }
}
export default withStyles(styles)(Household);
