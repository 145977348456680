import * as React from "react";
import Loader from "react-loader-spinner";

import { Button, Typography } from "@material-ui/core";

interface IServerNotAvailableProps {
  login: () => void;
}

interface IServerNotAvailableState {
  timePassed: boolean;
}

export default class ServerNotAvailable extends React.Component<
  IServerNotAvailableProps,
  IServerNotAvailableState
> {
  private componentUnmount: boolean;
  constructor(props: any) {
    super(props);
    this.state = {
      timePassed: false,
    };
    this.componentUnmount = false;
  }
  componentWillUnmount() {
    this.componentUnmount = true;
  }

  public render() {
    setTimeout(() => {
      if (!this.componentUnmount) {
        this.setState({ timePassed: true });
      }
    }, 15000);
    return (
      <div
        style={{
          textAlign: "center",
          height: "100vh",
          backgroundColor: "#3F51B5",
        }}
      >
        <Loader
          type="ThreeDots"
          color="#2BAD60"
          height={100}
          width={100}
          timeout={14000}
        />
        {this.state.timePassed && (
          <div>
            <Typography
              variant="h2"
              gutterBottom
              style={{ color: "#fff", fontWeight: "lighter" }}
            >
              Server is not available.
            </Typography>
            <Button variant="contained" onClick={this.props.login}>
              Try again
            </Button>
          </div>
        )}
      </div>
    );
  }
}
