import React from "react";
import {
  createMuiTheme,
  createStyles,
  ThemeProvider,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Hidden from "@material-ui/core/Hidden";
import Typography from "@material-ui/core/Typography";
import Navigator from "./Navigator";
import EventType from "./entityComponents/EventType";
import District from "./entityComponents/District";
import BuildingType from "./entityComponents/BuildingType";
import MinistryType from "./entityComponents/MinistryType";
import AdministrativeUser from "./entityComponents/AdministrativeUser";
import Building from "./entityComponents/Building";
import Event from "./entityComponents/Event";
import Person from "./entityComponents/Person";
import Ministry from "./entityComponents/Ministry";
import BuildingMinistry from "./entityComponents/BuildingMinistry";
import Household from "./entityComponents/Household/Household";
import HouseholdEditSuggestion from "./entityComponents/HouseholdEditSuggestion";
import HouseholdMember from "./entityComponents/HouseholdMember";
import Marriage from "./entityComponents/Marriage";
import Header from "./Header";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      {"DirectoryApp "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

let theme = createMuiTheme({
  palette: {
    primary: {
      light: "#63ccff",
      main: "#009be5",
      dark: "#006db3",
    },
  },
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  shape: {
    borderRadius: 8,
  },
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

theme = {
  ...theme,
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: "#18202c",
      },
    },
    MuiButton: {
      label: {
        textTransform: "none",
      },
      contained: {
        boxShadow: "none",
        "&:active": {
          boxShadow: "none",
        },
      },
    },
    MuiTabs: {
      root: {
        marginLeft: theme.spacing(1),
      },
      indicator: {
        height: 3,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        backgroundColor: theme.palette.common.white,
      },
    },
    MuiTab: {
      root: {
        textTransform: "none",
        margin: "0 16px",
        minWidth: 0,
        padding: 0,
        [theme.breakpoints.up("md")]: {
          padding: 0,
          minWidth: 0,
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: theme.spacing(1),
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: "#404854",
      },
    },
    MuiListItemText: {
      primary: {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    MuiListItemIcon: {
      root: {
        color: "inherit",
        marginRight: 0,
        "& svg": {
          fontSize: 20,
        },
      },
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32,
      },
    },
  },
};

const drawerWidth = 256;

const styles = createStyles({
  root: {
    display: "flex",
    minHeight: "100vh",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  app: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  main: {
    flex: 1,
    padding: theme.spacing(6, 4),
    background: "#eaeff1",
  },
  footer: {
    padding: theme.spacing(2),
    background: "#eaeff1",
  },
});

export interface HomePageProps extends WithStyles<typeof styles> {
  logout: () => void;
}

function HomePage(props: HomePageProps) {
  const { classes, logout } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <div className={classes.root}>
          <CssBaseline />
          <nav className={classes.drawer}>
            <Hidden smUp implementation="js">
              <Navigator
                PaperProps={{ style: { width: drawerWidth } }}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
              />
            </Hidden>
            <Hidden xsDown implementation="css">
              <Navigator PaperProps={{ style: { width: drawerWidth } }} />
            </Hidden>
          </nav>
          <div className={classes.app}>
            <Header onDrawerToggle={handleDrawerToggle} logout={logout} />
            <main className={classes.main}>
              <Switch>
                <Route path="/eventtype" exact component={EventType} />
                <Route path="/district" exact component={District} />
                <Route path="/buildingtype" exact component={BuildingType} />
                <Route path="/ministrytype" exact component={MinistryType} />
                <Route
                  path="/administrativeuser"
                  exact
                  component={AdministrativeUser}
                />
                <Route path="/building" exact component={Building} />
                <Route path="/event" exact component={Event} />
                <Route path="/person" exact component={Person} />
                <Route path="/ministry" exact component={Ministry} />
                <Route
                  path="/buildingMinistry"
                  exact
                  component={BuildingMinistry}
                />
                <Route path="/household/:id" component={Household} />
                <Route path="/household" component={Household} />
                <Route
                  path="/householdEditSuggestion"
                  exact
                  component={HouseholdEditSuggestion}
                />
                <Route
                  path="/householdMember"
                  exact
                  component={HouseholdMember}
                />
                <Route path="/marriage" exact component={Marriage} />
              </Switch>
            </main>
            <footer className={classes.footer}>
              <Copyright />
            </footer>
          </div>
        </div>
      </ThemeProvider>
    </Router>
  );
}

export default withStyles(styles)(HomePage);
