import React from "react";
import clsx from "clsx";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import Drawer, { DrawerProps } from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import {
  Home,
  People,
  Event,
  Spellcheck,
  Person,
  LocationCity,
  Wc,
  SupervisorAccount,
  Apartment,
  AccountBox,
  EventNote,
  HomeWork,
} from "@material-ui/icons";
import { Omit } from "@material-ui/types";
import { Link } from "react-router-dom";
import { createBrowserHistory } from "history";

export interface INavigatorState {
  categories: any[];
}

const styles = (theme: Theme) =>
  createStyles({
    categoryHeader: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    categoryHeaderPrimary: {
      color: theme.palette.common.white,
    },
    item: {
      paddingTop: 1,
      paddingBottom: 1,
      color: "rgba(255, 255, 255, 0.7)",
      "&:hover,&:focus": {
        backgroundColor: "rgba(255, 255, 255, 0.08)",
      },
    },
    itemCategory: {
      backgroundColor: "#232f3e",
      boxShadow: "0 -1px 0 #404854 inset",
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    firebase: {
      fontSize: 24,
      color: theme.palette.common.white,
    },
    itemActiveItem: {
      color: "#4fc3f7",
    },
    itemPrimary: {
      fontSize: "inherit",
    },
    itemIcon: {
      minWidth: "auto",
      marginRight: theme.spacing(2),
    },
    divider: {
      marginTop: theme.spacing(2),
    },
  });

export interface NavigatorProps
  extends Omit<DrawerProps, "classes">,
    WithStyles<typeof styles> {}

class Navigator extends React.Component<NavigatorProps, INavigatorState> {
  constructor(props: NavigatorProps) {
    super(props);
    this.state = {
      categories: [
        {
          id: "Households",
          icon: <Home />,
          linkto: "/household",
          active: false,
        },
        {
          id: "People",
          icon: <People />,
          linkto: "/person",
          active: false,
        },
        {
          id: "Events",
          icon: <Event />,
          linkto: "/event",
          active: false,
        },
        {
          id: "Change Requests",
          icon: <Spellcheck />,
          linkto: "/householdEditSuggestion",
          active: false,
        },
        {
          id: "Ministry",
          icon: <Person />,
          linkto: "/ministry",
          active: false,
        },
        {
          id: "Ministry / Church",
          icon: <Person />,
          linkto: "/buildingMinistry",
          active: false,
        },
        {
          id: "Marriages",
          icon: <Wc />,
          linkto: "/marriage",
          active: false,
        },
        {
          id: "Districts",
          icon: <LocationCity />,
          linkto: "/district",
          active: false,
        },
        {
          id: "Buildings",
          icon: <Apartment />,
          linkto: "/building",
          active: false,
        },
        {
          id: "Household Members",
          icon: <SupervisorAccount />,
          linkto: "/householdMember",
          active: false,
        },
        {
          id: "Building Types",
          icon: <HomeWork />,
          linkto: "/buildingtype",
          active: false,
        },
        {
          id: "Event Types",
          icon: <EventNote />,
          linkto: "/eventtype",
          active: false,
        },
        {
          id: "Ministry Types",
          icon: <Person />,
          linkto: "/ministrytype",
          active: false,
        },
        {
          id: "Administrative Users",
          icon: <AccountBox />,
          linkto: "/administrativeuser",
          active: false,
        },
      ],
    };
  }

  componentDidMount() {
    const history = createBrowserHistory();
    let { categories } = this.state;
    categories.forEach((categoryItem) => {
      categoryItem.active = history.location.pathname === categoryItem.linkto;
    });
    this.setState({ categories });
  }

  public render() {
    const { classes, ...other } = this.props;
    return (
      <Drawer variant="permanent" {...other}>
        <List disablePadding>
          <ListItem
            className={clsx(
              classes.firebase,
              classes.item,
              classes.itemCategory
            )}
            onClick={() => {
              window.location.href = "/index.html";
            }}
          >
            DirectoryApp
          </ListItem>
          <ListItem
            className={clsx(classes.item, classes.itemCategory)}
            style={{ marginBottom: 18 }}
            onClick={() => {
              window.location.href = "/index.html";
            }}
          >
            <ListItemIcon className={classes.itemIcon}>
              <Home />
            </ListItemIcon>
            <ListItemText
              classes={{
                primary: classes.itemPrimary,
              }}
            >
              Project Overview
            </ListItemText>
          </ListItem>
          {this.state.categories.map((category) => {
            return (
              <Link
                key={category.id}
                to={category.linkto}
                style={{ textDecoration: "none" }}
                onClick={() => {
                  let { categories } = this.state;
                  categories.forEach((categoryItem) => {
                    categoryItem.active = category.id === categoryItem.id;
                  });
                  this.setState({ categories });
                }}
              >
                <ListItem
                  key={category.id}
                  button
                  className={clsx(
                    classes.item,
                    category.active && classes.itemActiveItem
                  )}
                >
                  <ListItemIcon className={classes.itemIcon}>
                    {category.icon}
                  </ListItemIcon>
                  <ListItemText
                    classes={{
                      primary: classes.itemPrimary,
                    }}
                  >
                    {category.id}
                  </ListItemText>
                </ListItem>
              </Link>
            );
          })}
          <Divider className={classes.divider} />
        </List>
      </Drawer>
    );
  }
}

export default withStyles(styles)(Navigator);
