import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  AppBar,
  Toolbar,
  Paper,
  Grid,
  Button,
  TextField,
  Tooltip,
  IconButton,
  Switch,
  FormControlLabel,
  TableFooter,
  TablePagination,
} from "@material-ui/core";
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Delete, Edit, Add, Search, Clear } from "@material-ui/icons";
import Axios from "axios";
import { IdentityConstants } from "../../helpers/IdentityConstants";
import { AuthService } from "../../services/AuthService";
import { MessageInfo } from "../MessageInfo";
import { MessageForAction } from "../MessageForAction";
import { ReferenceEntitySelector } from "../ReferenceEntitySelector";
import Helpers from "../../helpers/Helpers";
import { IEvent } from "./dataTransferableObjects/IEvent";
import { IEventSave } from "./dataTransferableObjects/IEventSave";
import { IEventState } from "./componentStates/IEventState";
import { AppConstants } from "../../helpers/AppConstants";
import _ from "lodash";

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      margin: "auto",
      overflow: "hidden",
    },
    searchBar: {
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    },
    searchInput: {
      fontSize: theme.typography.fontSize,
    },
    block: {
      display: "block",
    },
  });

export interface IEventComponentProps extends WithStyles<typeof styles> {}

class Event extends React.Component<IEventComponentProps, IEventState> {
  private authService: AuthService;
  constructor(props: IEventComponentProps) {
    super(props);
    this.authService = new AuthService();
    this.state = {
      events: [],
      newEventData: this.resetEvent(),
      editEventData: this.resetEvent(),
      deleteEventData: {
        id: "",
        name: "",
      },
      isNewEventModalOpen: false,
      isEditEventModalOpen: false,
      isDeleteEventConfirmModalOpen: false,
      pageNumber: 0,
      eventsCount: 0,
      searchText: "",
      startDate: null,
      endDate: null,
      eventMessages: [],
      isErrorDialogOpen: false,
    };
    this.debounceRefreshEvents = _.debounce(this.debounceRefreshEvents, 1000);
  }

  debounceRefreshEvents() {
    this.refreshEvents();
  }

  resetEvent() {
    let data: IEvent = {
      id: "",
      name: "",
      description: "",
      startDate: null,
      endDate: null,
      building: null,
      eventType: null,
      isSpecial: false,
      isActive: true,
    };
    return data;
  }

  componentDidMount() {
    this.refreshTableData();
  }

  refreshTableData() {
    this.refreshEvents();
  }

  resetFilters() {
    this.setState({
      searchText: "",
      pageNumber: 0,
      startDate: null,
      endDate: null,
    });
  }

  refreshEvents() {
    this.setState({ eventMessages: [] });
    this.authService.getUser().then((user) => {
      if (user && user.access_token) {
        const headers = Helpers.getHeaders(user.access_token);
        let searchEventsDTO = {
          searchText: this.state.searchText,
          pageNumber: this.state.pageNumber,
          pageSize: AppConstants.TablePageSize,
          startDate: this.state.startDate,
          endDate: this.state.endDate,
        };
        Axios.post(
          `${IdentityConstants.apiRoot}Event/GetEventItems`,
          searchEventsDTO,
          {
            headers,
          }
        )
          .then((response) => {
            this.setState({
              events: response.data.items,
              eventsCount: response.data.searchItemsCount,
            });
          })
          .catch((error) => {
            this.handleCatch(error, MessageForAction.Read);
            this.setState({ isErrorDialogOpen: true });
          });
      }
    });
  }

  removeMessages(actionType: MessageForAction) {
    let filteredMessages = this.state.eventMessages.filter(
      (m) => m.messageForAction !== actionType
    );
    this.setState({ eventMessages: filteredMessages });
  }

  handleNewEventTypeSelect = (selectedEventType: any) => {
    let { newEventData } = this.state;
    newEventData.eventType = selectedEventType;
    this.setState({ newEventData });
  };

  handleNewBuildingSelect = (selectedBuilding: any) => {
    let { newEventData } = this.state;
    newEventData.building = selectedBuilding;
    this.setState({ newEventData });
  };

  handleEditEventTypeSelect = (selectedEventType: any) => {
    let { editEventData } = this.state;
    editEventData.eventType = selectedEventType;
    this.setState({ editEventData });
  };

  handleEditBuildingSelect = (selectedBuilding: any) => {
    let { editEventData } = this.state;
    editEventData.building = selectedBuilding;
    this.setState({ editEventData });
  };

  toggleNewEventModal() {
    this.setState({
      isNewEventModalOpen: !this.state.isNewEventModalOpen,
    });
  }

  toggleEditEventModal() {
    this.setState({
      isEditEventModalOpen: !this.state.isEditEventModalOpen,
    });
    this.removeMessages(MessageForAction.Update);
  }

  toggleDeleteEventModal() {
    this.setState({
      isDeleteEventConfirmModalOpen: !this.state.isDeleteEventConfirmModalOpen,
    });
    this.removeMessages(MessageForAction.Delete);
  }

  addNewEvent() {
    this.removeMessages(MessageForAction.Create);
    this.authService.getUser().then((user) => {
      if (user && user.access_token) {
        const headers = Helpers.getHeaders(user.access_token);
        Axios.post(
          `${IdentityConstants.apiRoot}Event`,
          this.CopyFrom(this.state.newEventData),
          { headers }
        )
          .then((_) => {
            this.refreshTableData();
            this.setState({
              isNewEventModalOpen: false,
              newEventData: this.resetEvent(),
            });
          })
          .catch((error) => {
            this.handleCatch(error, MessageForAction.Create);
          });
      }
    });
  }

  CopyFrom(event: IEvent): IEventSave {
    return {
      name: event.name,
      description: event.description,
      startDate: event.startDate,
      endDate: event.endDate,
      buildingId: event.building?.id,
      eventTypeId: event.eventType?.id,
      isSpecial: event.isSpecial,
      isActive: event.isActive,
    };
  }

  updateEvent() {
    this.removeMessages(MessageForAction.Update);
    this.authService.getUser().then((user) => {
      if (user && user.access_token) {
        const headers = Helpers.getHeaders(user.access_token);
        Axios.put(
          `${IdentityConstants.apiRoot}Event/${this.state.editEventData.id}`,
          this.CopyFrom(this.state.editEventData),
          { headers }
        )
          .then((response) => {
            this.refreshEvents();
            this.setState({
              isEditEventModalOpen: false,
              editEventData: this.resetEvent(),
            });
          })
          .catch((error) => {
            this.handleCatch(error, MessageForAction.Update);
          });
      }
    });
  }

  deleteEvent() {
    this.removeMessages(MessageForAction.Delete);
    this.authService.getUser().then((user) => {
      if (user && user.access_token) {
        const headers = Helpers.getHeaders(user.access_token);
        Axios.delete(
          `${IdentityConstants.apiRoot}Event/${this.state.deleteEventData.id}`,
          { headers }
        )
          .then(() => {
            this.refreshTableData();
            this.setState({
              isDeleteEventConfirmModalOpen: false,
              deleteEventData: { id: "", name: "" },
            });
          })
          .catch((error) => {
            this.handleCatch(error, MessageForAction.Delete);
          });
      }
    });
  }

  editEventSetState(
    id: string,
    name: string,
    description: string,
    startDate: Date,
    endDate: Date,
    building: any,
    eventType: any,
    isSpecial: boolean,
    isActive: boolean
  ) {
    let { editEventData } = this.state;
    editEventData.id = id;
    editEventData.name = name;
    editEventData.description = description;
    editEventData.startDate = startDate;
    editEventData.endDate = endDate;
    editEventData.building = building;
    editEventData.eventType = eventType;
    editEventData.isSpecial = isSpecial;
    editEventData.isActive = isActive;
    this.setState({
      editEventData,
      isEditEventModalOpen: !this.state.isEditEventModalOpen,
    });
  }

  deleteEventSetState(id: any, name: any) {
    let { deleteEventData } = this.state;
    deleteEventData.id = id;
    deleteEventData.name = name;
    this.setState({
      deleteEventData,
      isDeleteEventConfirmModalOpen: !this.state.isDeleteEventConfirmModalOpen,
    });
  }

  handleChangePage(
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) {
    this.setState({ pageNumber: newPage });
    this.debounceRefreshEvents();
  }

  public render() {
    const { classes } = this.props;
    let events = this.state.events.map((event) => {
      return (
        <TableRow key={event.id}>
          <TableCell>
            <Tooltip title="Edit">
              <IconButton
                onClick={this.editEventSetState.bind(
                  this,
                  event.id,
                  event.name,
                  event.description,
                  event.startDate,
                  event.endDate,
                  event.building,
                  event.eventType,
                  event.isSpecial,
                  event.isActive
                )}
              >
                <Edit style={{ marginRight: 2 }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton
                onClick={this.deleteEventSetState.bind(
                  this,
                  event.id,
                  event.name
                )}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          </TableCell>
          <TableCell>{event.name}</TableCell>
          <TableCell>{event.description}</TableCell>
          <TableCell>
            {event.startDate === null
              ? null
              : new Date(event.startDate).toDateString()}
          </TableCell>
          <TableCell>
            {event.endDate === null
              ? null
              : new Date(event.endDate).toDateString()}
          </TableCell>
          <TableCell>{event.building?.name}</TableCell>
          <TableCell>{event.eventType?.name}</TableCell>
          <TableCell>
            <Switch
              checked={event.isSpecial}
              color="primary"
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </TableCell>
          <TableCell>
            <Switch
              checked={event.isActive}
              color="primary"
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </TableCell>
        </TableRow>
      );
    });
    return (
      <Paper className={classes.paper}>
        <AppBar
          className={classes.searchBar}
          position="static"
          color="default"
          elevation={0}
        >
          <Toolbar>
            <Grid container alignItems="center" justify="center">
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  placeholder="Search by name, description, building name, or event type"
                  value={this.state.searchText}
                  onChange={(e) => {
                    this.setState({ searchText: e.target.value });
                  }}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      this.setState({ pageNumber: 0 });
                      this.refreshTableData();
                      e.preventDefault();
                    }
                  }}
                  InputProps={{
                    disableUnderline: true,
                    className: classes.searchInput,
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                style={{ display: "flex", alignItems: "center" }}
              >
                <span style={{ paddingRight: 15 }}>From</span>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    autoOk
                    openTo="year"
                    views={["year", "month", "date"]}
                    format="MM/dd/yyyy"
                    id="searchStartDate"
                    value={this.state.startDate}
                    onChange={(e) => {
                      this.setState({
                        startDate: Helpers.getDateWithUTCZero(e),
                      });
                    }}
                  />
                </MuiPickersUtilsProvider>
                <span style={{ paddingLeft: 15, paddingRight: 15 }}>To</span>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    autoOk
                    openTo="year"
                    views={["year", "month", "date"]}
                    format="MM/dd/yyyy"
                    id="searchEndDate"
                    value={this.state.endDate}
                    onChange={(e) => {
                      this.setState({
                        endDate: Helpers.getDateWithUTCZero(e),
                      });
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Dialog
                  open={this.state.isNewEventModalOpen}
                  onClose={this.toggleNewEventModal.bind(this)}
                  aria-labelledby="form-dialog-title"
                >
                  <DialogTitle id="form-dialog-title">Add Event</DialogTitle>
                  <DialogContent>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          autoFocus
                          id="EventName"
                          label="Name"
                          required
                          fullWidth
                          value={this.state.newEventData.name}
                          onChange={(e) => {
                            let { newEventData } = this.state;
                            newEventData.name = e.target.value;
                            this.setState({ newEventData });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="Description"
                          label="Description"
                          required
                          fullWidth
                          value={this.state.newEventData.description}
                          onChange={(e) => {
                            let { newEventData } = this.state;
                            newEventData.description = e.target.value;
                            this.setState({ newEventData });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            autoOk
                            openTo="year"
                            views={["year", "month", "date"]}
                            variant="inline"
                            format="MM/dd/yyyy"
                            id="addStartDate"
                            label="StartDate"
                            value={this.state.newEventData.startDate}
                            onChange={(e) => {
                              let { newEventData } = this.state;
                              newEventData.startDate = Helpers.getDateWithUTCZero(
                                e
                              );
                              this.setState({ newEventData });
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            autoOk
                            openTo="year"
                            views={["year", "month", "date"]}
                            variant="inline"
                            format="MM/dd/yyyy"
                            id="addEndDate"
                            label="EndDate"
                            value={this.state.newEventData.endDate}
                            onChange={(e) => {
                              let { newEventData } = this.state;
                              newEventData.endDate = Helpers.getDateWithUTCZero(
                                e
                              );
                              this.setState({ newEventData });
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <ReferenceEntitySelector
                          componentId="BuildingSelector"
                          selectedItem={this.state.newEventData.building}
                          label="Building"
                          url="Building/GetItemsForReferenceControl"
                          required={true}
                          onReferenceEntitySelectEvent={
                            this.handleNewBuildingSelect
                          }
                          handleException={(error) =>
                            this.handleCatch(error, MessageForAction.Create)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <ReferenceEntitySelector
                          componentId="EventTypeSelector"
                          selectedItem={this.state.newEventData.eventType}
                          label="EventType"
                          url="EventType/GetItemsForReferenceControl"
                          required={true}
                          onReferenceEntitySelectEvent={
                            this.handleNewEventTypeSelect
                          }
                          handleException={(error) =>
                            this.handleCatch(error, MessageForAction.Create)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControlLabel
                          label="Is special"
                          labelPlacement="start"
                          style={{ marginLeft: 0 }}
                          control={
                            <Switch
                              checked={this.state.newEventData.isSpecial}
                              value={this.state.newEventData.isSpecial}
                              onChange={(e) => {
                                let { newEventData } = this.state;
                                newEventData.isSpecial = e.target.checked;
                                this.setState({ newEventData });
                              }}
                              color="primary"
                              inputProps={{ "aria-label": "primary checkbox" }}
                            />
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControlLabel
                          label="IsActive"
                          labelPlacement="start"
                          style={{ marginLeft: 0 }}
                          control={
                            <Switch
                              checked={this.state.newEventData.isActive}
                              value={this.state.newEventData.isActive}
                              onChange={(e) => {
                                let { newEventData } = this.state;
                                newEventData.isActive = e.target.checked;
                                this.setState({ newEventData });
                              }}
                              color="primary"
                              inputProps={{ "aria-label": "primary checkbox" }}
                            />
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        {Helpers.handleError(
                          MessageForAction.Create,
                          this.state.eventMessages
                        )}
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      color="primary"
                      onClick={this.addNewEvent.bind(this)}
                    >
                      Add
                    </Button>
                    <Button
                      color="secondary"
                      onClick={this.toggleNewEventModal.bind(this)}
                    >
                      Cancel
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  open={this.state.isDeleteEventConfirmModalOpen}
                  onClose={this.toggleDeleteEventModal.bind(this)}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  fullWidth={true}
                  maxWidth={"sm"}
                >
                  <DialogTitle id="form-dialog-title">Delete Event</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Delete {this.state.deleteEventData.name} Event?
                    </DialogContentText>
                    {Helpers.handleError(
                      MessageForAction.Delete,
                      this.state.eventMessages
                    )}
                  </DialogContent>
                  <DialogActions>
                    <Button
                      color="primary"
                      onClick={this.deleteEvent.bind(this)}
                    >
                      Yes, delete
                    </Button>
                    <Button
                      color="secondary"
                      onClick={this.toggleDeleteEventModal.bind(this)}
                    >
                      Cancel
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  open={this.state.isEditEventModalOpen}
                  onClose={this.toggleEditEventModal.bind(this)}
                  aria-labelledby="form-dialog-title"
                >
                  <DialogTitle id="form-dialog-title">Edit Event</DialogTitle>
                  <DialogContent>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          autoFocus
                          id="EventName"
                          label="Name"
                          required
                          fullWidth
                          value={this.state.editEventData.name}
                          onChange={(e) => {
                            let { editEventData } = this.state;
                            editEventData.name = e.target.value;
                            this.setState({ editEventData });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="Description"
                          label="Description"
                          required
                          fullWidth
                          value={this.state.editEventData.description}
                          onChange={(e) => {
                            let { editEventData } = this.state;
                            editEventData.description = e.target.value;
                            this.setState({ editEventData });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            autoOk
                            disableFuture
                            openTo="year"
                            views={["year", "month", "date"]}
                            variant="inline"
                            format="MM/dd/yyyy"
                            id="addStartDate"
                            label="StartDate"
                            value={this.state.editEventData.startDate}
                            onChange={(e) => {
                              let { editEventData } = this.state;
                              editEventData.startDate = Helpers.getDateWithUTCZero(
                                e
                              );
                              this.setState({ editEventData });
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            autoOk
                            disableFuture
                            openTo="year"
                            views={["year", "month", "date"]}
                            variant="inline"
                            format="MM/dd/yyyy"
                            id="addEndDate"
                            label="EndDate"
                            value={this.state.editEventData.endDate}
                            onChange={(e) => {
                              let { editEventData } = this.state;
                              editEventData.endDate = Helpers.getDateWithUTCZero(
                                e
                              );
                              this.setState({ editEventData });
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <ReferenceEntitySelector
                          componentId="editBuildingSelector"
                          selectedItem={this.state.editEventData.building}
                          label="Building"
                          url="Building/GetItemsForReferenceControl"
                          required={true}
                          onReferenceEntitySelectEvent={
                            this.handleEditBuildingSelect
                          }
                          handleException={(error) =>
                            this.handleCatch(error, MessageForAction.Update)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <ReferenceEntitySelector
                          componentId="editEventTypeSelector"
                          selectedItem={this.state.editEventData.eventType}
                          label="EventType"
                          url="EventType/GetItemsForReferenceControl"
                          required={true}
                          onReferenceEntitySelectEvent={
                            this.handleEditEventTypeSelect
                          }
                          handleException={(error) =>
                            this.handleCatch(error, MessageForAction.Update)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControlLabel
                          label="Is special"
                          labelPlacement="start"
                          style={{ marginLeft: 0 }}
                          control={
                            <Switch
                              checked={this.state.editEventData.isSpecial}
                              value={this.state.editEventData.isSpecial}
                              onChange={(e) => {
                                let { editEventData } = this.state;
                                editEventData.isSpecial = e.target.checked;
                                this.setState({ editEventData });
                              }}
                              color="primary"
                              inputProps={{ "aria-label": "primary checkbox" }}
                            />
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControlLabel
                          label="IsActive"
                          labelPlacement="start"
                          style={{ marginLeft: 0 }}
                          control={
                            <Switch
                              checked={this.state.editEventData.isActive}
                              value={this.state.editEventData.isActive}
                              onChange={(e) => {
                                let { editEventData } = this.state;
                                editEventData.isActive = e.target.checked;
                                this.setState({ editEventData });
                              }}
                              color="primary"
                              inputProps={{ "aria-label": "primary checkbox" }}
                            />
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        {Helpers.handleError(
                          MessageForAction.Update,
                          this.state.eventMessages
                        )}
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      color="primary"
                      onClick={this.updateEvent.bind(this)}
                    >
                      Update
                    </Button>
                    <Button
                      color="secondary"
                      onClick={this.toggleEditEventModal.bind(this)}
                    >
                      Cancel
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  open={this.state.isErrorDialogOpen}
                  onClose={() => {
                    this.setState({
                      isErrorDialogOpen: false,
                      eventMessages: [],
                    });
                  }}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  fullWidth={true}
                  maxWidth={"sm"}
                >
                  <DialogTitle id="form-dialog-title">
                    Error occurred
                  </DialogTitle>
                  <DialogContent>
                    {Helpers.handleError(
                      MessageForAction.Read,
                      this.state.eventMessages
                    )}
                  </DialogContent>
                  <DialogActions>
                    <Button
                      color="secondary"
                      onClick={() => {
                        this.setState({
                          isErrorDialogOpen: false,
                          eventMessages: [],
                        });
                      }}
                    >
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>
                <Tooltip title="Search">
                  <IconButton onClick={this.refreshTableData.bind(this)}>
                    <Search />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Reset filters">
                  <IconButton onClick={this.resetFilters.bind(this)}>
                    <Clear />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Add">
                  <IconButton onClick={this.toggleNewEventModal.bind(this)}>
                    <Add />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Action</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Start date</TableCell>
                <TableCell>End date</TableCell>
                <TableCell>Building</TableCell>
                <TableCell>EventType</TableCell>
                <TableCell>IsSpecial</TableCell>
                <TableCell>IsActive</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{events}</TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10]}
                  count={this.state.eventsCount}
                  rowsPerPage={10}
                  page={this.state.pageNumber}
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    native: true,
                  }}
                  onChangePage={this.handleChangePage.bind(this)}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Paper>
    );
  }

  private handleCatch(error: any, messageForAction: MessageForAction) {
    let { eventMessages } = this.state;
    if (error.toString().includes("Network Error")) {
      eventMessages.push(
        new MessageInfo(
          messageForAction,
          "Server unavailable, try again later",
          503
        )
      );
    } else if (
      error.toString().includes("Request failed with status code 401")
    ) {
      this.authService.login();
    } else if (
      error.toString().includes("Request failed with status code 500")
    ) {
      eventMessages.push(
        new MessageInfo(messageForAction, error.toString(), 500)
      );
    } else if (error.response.data.hasOwnProperty("errors")) {
      error.response.data.errors.forEach((errorItem: any) => {
        eventMessages.push(
          new MessageInfo(
            messageForAction,
            errorItem.message,
            error.response.status
          )
        );
      });
    } else if (error.response.data.hasOwnProperty("Message")) {
      eventMessages.push(
        new MessageInfo(
          messageForAction,
          error.response.data.Message,
          error.response.status
        )
      );
    } else {
      eventMessages.push(
        new MessageInfo(messageForAction, error, error.response.status)
      );
    }
    this.setState({ eventMessages });
  }
}

export default withStyles(styles)(Event);
