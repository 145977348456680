import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  AppBar,
  Toolbar,
  Paper,
  Grid,
  Button,
  TextField,
  Tooltip,
  IconButton,
  Switch,
  FormControlLabel,
  TableFooter,
  TablePagination,
} from "@material-ui/core";
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Delete, Edit, Add, Search, Refresh } from "@material-ui/icons";
import Axios from "axios";
import { IdentityConstants } from "../../helpers/IdentityConstants";
import { AuthService } from "../../services/AuthService";
import { MessageInfo } from "../MessageInfo";
import { MessageForAction } from "../MessageForAction";
import { ReferenceEntitySelector } from "../ReferenceEntitySelector";
import Helpers from "../../helpers/Helpers";
import { IMarriage } from "./dataTransferableObjects/IMarriage";
import { IMarriageSave } from "./dataTransferableObjects/IMarriageSave";
import { IMarriageState } from "./componentStates/IMarriageState";
import { AppConstants } from "../../helpers/AppConstants";
import _ from "lodash";

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      margin: "auto",
      overflow: "hidden",
    },
    searchBar: {
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    },
    searchInput: {
      fontSize: theme.typography.fontSize,
    },
    block: {
      display: "block",
    },
  });

export interface IMarriageComponentProps extends WithStyles<typeof styles> {}

class Marriage extends React.Component<
  IMarriageComponentProps,
  IMarriageState
> {
  private authService: AuthService;
  constructor(props: IMarriageComponentProps) {
    super(props);
    this.authService = new AuthService();
    this.state = {
      marriages: [],
      newMarriageData: this.resetMarriage(),
      editMarriageData: this.resetMarriage(),
      deleteMarriageData: {
        id: "",
        deleteMessage: "",
      },
      isNewMarriageModalOpen: false,
      isEditMarriageModalOpen: false,
      isDeleteMarriageConfirmModalOpen: false,
      pageNumber: 0,
      marriagesCount: 0,
      searchText: "",
      marriageMessages: [],
      isErrorDialogOpen: false,
    };
    this.debounceRefreshMarriages = _.debounce(
      this.debounceRefreshMarriages,
      1000
    );
  }

  debounceRefreshMarriages() {
    this.refreshMarriages();
  }

  resetMarriage() {
    let data: IMarriage = {
      id: "",
      husband: null,
      wife: null,
      startDate: null,
      endDate: null,
      isActive: true,
    };
    return data;
  }

  componentDidMount() {
    this.refreshTableData();
  }

  refreshTableData() {
    this.refreshMarriages();
  }

  refreshMarriages() {
    this.setState({ marriageMessages: [] });
    this.authService.getUser().then((user) => {
      if (user && user.access_token) {
        const headers = Helpers.getHeaders(user.access_token);
        let searchDTO = {
          searchText: this.state.searchText,
          pageNumber: this.state.pageNumber,
          pageSize: AppConstants.TablePageSize,
        };
        Axios.post(`${IdentityConstants.apiRoot}Marriage/GetItems`, searchDTO, {
          headers,
        })
          .then((response) => {
            this.setState({
              marriages: response.data.items,
              marriagesCount: response.data.searchItemsCount,
            });
          })
          .catch((error) => {
            this.handleCatch(error, MessageForAction.Read);
            this.setState({ isErrorDialogOpen: true });
          });
      }
    });
  }

  removeMessages(actionType: MessageForAction) {
    let filteredMessages = this.state.marriageMessages.filter(
      (m) => m.messageForAction !== actionType
    );
    this.setState({ marriageMessages: filteredMessages });
  }

  handleNewHusbandSelect = (selectedHusband: any) => {
    let { newMarriageData } = this.state;
    newMarriageData.husband = selectedHusband;
    this.setState({ newMarriageData });
  };

  handleNewWifeSelect = (selectedWife: any) => {
    let { newMarriageData } = this.state;
    newMarriageData.wife = selectedWife;
    this.setState({ newMarriageData });
  };

  handleEditHusbandSelect = (selectedHusband: any) => {
    let { editMarriageData } = this.state;
    editMarriageData.husband = selectedHusband;
    this.setState({ editMarriageData });
  };

  handleEditWifeSelect = (selectedWife: any) => {
    let { editMarriageData } = this.state;
    editMarriageData.wife = selectedWife;
    this.setState({ editMarriageData });
  };

  toggleNewMarriageModal() {
    this.setState({
      isNewMarriageModalOpen: !this.state.isNewMarriageModalOpen,
    });
  }

  toggleEditMarriageModal() {
    this.setState({
      isEditMarriageModalOpen: !this.state.isEditMarriageModalOpen,
    });
    this.removeMessages(MessageForAction.Update);
  }

  toggleDeleteMarriageModal() {
    this.setState({
      isDeleteMarriageConfirmModalOpen: !this.state
        .isDeleteMarriageConfirmModalOpen,
    });
    this.removeMessages(MessageForAction.Delete);
  }

  addNewMarriage() {
    this.removeMessages(MessageForAction.Create);
    this.authService.getUser().then((user) => {
      if (user && user.access_token) {
        const headers = Helpers.getHeaders(user.access_token);
        Axios.post(
          `${IdentityConstants.apiRoot}Marriage`,
          this.CopyFrom(this.state.newMarriageData),
          { headers }
        )
          .then((response) => {
            this.refreshTableData();
            this.setState({
              isNewMarriageModalOpen: false,
              newMarriageData: this.resetMarriage(),
            });
          })
          .catch((error) => {
            this.handleCatch(error, MessageForAction.Create);
          });
      }
    });
  }

  CopyFrom(marriage: IMarriage): IMarriageSave {
    return {
      husbandId: marriage.husband?.id,
      wifeId: marriage.wife?.id,
      startDate: marriage.startDate,
      endDate: marriage.endDate,
      isActive: marriage.isActive,
    };
  }

  updateMarriage() {
    this.removeMessages(MessageForAction.Update);
    this.authService.getUser().then((user) => {
      if (user && user.access_token) {
        const headers = Helpers.getHeaders(user.access_token);
        Axios.put(
          `${IdentityConstants.apiRoot}Marriage/${this.state.editMarriageData.id}`,
          this.CopyFrom(this.state.editMarriageData),
          { headers }
        )
          .then((response) => {
            this.refreshMarriages();
            this.setState({
              isEditMarriageModalOpen: false,
              editMarriageData: this.resetMarriage(),
            });
          })
          .catch((error) => {
            this.handleCatch(error, MessageForAction.Update);
          });
      }
    });
  }

  deleteMarriage() {
    this.removeMessages(MessageForAction.Delete);
    this.authService.getUser().then((user) => {
      if (user && user.access_token) {
        const headers = Helpers.getHeaders(user.access_token);
        Axios.delete(
          `${IdentityConstants.apiRoot}Marriage/${this.state.deleteMarriageData.id}`,
          { headers }
        )
          .then(() => {
            this.refreshTableData();
            this.setState({
              isDeleteMarriageConfirmModalOpen: false,
              deleteMarriageData: { id: "", deleteMessage: "" },
            });
          })
          .catch((error) => {
            this.handleCatch(error, MessageForAction.Delete);
          });
      }
    });
  }

  editMarriageSetState(
    id: string,
    husband: any,
    wife: any,
    startDate: Date,
    endDate: Date,
    isActive: boolean
  ) {
    let { editMarriageData } = this.state;
    editMarriageData.id = id;
    editMarriageData.husband = husband;
    editMarriageData.wife = wife;
    editMarriageData.startDate = startDate;
    editMarriageData.endDate = endDate;
    editMarriageData.isActive = isActive;
    this.setState({
      editMarriageData,
      isEditMarriageModalOpen: !this.state.isEditMarriageModalOpen,
    });
  }

  deleteMarriageSetState(id: any, name: any) {
    let { deleteMarriageData } = this.state;
    deleteMarriageData.id = id;
    deleteMarriageData.deleteMessage = name;
    this.setState({
      deleteMarriageData,
      isDeleteMarriageConfirmModalOpen: !this.state
        .isDeleteMarriageConfirmModalOpen,
    });
  }

  handleChangePage(
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) {
    this.setState({ pageNumber: newPage });
    this.debounceRefreshMarriages();
  }

  public render() {
    const { classes } = this.props;
    let marriages = this.state.marriages.map((marriage) => {
      return (
        <TableRow key={marriage.id}>
          <TableCell>
            <Tooltip title="Edit">
              <IconButton
                onClick={this.editMarriageSetState.bind(
                  this,
                  marriage.id,
                  marriage.husband,
                  marriage.wife,
                  marriage.startDate,
                  marriage.endDate,
                  marriage.isActive
                )}
              >
                <Edit style={{ marginRight: 2 }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton
                onClick={this.deleteMarriageSetState.bind(
                  this,
                  marriage.id,
                  `${marriage.husband?.title} and ${marriage.wife?.title}`
                )}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          </TableCell>
          <TableCell>{marriage.husband?.title}</TableCell>
          <TableCell>{marriage.wife?.title}</TableCell>
          <TableCell>
            {marriage.startDate === null
              ? null
              : new Date(marriage.startDate).toDateString()}
          </TableCell>
          <TableCell>
            {marriage.endDate === null
              ? null
              : new Date(marriage.endDate).toDateString()}
          </TableCell>
          <TableCell>
            <Switch
              checked={marriage.isActive}
              color="primary"
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </TableCell>
        </TableRow>
      );
    });
    return (
      <Paper className={classes.paper}>
        <AppBar
          className={classes.searchBar}
          position="static"
          color="default"
          elevation={0}
        >
          <Toolbar>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <Search className={classes.block} color="inherit" />
              </Grid>
              <Grid item xs>
                <TextField
                  fullWidth
                  placeholder="Search by Husband and Wife's first and last name"
                  value={this.state.searchText}
                  onChange={(e) => {
                    this.setState({ searchText: e.target.value });
                  }}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      this.setState({ pageNumber: 0 });
                      this.refreshTableData();
                      e.preventDefault();
                    }
                  }}
                  InputProps={{
                    disableUnderline: true,
                    className: classes.searchInput,
                  }}
                />
              </Grid>
              <Grid item>
                <Tooltip title="Add">
                  <IconButton onClick={this.toggleNewMarriageModal.bind(this)}>
                    <Add />
                  </IconButton>
                </Tooltip>
                <Dialog
                  open={this.state.isNewMarriageModalOpen}
                  onClose={this.toggleNewMarriageModal.bind(this)}
                  aria-labelledby="form-dialog-title"
                >
                  <DialogTitle id="form-dialog-title">Add Marriage</DialogTitle>
                  <DialogContent>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6}>
                        <ReferenceEntitySelector
                          componentId="addHusbandSelector"
                          selectedItem={this.state.newMarriageData.husband}
                          label="Husband"
                          url="Person/GetItemsForReferenceControl"
                          required={true}
                          onReferenceEntitySelectEvent={
                            this.handleNewHusbandSelect
                          }
                          handleException={(error) =>
                            this.handleCatch(error, MessageForAction.Create)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <ReferenceEntitySelector
                          componentId="addWifeSelector"
                          selectedItem={this.state.newMarriageData.wife}
                          label="Wife"
                          url="Person/GetItemsForReferenceControl"
                          required={true}
                          onReferenceEntitySelectEvent={
                            this.handleNewWifeSelect
                          }
                          handleException={(error) =>
                            this.handleCatch(error, MessageForAction.Create)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            autoOk
                            disableFuture
                            openTo="year"
                            views={["year", "month", "date"]}
                            variant="inline"
                            format="MM/dd/yyyy"
                            id="addStartDate"
                            label="StartDate"
                            value={this.state.newMarriageData.startDate}
                            onChange={(e) => {
                              let { newMarriageData } = this.state;
                              newMarriageData.startDate = Helpers.getDateWithUTCZero(
                                e
                              );
                              this.setState({ newMarriageData });
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            autoOk
                            disableFuture
                            openTo="year"
                            views={["year", "month", "date"]}
                            variant="inline"
                            format="MM/dd/yyyy"
                            id="addEndDate"
                            label="EndDate"
                            value={this.state.newMarriageData.endDate}
                            onChange={(e) => {
                              let { newMarriageData } = this.state;
                              newMarriageData.endDate = Helpers.getDateWithUTCZero(
                                e
                              );
                              this.setState({ newMarriageData });
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControlLabel
                          label="IsActive"
                          labelPlacement="start"
                          style={{ marginLeft: 0 }}
                          control={
                            <Switch
                              checked={this.state.newMarriageData.isActive}
                              value={this.state.newMarriageData.isActive}
                              onChange={(e) => {
                                let { newMarriageData } = this.state;
                                newMarriageData.isActive = e.target.checked;
                                this.setState({ newMarriageData });
                              }}
                              color="primary"
                              inputProps={{ "aria-label": "primary checkbox" }}
                            />
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        {Helpers.handleError(
                          MessageForAction.Create,
                          this.state.marriageMessages
                        )}
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      color="primary"
                      onClick={this.addNewMarriage.bind(this)}
                    >
                      Add
                    </Button>
                    <Button
                      color="secondary"
                      onClick={this.toggleNewMarriageModal.bind(this)}
                    >
                      Cancel
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  open={this.state.isDeleteMarriageConfirmModalOpen}
                  onClose={this.toggleDeleteMarriageModal.bind(this)}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  fullWidth={true}
                  maxWidth={"sm"}
                >
                  <DialogTitle id="form-dialog-title">
                    Delete Marriage
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Delete {this.state.deleteMarriageData.deleteMessage}{" "}
                      Marriage?
                    </DialogContentText>
                    {Helpers.handleError(
                      MessageForAction.Delete,
                      this.state.marriageMessages
                    )}
                  </DialogContent>
                  <DialogActions>
                    <Button
                      color="primary"
                      onClick={this.deleteMarriage.bind(this)}
                    >
                      Yes, delete
                    </Button>
                    <Button
                      color="secondary"
                      onClick={this.toggleDeleteMarriageModal.bind(this)}
                    >
                      Cancel
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  open={this.state.isEditMarriageModalOpen}
                  onClose={this.toggleEditMarriageModal.bind(this)}
                  aria-labelledby="form-dialog-title"
                >
                  <DialogTitle id="form-dialog-title">
                    Edit Marriage
                  </DialogTitle>
                  <DialogContent>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6}>
                        <ReferenceEntitySelector
                          componentId="editHusbandSelector"
                          selectedItem={this.state.editMarriageData.husband}
                          label="Husband"
                          url="Person/GetItemsForReferenceControl"
                          required={true}
                          onReferenceEntitySelectEvent={
                            this.handleEditHusbandSelect
                          }
                          handleException={(error) =>
                            this.handleCatch(error, MessageForAction.Update)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <ReferenceEntitySelector
                          componentId="editWifeSelector"
                          selectedItem={this.state.editMarriageData.wife}
                          label="Wife"
                          url="Person/GetItemsForReferenceControl"
                          required={true}
                          onReferenceEntitySelectEvent={
                            this.handleEditWifeSelect
                          }
                          handleException={(error) =>
                            this.handleCatch(error, MessageForAction.Update)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            autoOk
                            disableFuture
                            openTo="year"
                            views={["year", "month", "date"]}
                            variant="inline"
                            format="MM/dd/yyyy"
                            id="editStartDate"
                            label="StartDate"
                            value={this.state.editMarriageData.startDate}
                            onChange={(e) => {
                              let { editMarriageData } = this.state;
                              editMarriageData.startDate = Helpers.getDateWithUTCZero(
                                e
                              );
                              this.setState({ editMarriageData });
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            autoOk
                            disableFuture
                            openTo="year"
                            views={["year", "month", "date"]}
                            variant="inline"
                            format="MM/dd/yyyy"
                            id="editEndDate"
                            label="EndDate"
                            value={this.state.editMarriageData.endDate}
                            onChange={(e) => {
                              let { editMarriageData } = this.state;
                              editMarriageData.endDate = Helpers.getDateWithUTCZero(
                                e
                              );
                              this.setState({ editMarriageData });
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControlLabel
                          label="IsActive"
                          labelPlacement="start"
                          style={{ marginLeft: 0 }}
                          control={
                            <Switch
                              checked={this.state.editMarriageData.isActive}
                              value={this.state.editMarriageData.isActive}
                              onChange={(e) => {
                                let { editMarriageData } = this.state;
                                editMarriageData.isActive = e.target.checked;
                                this.setState({ editMarriageData });
                              }}
                              color="primary"
                              inputProps={{ "aria-label": "primary checkbox" }}
                            />
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        {Helpers.handleError(
                          MessageForAction.Update,
                          this.state.marriageMessages
                        )}
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      color="primary"
                      onClick={this.updateMarriage.bind(this)}
                    >
                      Update
                    </Button>
                    <Button
                      color="secondary"
                      onClick={this.toggleEditMarriageModal.bind(this)}
                    >
                      Cancel
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  open={this.state.isErrorDialogOpen}
                  onClose={() => {
                    this.setState({
                      isErrorDialogOpen: false,
                      marriageMessages: [],
                    });
                  }}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  fullWidth={true}
                  maxWidth={"sm"}
                >
                  <DialogTitle id="form-dialog-title">
                    Error occurred
                  </DialogTitle>
                  <DialogContent>
                    {Helpers.handleError(
                      MessageForAction.Read,
                      this.state.marriageMessages
                    )}
                  </DialogContent>
                  <DialogActions>
                    <Button
                      color="secondary"
                      onClick={() => {
                        this.setState({
                          isErrorDialogOpen: false,
                          marriageMessages: [],
                        });
                      }}
                    >
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>
                <Tooltip title="Reload">
                  <IconButton onClick={this.refreshTableData.bind(this)}>
                    <Refresh className={classes.block} color="inherit" />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Action</TableCell>
                <TableCell>Husband</TableCell>
                <TableCell>Wife</TableCell>
                <TableCell>Start date</TableCell>
                <TableCell>End date</TableCell>
                <TableCell>IsActive</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{marriages}</TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10]}
                  count={this.state.marriagesCount}
                  rowsPerPage={10}
                  page={this.state.pageNumber}
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    native: true,
                  }}
                  onChangePage={this.handleChangePage.bind(this)}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Paper>
    );
  }

  private handleCatch(error: any, messageForAction: MessageForAction) {
    let { marriageMessages } = this.state;
    if (error.toString().includes("Network Error")) {
      marriageMessages.push(
        new MessageInfo(
          messageForAction,
          "Server unavailable, try again later",
          503
        )
      );
    } else if (
      error.toString().includes("Request failed with status code 401")
    ) {
      this.authService.login();
    } else if (
      error.toString().includes("Request failed with status code 500")
    ) {
      marriageMessages.push(
        new MessageInfo(messageForAction, error.toString(), 500)
      );
    } else if (error.response.data.hasOwnProperty("errors")) {
      error.response.data.errors.forEach((errorItem: any) => {
        marriageMessages.push(
          new MessageInfo(
            messageForAction,
            errorItem.message,
            error.response.status
          )
        );
      });
    } else if (error.response.data.hasOwnProperty("Message")) {
      marriageMessages.push(
        new MessageInfo(
          messageForAction,
          error.response.data.Message,
          error.response.status
        )
      );
    } else {
      marriageMessages.push(
        new MessageInfo(messageForAction, error, error.response.status)
      );
    }
    this.setState({ marriageMessages });
  }
}

export default withStyles(styles)(Marriage);
