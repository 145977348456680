import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  AppBar,
  Toolbar,
  Paper,
  Grid,
  Button,
  TextField,
  Tooltip,
  IconButton,
  Switch,
  FormControlLabel,
  Typography,
  TableFooter,
  TablePagination,
} from "@material-ui/core";
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Delete, Edit, Add, Search, Refresh } from "@material-ui/icons";
import Axios from "axios";
import { IdentityConstants } from "../../helpers/IdentityConstants";
import { AuthService } from "../../services/AuthService";
import { MessageInfo } from "../MessageInfo";
import { MessageForAction } from "../MessageForAction";
import { ReferenceEntitySelector } from "../ReferenceEntitySelector";
import Helpers from "../../helpers/Helpers";
import { IPerson } from "./dataTransferableObjects/IPerson";
import { IPersonSave } from "./dataTransferableObjects/IPersonSave";
import { IPersonState } from "./componentStates/IPersonState";
import { Link } from "react-router-dom";
import { AppConstants } from "../../helpers/AppConstants";
import _ from "lodash";

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      margin: "auto",
      overflow: "hidden",
    },
    searchBar: {
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    },
    searchInput: {
      fontSize: theme.typography.fontSize,
    },
    block: {
      display: "block",
    },
  });

export interface IPersonComponentProps extends WithStyles<typeof styles> {}

class Person extends React.Component<IPersonComponentProps, IPersonState> {
  private authService: AuthService;
  constructor(props: IPersonComponentProps) {
    super(props);
    this.authService = new AuthService();
    this.state = {
      persons: [],
      newPersonData: this.resetPerson(),
      editPersonData: this.resetPerson(),
      deletePersonData: {
        id: "",
        name: "",
      },
      isNewPersonModalOpen: false,
      isEditPersonModalOpen: false,
      isDeletePersonConfirmModalOpen: false,
      pageNumber: 0,
      personsCount: 0,
      searchText: "",
      personMessages: [],
      isErrorDialogOpen: false,
    };
    this.debounceRefreshPersons = _.debounce(this.debounceRefreshPersons, 1000);
  }

  debounceRefreshPersons() {
    this.refreshPersons();
  }

  resetPerson() {
    let data: IPerson = {
      id: "",
      title: "",
      firstName: "",
      preferredFirstName: "",
      lastName: "",
      middleName: "",
      isMiddleNameShortened: true,
      gender: false,
      birthDate: null,
      deathDate: null,
      deathComment: "",
      comment: "",
      phone1: "",
      phone2: "",
      email: "",
      memberOfChurchId: "",
      memberOfChurch: null,
      father: null,
      mother: null,
      isActive: true,
      householdMembers: [],
      primaryHouseholds: [],
      secondaryHouseholds: [],
    };
    return data;
  }

  componentDidMount() {
    this.refreshTableData();
  }

  removeMessages(actionType: MessageForAction) {
    let filteredMessages = this.state.personMessages.filter(
      (m) => m.messageForAction !== actionType
    );
    this.setState({ personMessages: filteredMessages });
  }

  handleNewMemberOfChurchSelect = (selectedMemberOfChurch: any) => {
    let { newPersonData } = this.state;
    newPersonData.memberOfChurch = selectedMemberOfChurch;
    this.setState({ newPersonData });
  };

  handleNewFatherSelect = (selectedFather: any) => {
    let { newPersonData } = this.state;
    newPersonData.father = selectedFather;
    this.setState({ newPersonData });
  };

  handleNewMotherSelect = (selectedMother: any) => {
    let { newPersonData } = this.state;
    newPersonData.mother = selectedMother;
    this.setState({ newPersonData });
  };

  handleEditMemberOfChurchSelect = (selectedMemberOfChurch: any) => {
    let { editPersonData } = this.state;
    editPersonData.memberOfChurch = selectedMemberOfChurch;
    this.setState({ editPersonData });
  };

  handleEditFatherSelect = (selectedFather: any) => {
    let { editPersonData } = this.state;
    editPersonData.father = selectedFather;
    this.setState({ editPersonData });
  };

  handleEditMotherSelect = (selectedMother: any) => {
    let { editPersonData } = this.state;
    editPersonData.mother = selectedMother;
    this.setState({ editPersonData });
  };

  refreshPersons() {
    this.setState({ personMessages: [] });
    this.authService.getUser().then((user) => {
      if (user && user.access_token) {
        const headers = Helpers.getHeaders(user.access_token);
        let searchDTO = {
          searchText: this.state.searchText,
          pageNumber: this.state.pageNumber,
          pageSize: AppConstants.TablePageSize,
        };
        Axios.post(`${IdentityConstants.apiRoot}Person/GetPersons`, searchDTO, {
          headers,
        })
          .then((response) => {
            this.setState({
              persons: response.data.items,
              personsCount: response.data.searchItemsCount,
            });
          })
          .catch((error) => {
            this.handleCatch(error, MessageForAction.Read);
            this.setState({ isErrorDialogOpen: true });
          });
      }
    });
  }

  refreshTableData() {
    this.refreshPersons();
  }

  toggleNewPersonModal() {
    this.setState({ isNewPersonModalOpen: !this.state.isNewPersonModalOpen });
  }

  toggleEditPersonModal() {
    this.setState({ isEditPersonModalOpen: !this.state.isEditPersonModalOpen });
    this.removeMessages(MessageForAction.Update);
  }

  toggleDeletePersonModal() {
    this.setState({
      isDeletePersonConfirmModalOpen: !this.state
        .isDeletePersonConfirmModalOpen,
    });
    this.removeMessages(MessageForAction.Delete);
  }

  addNewPerson() {
    this.removeMessages(MessageForAction.Create);
    this.authService.getUser().then((user) => {
      if (user && user.access_token) {
        const headers = Helpers.getHeaders(user.access_token);
        Axios.post(
          `${IdentityConstants.apiRoot}Person`,
          this.CopyFrom(this.state.newPersonData),
          { headers }
        )
          .then((_) => {
            this.refreshPersons();
            this.setState({
              isNewPersonModalOpen: false,
              newPersonData: this.resetPerson(),
            });
          })
          .catch((error) => {
            this.handleCatch(error, MessageForAction.Create);
          });
      }
    });
  }

  CopyFrom(person: IPerson): IPersonSave {
    return {
      firstName: person.firstName,
      preferredFirstName: person.preferredFirstName,
      lastName: person.lastName,
      middleName: person.middleName,
      isMiddleNameShortened: person.isMiddleNameShortened,
      gender: person.gender,
      birthDate: person.birthDate,
      deathDate: person.deathDate,
      deathComment: person.deathComment,
      comment: person.comment,
      phone1: person.phone1,
      phone2: person.phone2,
      email: person.email,
      memberOfChurchId: person.memberOfChurch?.id,
      fatherId: person.father?.id,
      motherId: person.mother?.id,
      isActive: person.isActive,
    };
  }

  updatePerson() {
    this.removeMessages(MessageForAction.Update);
    this.authService.getUser().then((user) => {
      if (user && user.access_token) {
        const headers = Helpers.getHeaders(user.access_token);
        Axios.put(
          `${IdentityConstants.apiRoot}Person/${this.state.editPersonData.id}`,
          this.CopyFrom(this.state.editPersonData),
          { headers }
        )
          .then((_) => {
            this.refreshPersons();
            this.setState({
              isEditPersonModalOpen: false,
              editPersonData: this.resetPerson(),
            });
          })
          .catch((error) => {
            this.handleCatch(error, MessageForAction.Update);
          });
      }
    });
  }

  deletePerson() {
    this.removeMessages(MessageForAction.Delete);
    this.authService.getUser().then((user) => {
      if (user && user.access_token) {
        const headers = Helpers.getHeaders(user.access_token);
        Axios.delete(
          `${IdentityConstants.apiRoot}Person/${this.state.deletePersonData.id}`,
          { headers }
        )
          .then(() => {
            this.refreshPersons();
            this.setState({
              isDeletePersonConfirmModalOpen: false,
              deletePersonData: {
                id: "",
                name: "",
              },
            });
          })
          .catch((error) => {
            this.handleCatch(error, MessageForAction.Delete);
          });
      }
    });
  }

  editPersonSetState(id: string) {
    this.setState({ editPersonData: this.resetPerson() });
    this.authService.getUser().then((user) => {
      if (user && user.access_token) {
        const headers = Helpers.getHeaders(user.access_token);
        Axios.get(`${IdentityConstants.apiRoot}Person/${id}`, {
          headers,
        })
          .then((response) => {
            let { editPersonData } = this.state;
            editPersonData = response.data;
            this.setState({
              editPersonData,
              isEditPersonModalOpen: true,
            });
          })
          .catch((error) => {
            this.handleCatch(error, MessageForAction.Read);
          });
      }
    });
  }

  deletePersonSetState(id: any, name: any) {
    let { deletePersonData } = this.state;
    deletePersonData.id = id;
    deletePersonData.name = name;
    this.setState({
      deletePersonData,
      isDeletePersonConfirmModalOpen: !this.state
        .isDeletePersonConfirmModalOpen,
    });
  }

  handleChangePage(
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) {
    this.setState({ pageNumber: newPage });
    this.debounceRefreshPersons();
  }

  public render() {
    const { classes } = this.props;
    let persons = this.state.persons.map((person) => {
      return (
        <TableRow key={person.id}>
          <TableCell style={{ width: 100 }}>
            <Tooltip title="Edit">
              <IconButton
                style={{ padding: 0 }}
                onClick={this.editPersonSetState.bind(this, person.id)}
              >
                <Edit style={{ marginRight: 2 }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton
                style={{ padding: 0 }}
                onClick={this.deletePersonSetState.bind(
                  this,
                  person.id,
                  person.title
                )}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          </TableCell>
          <TableCell>{person.firstName}</TableCell>
          <TableCell>{person.preferredFirstName}</TableCell>
          <TableCell>{person.lastName}</TableCell>
          <TableCell>{person.gender ? "Male" : "Female"}</TableCell>
          <TableCell>
            {person.birthDate === null
              ? null
              : new Date(person.birthDate).toDateString()}
          </TableCell>
          <TableCell>
            {person.deathDate === null
              ? null
              : new Date(person.deathDate).toDateString()}
          </TableCell>
          <TableCell>{person.phone1}</TableCell>
          <TableCell>{person.email}</TableCell>
          <TableCell>{person.memberOfChurch}</TableCell>
          <TableCell>{person.father}</TableCell>
          <TableCell>{person.mother}</TableCell>
          <TableCell>
            <Switch
              checked={person.isActive}
              color="primary"
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </TableCell>
        </TableRow>
      );
    });
    return (
      <Paper className={classes.paper}>
        <AppBar
          className={classes.searchBar}
          position="static"
          color="default"
          elevation={0}
        >
          <Toolbar>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <Search className={classes.block} color="inherit" />
              </Grid>
              <Grid item xs>
                <TextField
                  fullWidth
                  placeholder="Search by first name, last name, or middle name"
                  value={this.state.searchText}
                  onChange={(e) => {
                    this.setState({ searchText: e.target.value });
                  }}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      this.setState({ pageNumber: 0 });
                      this.refreshTableData();
                      e.preventDefault();
                    }
                  }}
                  InputProps={{
                    disableUnderline: true,
                    className: classes.searchInput,
                  }}
                />
              </Grid>
              <Grid item>
                <Tooltip title="Add">
                  <IconButton onClick={this.toggleNewPersonModal.bind(this)}>
                    <Add />
                  </IconButton>
                </Tooltip>
                <Dialog
                  open={this.state.isNewPersonModalOpen}
                  onClose={this.toggleNewPersonModal.bind(this)}
                  aria-labelledby="form-dialog-title"
                >
                  <DialogTitle id="form-dialog-title">Add Person</DialogTitle>
                  <DialogContent>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          autoFocus
                          id="addFirstName"
                          label="FirstName"
                          required
                          fullWidth
                          value={this.state.newPersonData.firstName ?? ""}
                          onChange={(e) => {
                            let { newPersonData } = this.state;
                            newPersonData.firstName = e.target.value;
                            this.setState({ newPersonData });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="addPreferredFirstName"
                          label="PreferredFirstName"
                          fullWidth
                          value={
                            this.state.newPersonData.preferredFirstName ?? ""
                          }
                          onChange={(e) => {
                            let { newPersonData } = this.state;
                            newPersonData.preferredFirstName = e.target.value;
                            this.setState({ newPersonData });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="addLastName"
                          label="LastName"
                          required
                          fullWidth
                          value={this.state.newPersonData.lastName ?? ""}
                          onChange={(e) => {
                            let { newPersonData } = this.state;
                            newPersonData.lastName = e.target.value;
                            this.setState({ newPersonData });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="addMiddleName"
                          label="MiddleName"
                          fullWidth
                          value={this.state.newPersonData.middleName ?? ""}
                          onChange={(e) => {
                            let { newPersonData } = this.state;
                            newPersonData.middleName = e.target.value;
                            this.setState({ newPersonData });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControlLabel
                          label="IsMiddleNameShortened"
                          labelPlacement="start"
                          style={{ marginLeft: 0 }}
                          control={
                            <Switch
                              checked={
                                this.state.newPersonData.isMiddleNameShortened
                              }
                              value={
                                this.state.newPersonData.isMiddleNameShortened
                              }
                              onChange={(e) => {
                                let { newPersonData } = this.state;
                                newPersonData.isMiddleNameShortened =
                                  e.target.checked;
                                this.setState({ newPersonData });
                              }}
                              color="primary"
                              inputProps={{ "aria-label": "primary checkbox" }}
                            />
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography component="div">
                          <Grid
                            component="label"
                            container
                            alignItems="center"
                            spacing={1}
                          >
                            <Grid item>Female</Grid>
                            <Grid item>
                              <Switch
                                checked={this.state.newPersonData.gender}
                                value={this.state.newPersonData.gender}
                                onChange={(e) => {
                                  let { newPersonData } = this.state;
                                  newPersonData.gender = e.target.checked;
                                  this.setState({ newPersonData });
                                }}
                                color="primary"
                                inputProps={{
                                  "aria-label": "primary checkbox",
                                }}
                              />
                            </Grid>
                            <Grid item>Male</Grid>
                          </Grid>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            autoOk
                            disableFuture
                            openTo="year"
                            views={["year", "month", "date"]}
                            variant="inline"
                            format="MM/dd/yyyy"
                            id="addBirthDate"
                            label="BirthDate"
                            value={this.state.newPersonData.birthDate}
                            onChange={(e) => {
                              let { newPersonData } = this.state;
                              newPersonData.birthDate = Helpers.getDateWithUTCZero(
                                e
                              );
                              this.setState({ newPersonData });
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            autoOk
                            disableFuture
                            openTo="year"
                            views={["year", "month", "date"]}
                            variant="inline"
                            format="MM/dd/yyyy"
                            id="addDeathDate"
                            label="DeathDate"
                            value={this.state.newPersonData.deathDate}
                            onChange={(e) => {
                              let { newPersonData } = this.state;
                              newPersonData.deathDate = Helpers.getDateWithUTCZero(
                                e
                              );
                              this.setState({ newPersonData });
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <TextField
                          id="addDeathComment"
                          label="DeathComment"
                          fullWidth
                          value={this.state.newPersonData.deathComment ?? ""}
                          onChange={(e) => {
                            let { newPersonData } = this.state;
                            newPersonData.deathComment = e.target.value;
                            this.setState({ newPersonData });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="addComment"
                          label="Comment"
                          fullWidth
                          value={this.state.newPersonData.comment ?? ""}
                          onChange={(e) => {
                            let { newPersonData } = this.state;
                            newPersonData.comment = e.target.value;
                            this.setState({ newPersonData });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="addPhone1"
                          label="Phone1"
                          fullWidth
                          value={this.state.newPersonData.phone1 ?? ""}
                          onChange={(e) => {
                            let { newPersonData } = this.state;
                            newPersonData.phone1 = e.target.value;
                            this.setState({ newPersonData });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="addPhone2"
                          label="Phone2"
                          fullWidth
                          value={this.state.newPersonData.phone2 ?? ""}
                          onChange={(e) => {
                            let { newPersonData } = this.state;
                            newPersonData.phone2 = e.target.value;
                            this.setState({ newPersonData });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="addEmail"
                          label="Email"
                          fullWidth
                          value={this.state.newPersonData.email ?? ""}
                          onChange={(e) => {
                            let { newPersonData } = this.state;
                            newPersonData.email = e.target.value;
                            this.setState({ newPersonData });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <ReferenceEntitySelector
                          componentId="addMemberOfChurchSelector"
                          selectedItem={this.state.newPersonData.memberOfChurch}
                          label="MemberOfChurch"
                          url="Building/GetAllActiveChurchesForReferenceControl"
                          onReferenceEntitySelectEvent={
                            this.handleNewMemberOfChurchSelect
                          }
                          handleException={(error) =>
                            this.handleCatch(error, MessageForAction.Create)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <ReferenceEntitySelector
                          componentId="addFatherSelector"
                          selectedItem={this.state.newPersonData.father}
                          label="Father"
                          url="Person/GetItemsForReferenceControl"
                          onReferenceEntitySelectEvent={
                            this.handleNewFatherSelect
                          }
                          handleException={(error) =>
                            this.handleCatch(error, MessageForAction.Create)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <ReferenceEntitySelector
                          componentId="addMotherSelector"
                          selectedItem={this.state.newPersonData.mother}
                          label="Mother"
                          url="Person/GetItemsForReferenceControl"
                          onReferenceEntitySelectEvent={
                            this.handleNewMotherSelect
                          }
                          handleException={(error) =>
                            this.handleCatch(error, MessageForAction.Create)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControlLabel
                          label="IsActive"
                          labelPlacement="start"
                          style={{ marginLeft: 0 }}
                          control={
                            <Switch
                              checked={this.state.newPersonData.isActive}
                              value={this.state.newPersonData.isActive}
                              onChange={(e) => {
                                let { newPersonData } = this.state;
                                newPersonData.isActive = e.target.checked;
                                this.setState({ newPersonData });
                              }}
                              color="primary"
                              inputProps={{ "aria-label": "primary checkbox" }}
                            />
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        {Helpers.handleError(
                          MessageForAction.Create,
                          this.state.personMessages
                        )}
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      color="primary"
                      onClick={this.addNewPerson.bind(this)}
                    >
                      Add
                    </Button>
                    <Button
                      color="secondary"
                      onClick={this.toggleNewPersonModal.bind(this)}
                    >
                      Cancel
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  open={this.state.isDeletePersonConfirmModalOpen}
                  onClose={this.toggleDeletePersonModal.bind(this)}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  fullWidth={true}
                  maxWidth={"sm"}
                >
                  <DialogTitle id="form-dialog-title">
                    Delete Person
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Delete {this.state.deletePersonData.name} Person?
                    </DialogContentText>
                    {Helpers.handleError(
                      MessageForAction.Delete,
                      this.state.personMessages
                    )}
                  </DialogContent>
                  <DialogActions>
                    <Button
                      color="primary"
                      onClick={this.deletePerson.bind(this)}
                    >
                      Yes, delete
                    </Button>
                    <Button
                      color="secondary"
                      onClick={this.toggleDeletePersonModal.bind(this)}
                    >
                      Cancel
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  open={this.state.isEditPersonModalOpen}
                  onClose={this.toggleEditPersonModal.bind(this)}
                  aria-labelledby="form-dialog-title"
                >
                  <DialogTitle id="form-dialog-title">Edit Person</DialogTitle>
                  <DialogContent>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          autoFocus
                          id="editFirstName"
                          label="FirstName"
                          required
                          fullWidth
                          value={this.state.editPersonData.firstName ?? ""}
                          onChange={(e) => {
                            let { editPersonData } = this.state;
                            editPersonData.firstName = e.target.value;
                            this.setState({ editPersonData });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="editPreferredFirstName"
                          label="PreferredFirstName"
                          fullWidth
                          value={
                            this.state.editPersonData.preferredFirstName ?? ""
                          }
                          onChange={(e) => {
                            let { editPersonData } = this.state;
                            editPersonData.preferredFirstName = e.target.value;
                            this.setState({ editPersonData });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="editLastName"
                          label="LastName"
                          required
                          fullWidth
                          value={this.state.editPersonData.lastName ?? ""}
                          onChange={(e) => {
                            let { editPersonData } = this.state;
                            editPersonData.lastName = e.target.value;
                            this.setState({ editPersonData });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="editMiddleName"
                          label="MiddleName"
                          fullWidth
                          value={this.state.editPersonData.middleName ?? ""}
                          onChange={(e) => {
                            let { editPersonData } = this.state;
                            editPersonData.middleName = e.target.value;
                            this.setState({ editPersonData });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControlLabel
                          label="IsMiddleNameShortened"
                          labelPlacement="start"
                          style={{ marginLeft: 0 }}
                          control={
                            <Switch
                              checked={
                                this.state.editPersonData.isMiddleNameShortened
                              }
                              value={
                                this.state.editPersonData.isMiddleNameShortened
                              }
                              onChange={(e) => {
                                let { editPersonData } = this.state;
                                editPersonData.isMiddleNameShortened =
                                  e.target.checked;
                                this.setState({ editPersonData });
                              }}
                              color="primary"
                              inputProps={{ "aria-label": "primary checkbox" }}
                            />
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography component="div">
                          <Grid
                            component="label"
                            container
                            alignItems="center"
                            spacing={1}
                          >
                            <Grid item>Female</Grid>
                            <Grid item>
                              <Switch
                                checked={this.state.editPersonData.gender}
                                value={this.state.editPersonData.gender}
                                onChange={(e) => {
                                  let { editPersonData } = this.state;
                                  editPersonData.gender = e.target.checked;
                                  this.setState({ editPersonData });
                                }}
                                color="primary"
                                inputProps={{
                                  "aria-label": "primary checkbox",
                                }}
                              />
                            </Grid>
                            <Grid item>Male</Grid>
                          </Grid>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            autoOk
                            disableFuture
                            openTo="year"
                            views={["year", "month", "date"]}
                            variant="inline"
                            format="MM/dd/yyyy"
                            id="editBirthDate"
                            label="BirthDate"
                            value={this.state.editPersonData.birthDate}
                            onChange={(e) => {
                              let { editPersonData } = this.state;
                              editPersonData.birthDate = Helpers.getDateWithUTCZero(
                                e
                              );
                              this.setState({ editPersonData });
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            autoOk
                            disableFuture
                            openTo="year"
                            views={["year", "month", "date"]}
                            variant="inline"
                            format="MM/dd/yyyy"
                            id="editDeathDate"
                            label="DeathDate"
                            value={this.state.editPersonData.deathDate}
                            onChange={(e) => {
                              let { editPersonData } = this.state;
                              editPersonData.deathDate = Helpers.getDateWithUTCZero(
                                e
                              );
                              this.setState({ editPersonData });
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <TextField
                          id="editDeathComment"
                          label="DeathComment"
                          fullWidth
                          value={this.state.editPersonData.deathComment ?? ""}
                          onChange={(e) => {
                            let { editPersonData } = this.state;
                            editPersonData.deathComment = e.target.value;
                            this.setState({ editPersonData });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="editComment"
                          label="Comment"
                          fullWidth
                          value={this.state.editPersonData.comment ?? ""}
                          onChange={(e) => {
                            let { editPersonData } = this.state;
                            editPersonData.comment = e.target.value;
                            this.setState({ editPersonData });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="editPhone1"
                          label="Phone1"
                          fullWidth
                          value={this.state.editPersonData.phone1 ?? ""}
                          onChange={(e) => {
                            let { editPersonData } = this.state;
                            editPersonData.phone1 = e.target.value;
                            this.setState({ editPersonData });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="editPhone2"
                          label="Phone2"
                          fullWidth
                          value={this.state.editPersonData.phone2 ?? ""}
                          onChange={(e) => {
                            let { editPersonData } = this.state;
                            editPersonData.phone2 = e.target.value;
                            this.setState({ editPersonData });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="editEmail"
                          label="Email"
                          fullWidth
                          value={this.state.editPersonData.email ?? ""}
                          onChange={(e) => {
                            let { editPersonData } = this.state;
                            editPersonData.email = e.target.value;
                            this.setState({ editPersonData });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <ReferenceEntitySelector
                          componentId="editMemberOfChurchSelector"
                          selectedItem={
                            this.state.editPersonData.memberOfChurch
                          }
                          label="MemberOfChurch"
                          url="Building/GetAllActiveChurchesForReferenceControl"
                          onReferenceEntitySelectEvent={
                            this.handleEditMemberOfChurchSelect
                          }
                          handleException={(error) =>
                            this.handleCatch(error, MessageForAction.Update)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <ReferenceEntitySelector
                          componentId="editFatherSelector"
                          selectedItem={this.state.editPersonData.father}
                          label="Father"
                          url="Person/GetItemsForReferenceControl"
                          onReferenceEntitySelectEvent={
                            this.handleEditFatherSelect
                          }
                          handleException={(error) =>
                            this.handleCatch(error, MessageForAction.Update)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <ReferenceEntitySelector
                          componentId="editMotherSelector"
                          selectedItem={this.state.editPersonData.mother}
                          label="Mother"
                          url="Person/GetItemsForReferenceControl"
                          onReferenceEntitySelectEvent={
                            this.handleEditMotherSelect
                          }
                          handleException={(error) =>
                            this.handleCatch(error, MessageForAction.Update)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControlLabel
                          label="IsActive"
                          labelPlacement="start"
                          style={{ marginLeft: 0 }}
                          control={
                            <Switch
                              checked={this.state.editPersonData.isActive}
                              value={this.state.editPersonData.isActive}
                              onChange={(e) => {
                                let { editPersonData } = this.state;
                                editPersonData.isActive = e.target.checked;
                                this.setState({ editPersonData });
                              }}
                              color="primary"
                              inputProps={{ "aria-label": "primary checkbox" }}
                            />
                          }
                        />
                      </Grid>
                      {this.state.editPersonData.householdMembers.length > 0 ? (
                        <>
                          <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="center"
                          >
                            <Typography variant="h6" gutterBottom>
                              Household member in:
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            {this.state.editPersonData.householdMembers.map(
                              (member: any) => (
                                <Link
                                  key={member.household.id}
                                  to={`/household/${member.household.id}`}
                                >
                                  {member.household.title}
                                </Link>
                              )
                            )}
                          </Grid>
                        </>
                      ) : null}
                      {this.state.editPersonData.primaryHouseholds.length >
                      0 ? (
                        <>
                          <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="center"
                          >
                            <Typography variant="h6" gutterBottom>
                              Primary person in households:
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            {this.state.editPersonData.primaryHouseholds.map(
                              (primaryHousehold: any) => (
                                <Link
                                  key={primaryHousehold.id}
                                  to={`/household/${primaryHousehold.id}`}
                                >
                                  {primaryHousehold.title}
                                </Link>
                              )
                            )}
                          </Grid>
                        </>
                      ) : null}
                      {this.state.editPersonData.secondaryHouseholds.length >
                      0 ? (
                        <>
                          <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="center"
                          >
                            <Typography variant="h6" gutterBottom>
                              Secondary person in households:
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            {this.state.editPersonData.secondaryHouseholds.map(
                              (secondaryHousehold: any) => (
                                <Link
                                  key={secondaryHousehold.id}
                                  to={`/household/${secondaryHousehold.id}`}
                                >
                                  {secondaryHousehold.title}
                                </Link>
                              )
                            )}
                          </Grid>
                        </>
                      ) : null}
                      <Grid item xs={12}>
                        {Helpers.handleError(
                          MessageForAction.Update,
                          this.state.personMessages
                        )}
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      color="primary"
                      onClick={this.updatePerson.bind(this)}
                    >
                      Update
                    </Button>
                    <Button
                      color="secondary"
                      onClick={this.toggleEditPersonModal.bind(this)}
                    >
                      Cancel
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  open={this.state.isErrorDialogOpen}
                  onClose={() => {
                    this.setState({
                      isErrorDialogOpen: false,
                      personMessages: [],
                    });
                  }}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  fullWidth={true}
                  maxWidth={"sm"}
                >
                  <DialogTitle id="form-dialog-title">
                    Error occurred
                  </DialogTitle>
                  <DialogContent>
                    {Helpers.handleError(
                      MessageForAction.Read,
                      this.state.personMessages
                    )}
                  </DialogContent>
                  <DialogActions>
                    <Button
                      color="secondary"
                      onClick={() => {
                        this.setState({
                          isErrorDialogOpen: false,
                          personMessages: [],
                        });
                      }}
                    >
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>
                <Tooltip title="Reload">
                  <IconButton onClick={this.refreshTableData.bind(this)}>
                    <Refresh className={classes.block} color="inherit" />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Action</TableCell>
                <TableCell>FirstName</TableCell>
                <TableCell>PreferredFirstName</TableCell>
                <TableCell>LastName</TableCell>
                <TableCell>Gender</TableCell>
                <TableCell>BirthDate</TableCell>
                <TableCell>DeathDate</TableCell>
                <TableCell>Phone1</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>MemberOfChurch</TableCell>
                <TableCell>Father</TableCell>
                <TableCell>Mother</TableCell>
                <TableCell>IsActive</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{persons}</TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10]}
                  count={this.state.personsCount}
                  rowsPerPage={10}
                  page={this.state.pageNumber}
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    native: true,
                  }}
                  onChangePage={this.handleChangePage.bind(this)}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Paper>
    );
  }

  private handleCatch(error: any, messageForAction: MessageForAction) {
    let { personMessages } = this.state;
    if (error.toString().includes("Network Error")) {
      personMessages.push(
        new MessageInfo(
          messageForAction,
          "Server unavailable, try again later",
          503
        )
      );
    } else if (
      error.toString().includes("Request failed with status code 401")
    ) {
      this.authService.login();
    } else if (
      error.toString().includes("Request failed with status code 500")
    ) {
      personMessages.push(
        new MessageInfo(messageForAction, error.toString(), 500)
      );
    } else if (error.response.data.hasOwnProperty("errors")) {
      error.response.data.errors.forEach((errorItem: any) => {
        personMessages.push(
          new MessageInfo(
            messageForAction,
            errorItem.message,
            error.response.status
          )
        );
      });
    } else if (error.response.data.hasOwnProperty("Message")) {
      personMessages.push(
        new MessageInfo(
          messageForAction,
          error.response.data.Message,
          error.response.status
        )
      );
    } else {
      personMessages.push(
        new MessageInfo(messageForAction, error, error.response.status)
      );
    }
    this.setState({ personMessages });
  }
}

export default withStyles(styles)(Person);
