const development = {
  authority: "http://localhost:5000",
  clientId: "DirectoryAppWebClient",
  clientRoot: "http://localhost:3000/",
  clientScope: "openid profile IdentityServerApi",
  apiRoot: "http://localhost:5000/api/"
};

const production = {
  authority: "https://cbdata.weatec.net:88/",
  clientId: "DirectoryAppWebClient",
  clientRoot: "https://cbdata.weatec.net/",
  clientScope: "openid profile IdentityServerApi",
  apiRoot: "https://cbdata.weatec.net:88/api/"
};

export const IdentityConstants = process.env.NODE_ENV === "development" ? development : production;