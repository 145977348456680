import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  AppBar,
  Toolbar,
  Paper,
  Grid,
  Button,
  TextField,
  Tooltip,
  IconButton,
  Switch,
  TableFooter,
  TablePagination,
  FormControlLabel,
} from "@material-ui/core";
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import { Delete, Search, Refresh } from "@material-ui/icons";
import Axios from "axios";
import { IdentityConstants } from "../../helpers/IdentityConstants";
import { AuthService } from "../../services/AuthService";
import { MessageInfo } from "../MessageInfo";
import { MessageForAction } from "../MessageForAction";
import Helpers from "../../helpers/Helpers";
import { IHouseholdEditSuggestionState } from "./componentStates/IHouseholdEditSuggestionState";
import { IHouseholdEditSuggestion } from "./dataTransferableObjects/IHouseholdEditSuggestion";
import { Link } from "react-router-dom";
import { AppConstants } from "../../helpers/AppConstants";
import _ from "lodash";

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      margin: "auto",
      overflow: "hidden",
    },
    searchBar: {
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    },
    searchInput: {
      fontSize: theme.typography.fontSize,
    },
    block: {
      display: "block",
    },
  });

export interface IHouseholdEditSuggestionComponentProps
  extends WithStyles<typeof styles> {}

class HouseholdEditSuggestion extends React.Component<
  IHouseholdEditSuggestionComponentProps,
  IHouseholdEditSuggestionState
> {
  private authService: AuthService;
  constructor(props: IHouseholdEditSuggestionComponentProps) {
    super(props);
    this.authService = new AuthService();
    this.state = {
      householdEditSuggestions: [],
      deleteHouseholdEditSuggestionData: {
        id: "",
        deleteText: "",
      },
      includeInActive: false,
      isDeleteHouseholdEditSuggestionConfirmModalOpen: false,
      pageNumber: 0,
      householdEditSuggestionsCount: 0,
      searchText: "",
      householdEditSuggestionMessages: [],
      isErrorDialogOpen: false,
    };
    this.debounceRefreshHouseholdEditSuggestions = _.debounce(
      this.debounceRefreshHouseholdEditSuggestions,
      1000
    );
  }

  debounceRefreshHouseholdEditSuggestions() {
    this.refreshHouseholdEditSuggestions();
  }

  componentDidMount() {
    this.refreshTableData();
  }

  refreshTableData() {
    this.refreshHouseholdEditSuggestions();
  }

  refreshHouseholdEditSuggestions() {
    this.setState({ householdEditSuggestionMessages: [] });
    this.authService.getUser().then((user) => {
      if (user && user.access_token) {
        const headers = Helpers.getHeaders(user.access_token);
        let searchDTO = {
          searchText: this.state.searchText,
          pageNumber: this.state.pageNumber,
          pageSize: AppConstants.TablePageSize,
          onlyIsActive: !this.state.includeInActive,
        };
        Axios.post(
          `${IdentityConstants.apiRoot}HouseholdEditSuggestion/GetItems`,
          searchDTO,
          {
            headers,
          }
        )
          .then((response) => {
            this.setState({
              householdEditSuggestions: response.data.items,
              householdEditSuggestionsCount: response.data.searchItemsCount,
            });
          })
          .catch((error) => {
            this.handleCatch(error, MessageForAction.Read);
            this.setState({ isErrorDialogOpen: true });
          });
      }
    });
  }

  removeMessages(actionType: MessageForAction) {
    let filteredMessages = this.state.householdEditSuggestionMessages.filter(
      (m) => m.messageForAction !== actionType
    );
    this.setState({ householdEditSuggestionMessages: filteredMessages });
  }

  toggleDeleteHouseholdEditSuggestionModal() {
    this.setState({
      isDeleteHouseholdEditSuggestionConfirmModalOpen: !this.state
        .isDeleteHouseholdEditSuggestionConfirmModalOpen,
    });
    this.removeMessages(MessageForAction.Delete);
  }

  deleteHouseholdEditSuggestion() {
    this.removeMessages(MessageForAction.Delete);
    this.authService.getUser().then((user) => {
      if (user && user.access_token) {
        const headers = Helpers.getHeaders(user.access_token);
        Axios.delete(
          `${IdentityConstants.apiRoot}HouseholdEditSuggestion/${this.state.deleteHouseholdEditSuggestionData.id}`,
          { headers }
        )
          .then(() => {
            this.refreshTableData();
            this.setState({
              isDeleteHouseholdEditSuggestionConfirmModalOpen: false,
              deleteHouseholdEditSuggestionData: { id: "", deleteText: "" },
            });
          })
          .catch((error) => {
            this.handleCatch(error, MessageForAction.Delete);
          });
      }
    });
  }

  deleteHouseholdEditSuggestionSetState(id: any, deleteText: any) {
    let { deleteHouseholdEditSuggestionData } = this.state;
    deleteHouseholdEditSuggestionData.id = id;
    deleteHouseholdEditSuggestionData.deleteText = deleteText;
    this.setState({
      deleteHouseholdEditSuggestionData,
      isDeleteHouseholdEditSuggestionConfirmModalOpen: !this.state
        .isDeleteHouseholdEditSuggestionConfirmModalOpen,
    });
  }

  handleChangePage(
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) {
    this.setState({ pageNumber: newPage });
    this.debounceRefreshHouseholdEditSuggestions();
  }

  public render() {
    const { classes } = this.props;
    let householdEditSuggestions = this.state.householdEditSuggestions.map(
      (householdEditSuggestion) => {
        return (
          <TableRow key={householdEditSuggestion.id}>
            <TableCell>
              <Tooltip title="Delete">
                <IconButton
                  onClick={this.deleteHouseholdEditSuggestionSetState.bind(
                    this,
                    householdEditSuggestion.id,
                    `Delete HouseholdEditSuggestion for ${householdEditSuggestion.household?.title} suggested by ${householdEditSuggestion.username}`
                  )}
                >
                  <Delete />
                </IconButton>
              </Tooltip>
            </TableCell>
            <TableCell>{householdEditSuggestion.username}</TableCell>
            <TableCell>{householdEditSuggestion.name}</TableCell>
            <TableCell>{householdEditSuggestion.email}</TableCell>
            <TableCell>{householdEditSuggestion.phone}</TableCell>
            <TableCell>
              <Link to={`/household/${householdEditSuggestion.household?.id}`}>
                {householdEditSuggestion.household?.title}
              </Link>
            </TableCell>
            <TableCell>
              {householdEditSuggestion.suggestionTime === null
                ? null
                : new Date(
                    householdEditSuggestion.suggestionTime
                  ).toDateString()}
            </TableCell>
            <TableCell>{householdEditSuggestion.suggestionText}</TableCell>
            <TableCell>
              {householdEditSuggestion.shouldPublishInHomeMessenger
                ? "Yes"
                : "No"}
            </TableCell>
            <TableCell>
              <Switch
                checked={householdEditSuggestion.isActive}
                onChange={(e, checked) => {
                  this.handleIsActiveChange(householdEditSuggestion, checked);
                }}
                color="primary"
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            </TableCell>
          </TableRow>
        );
      }
    );
    return (
      <Paper className={classes.paper}>
        <AppBar
          className={classes.searchBar}
          position="static"
          color="default"
          elevation={0}
        >
          <Toolbar>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <Search className={classes.block} color="inherit" />
              </Grid>
              <Grid item xs>
                <TextField
                  fullWidth
                  placeholder="Search by suggestion text, primary and secondary person"
                  value={this.state.searchText}
                  onChange={(e) => {
                    this.setState({ searchText: e.target.value });
                  }}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      this.setState({ pageNumber: 0 });
                      this.refreshTableData();
                      e.preventDefault();
                    }
                  }}
                  InputProps={{
                    disableUnderline: true,
                    className: classes.searchInput,
                  }}
                />
              </Grid>
              <Grid item>
                <Dialog
                  open={
                    this.state.isDeleteHouseholdEditSuggestionConfirmModalOpen
                  }
                  onClose={this.toggleDeleteHouseholdEditSuggestionModal.bind(
                    this
                  )}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  fullWidth={true}
                  maxWidth={"sm"}
                >
                  <DialogTitle id="form-dialog-title">
                    Delete HouseholdEditSuggestion
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      {this.state.deleteHouseholdEditSuggestionData.deleteText}
                    </DialogContentText>
                    {Helpers.handleError(
                      MessageForAction.Delete,
                      this.state.householdEditSuggestionMessages
                    )}
                  </DialogContent>
                  <DialogActions>
                    <Button
                      color="primary"
                      onClick={this.deleteHouseholdEditSuggestion.bind(this)}
                    >
                      Yes, delete
                    </Button>
                    <Button
                      color="secondary"
                      onClick={this.toggleDeleteHouseholdEditSuggestionModal.bind(
                        this
                      )}
                    >
                      Cancel
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  open={this.state.isErrorDialogOpen}
                  onClose={() => {
                    this.setState({
                      isErrorDialogOpen: false,
                      householdEditSuggestionMessages: [],
                    });
                  }}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  fullWidth={true}
                  maxWidth={"sm"}
                >
                  <DialogTitle id="form-dialog-title">
                    Error occurred
                  </DialogTitle>
                  <DialogContent>
                    {Helpers.handleError(
                      MessageForAction.Read,
                      this.state.householdEditSuggestionMessages
                    )}
                  </DialogContent>
                  <DialogActions>
                    <Button
                      color="secondary"
                      onClick={() => {
                        this.setState({
                          isErrorDialogOpen: false,
                          householdEditSuggestionMessages: [],
                        });
                      }}
                    >
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  open={this.state.isErrorDialogOpen}
                  onClose={() => {
                    this.setState({
                      isErrorDialogOpen: false,
                      householdEditSuggestionMessages: [],
                    });
                  }}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  fullWidth={true}
                  maxWidth={"sm"}
                >
                  <DialogTitle id="form-dialog-title">
                    Error occurred
                  </DialogTitle>
                  <DialogContent>
                    {Helpers.handleError(
                      MessageForAction.Update,
                      this.state.householdEditSuggestionMessages
                    )}
                  </DialogContent>
                  <DialogActions>
                    <Button
                      color="secondary"
                      onClick={() => {
                        this.setState({
                          isErrorDialogOpen: false,
                          householdEditSuggestionMessages: [],
                        });
                      }}
                    >
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>
                <FormControlLabel
                  label="Include in-active "
                  labelPlacement="start"
                  style={{ marginLeft: 0 }}
                  control={
                    <Switch
                      checked={this.state.includeInActive}
                      onChange={(e, includeInActive) => {
                        this.handleIncludeInActiveChange(includeInActive);
                      }}
                      color="primary"
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  }
                />
                <Tooltip title="Reload">
                  <IconButton onClick={this.refreshTableData.bind(this)}>
                    <Refresh className={classes.block} color="inherit" />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Action</TableCell>
                <TableCell>Username</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell style={{ width: 150 }}>Phone Number</TableCell>
                <TableCell style={{ width: 250 }}>Household</TableCell>
                <TableCell style={{ width: 150 }}>SuggestionTime</TableCell>
                <TableCell>SuggestionText</TableCell>
                <TableCell>Publish In Home Messenger</TableCell>
                <TableCell>IsActive</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{householdEditSuggestions}</TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10]}
                  count={this.state.householdEditSuggestionsCount}
                  rowsPerPage={10}
                  page={this.state.pageNumber}
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    native: true,
                  }}
                  onChangePage={this.handleChangePage.bind(this)}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Paper>
    );
  }

  private handleIsActiveChange(
    householdEditSuggestion: IHouseholdEditSuggestion,
    checked: boolean
  ) {
    this.removeMessages(MessageForAction.Update);
    this.authService.getUser().then((user) => {
      if (user && user.access_token) {
        const headers = Helpers.getHeaders(user.access_token);
        Axios.put(
          `${IdentityConstants.apiRoot}HouseholdEditSuggestion/${householdEditSuggestion.id}`,
          {
            username: householdEditSuggestion.username,
            name: householdEditSuggestion.name,
            phone: householdEditSuggestion.phone,
            email: householdEditSuggestion.email,
            shouldPublishInHomeMessenger:
              householdEditSuggestion.shouldPublishInHomeMessenger,
            suggestionTime: householdEditSuggestion.suggestionTime,
            householdId: householdEditSuggestion.household?.id,
            suggestionText: householdEditSuggestion.suggestionText,
            isActive: checked,
          },
          { headers }
        )
          .then((response) => {
            this.refreshHouseholdEditSuggestions();
          })
          .catch((error) => {
            this.handleCatch(error, MessageForAction.Update);
          });
      }
    });
  }

  private handleIncludeInActiveChange(includeInActive: boolean) {
    this.setState({ includeInActive: includeInActive });
    this.refreshHouseholdEditSuggestions();
  }

  private handleCatch(error: any, messageForAction: MessageForAction) {
    let { householdEditSuggestionMessages } = this.state;
    this.setState({ isErrorDialogOpen: true });
    if (error.toString().includes("Network Error")) {
      householdEditSuggestionMessages.push(
        new MessageInfo(
          messageForAction,
          "Server unavailable, try again later",
          503
        )
      );
    } else if (
      error.toString().includes("Request failed with status code 401")
    ) {
      this.authService.login();
    } else if (
      error.toString().includes("Request failed with status code 500")
    ) {
      householdEditSuggestionMessages.push(
        new MessageInfo(messageForAction, error.toString(), 500)
      );
    } else if (error.response.data.hasOwnProperty("errors")) {
      error.response.data.errors.forEach((errorItem: any) => {
        householdEditSuggestionMessages.push(
          new MessageInfo(
            messageForAction,
            errorItem.message,
            error.response.status
          )
        );
      });
    } else if (error.response.data.hasOwnProperty("Message")) {
      householdEditSuggestionMessages.push(
        new MessageInfo(
          messageForAction,
          error.response.data.Message,
          error.response.status
        )
      );
    } else {
      householdEditSuggestionMessages.push(
        new MessageInfo(messageForAction, error, error.response.status)
      );
    }
    this.setState({ householdEditSuggestionMessages });
  }
}

export default withStyles(styles)(HouseholdEditSuggestion);
