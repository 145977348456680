import * as React from "react";
import { AuthService } from "../services/AuthService";
import { HealthCheckService } from "../services/HealthCheckService";
import ServerNotAvailable from "./ServerNotAvailable";
import HomePage from "./HomePage";

export default class AppContent extends React.Component<any, any, any> {
  public authService: AuthService;
  public healthCheckService: HealthCheckService;

  private shouldCancel: boolean;

  constructor(props: any) {
    super(props);

    this.authService = new AuthService();
    this.healthCheckService = new HealthCheckService();
    this.state = { user: {}, api: {}, isServerAvailable: false };
    this.shouldCancel = false;
  }

  public componentDidMount() {
    this.healthCheckService
      .healthCheck()
      .then((data) => {
        if (data.status === 200) {
          this.setState({ isServerAvailable: true });
          this.authService.getUser().then((user) => {
            if (!user) this.login();

            if (!this.shouldCancel) this.setState({ user });
          });
        }
      })
      .catch((error) => {
        console.log("componentDidMountCatch:" + error);
      });
  }

  public login = () => {
    this.authService.login();
  };

  public componentWillUnmount() {
    this.shouldCancel = true;
  }

  public renewToken = () => {
    this.authService
      .renewToken()
      .then((user) => {
        if (!this.shouldCancel) {
          this.setState({ user });
        }
      })
      .catch((error) => {
        console.log("renew token error: " + error);
      });
  };

  public logout = () => {
    this.authService.logout();
  };

  public render() {
    if (!this.state.isServerAvailable || this.state.user === null) {
      return <ServerNotAvailable login={this.login} />;
    } else {
      return (
        <div style={{ textAlign: "center" }}>
          <HomePage logout={this.logout} />
        </div>
      );
    }
  }
}
