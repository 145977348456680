import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  AppBar,
  Toolbar,
  Paper,
  Grid,
  Button,
  TextField,
  Tooltip,
  IconButton,
  Switch,
  FormControlLabel,
  TableFooter,
  TablePagination,
} from "@material-ui/core";
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import { Delete, Edit, Add, Search, Refresh } from "@material-ui/icons";
import Axios from "axios";
import { IdentityConstants } from "../../helpers/IdentityConstants";
import { AuthService } from "../../services/AuthService";
import { MessageInfo } from "../MessageInfo";
import { MessageForAction } from "../MessageForAction";
import { ReferenceEntitySelector } from "../ReferenceEntitySelector";
import Helpers from "../../helpers/Helpers";
import { IHouseholdMember } from "./dataTransferableObjects/IHouseholdMember";
import { IHouseholdMemberSave } from "./dataTransferableObjects/IHouseholdMemberSave";
import { IHouseholdMemberState } from "./componentStates/IHouseholdMemberState";
import { AppConstants } from "../../helpers/AppConstants";
import _ from "lodash";

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      margin: "auto",
      overflow: "hidden",
    },
    searchBar: {
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    },
    searchInput: {
      fontSize: theme.typography.fontSize,
    },
    block: {
      display: "block",
    },
  });

export interface IHouseholdMemberComponentProps
  extends WithStyles<typeof styles> {}

class HouseholdMember extends React.Component<
  IHouseholdMemberComponentProps,
  IHouseholdMemberState
> {
  private authService: AuthService;
  constructor(props: IHouseholdMemberComponentProps) {
    super(props);
    this.authService = new AuthService();
    this.state = {
      householdMembers: [],
      newHouseholdMemberData: this.resetHouseholdMember(),
      editHouseholdMemberData: this.resetHouseholdMember(),
      deleteHouseholdMemberData: {
        id: "",
        deleteMessage: "",
      },
      isNewHouseholdMemberModalOpen: false,
      isEditHouseholdMemberModalOpen: false,
      isDeleteHouseholdMemberConfirmModalOpen: false,
      pageNumber: 0,
      householdMembersCount: 0,
      searchText: "",
      householdMemberMessages: [],
      isErrorDialogOpen: false,
    };
    this.debounceRefreshHouseholdMembers = _.debounce(
      this.debounceRefreshHouseholdMembers,
      1000
    );
  }

  debounceRefreshHouseholdMembers() {
    this.refreshHouseholdMembers();
  }

  resetHouseholdMember() {
    let data: IHouseholdMember = {
      id: "",
      comment: "",
      person: null,
      household: null,
      isActive: true,
    };
    return data;
  }

  componentDidMount() {
    this.refreshTableData();
  }

  refreshTableData() {
    this.refreshHouseholdMembers();
  }

  refreshHouseholdMembers() {
    this.setState({ householdMemberMessages: [] });
    this.authService.getUser().then((user) => {
      if (user && user.access_token) {
        const headers = Helpers.getHeaders(user.access_token);
        let searchDTO = {
          searchText: this.state.searchText,
          pageNumber: this.state.pageNumber,
          pageSize: AppConstants.TablePageSize,
        };
        Axios.post(
          `${IdentityConstants.apiRoot}HouseholdMember/GetItems`,
          searchDTO,
          { headers }
        )
          .then((response) => {
            this.setState({
              householdMembers: response.data.items,
              householdMembersCount: response.data.searchItemsCount,
            });
          })
          .catch((error) => {
            this.handleCatch(error, MessageForAction.Read);
            this.setState({ isErrorDialogOpen: true });
          });
      }
    });
  }

  removeMessages(actionType: MessageForAction) {
    let filteredMessages = this.state.householdMemberMessages.filter(
      (m) => m.messageForAction !== actionType
    );
    this.setState({ householdMemberMessages: filteredMessages });
  }

  handleNewPersonSelect = (selectedPerson: any) => {
    let { newHouseholdMemberData } = this.state;
    newHouseholdMemberData.person = selectedPerson;
    this.setState({ newHouseholdMemberData });
  };

  handleNewHouseholdSelect = (selectedHousehold: any) => {
    let { newHouseholdMemberData } = this.state;
    newHouseholdMemberData.household = selectedHousehold;
    this.setState({ newHouseholdMemberData });
  };

  handleEditPersonSelect = (selectedPerson: any) => {
    let { editHouseholdMemberData } = this.state;
    editHouseholdMemberData.person = selectedPerson;
    this.setState({ editHouseholdMemberData });
  };

  handleEditHouseholdSelect = (selectedHousehold: any) => {
    let { editHouseholdMemberData } = this.state;
    editHouseholdMemberData.household = selectedHousehold;
    this.setState({ editHouseholdMemberData });
  };

  toggleNewHouseholdMemberModal() {
    this.setState({
      isNewHouseholdMemberModalOpen: !this.state.isNewHouseholdMemberModalOpen,
    });
  }

  toggleEditHouseholdMemberModal() {
    this.setState({
      isEditHouseholdMemberModalOpen: !this.state
        .isEditHouseholdMemberModalOpen,
    });
    this.removeMessages(MessageForAction.Update);
  }

  toggleDeleteHouseholdMemberModal() {
    this.setState({
      isDeleteHouseholdMemberConfirmModalOpen: !this.state
        .isDeleteHouseholdMemberConfirmModalOpen,
    });
    this.removeMessages(MessageForAction.Delete);
  }

  addNewHouseholdMember() {
    this.removeMessages(MessageForAction.Create);
    this.authService.getUser().then((user) => {
      if (user && user.access_token) {
        const headers = Helpers.getHeaders(user.access_token);
        Axios.post(
          `${IdentityConstants.apiRoot}HouseholdMember`,
          this.CopyFrom(this.state.newHouseholdMemberData),
          { headers }
        )
          .then((_) => {
            this.refreshTableData();
            this.setState({
              isNewHouseholdMemberModalOpen: false,
              newHouseholdMemberData: this.resetHouseholdMember(),
            });
          })
          .catch((error) => {
            this.handleCatch(error, MessageForAction.Create);
          });
      }
    });
  }

  CopyFrom(householdMember: IHouseholdMember): IHouseholdMemberSave {
    return {
      comment: householdMember.comment,
      personId: householdMember.person?.id,
      householdId: householdMember.household?.id,
      isActive: householdMember.isActive,
    };
  }

  updateHouseholdMember() {
    this.removeMessages(MessageForAction.Update);
    this.authService.getUser().then((user) => {
      if (user && user.access_token) {
        const headers = Helpers.getHeaders(user.access_token);
        Axios.put(
          `${IdentityConstants.apiRoot}HouseholdMember/${this.state.editHouseholdMemberData.id}`,
          this.CopyFrom(this.state.editHouseholdMemberData),
          { headers }
        )
          .then((response) => {
            this.refreshHouseholdMembers();
            this.setState({
              isEditHouseholdMemberModalOpen: false,
              editHouseholdMemberData: this.resetHouseholdMember(),
            });
          })
          .catch((error) => {
            this.handleCatch(error, MessageForAction.Update);
          });
      }
    });
  }

  deleteHouseholdMember() {
    this.removeMessages(MessageForAction.Delete);
    this.authService.getUser().then((user) => {
      if (user && user.access_token) {
        const headers = Helpers.getHeaders(user.access_token);
        Axios.delete(
          `${IdentityConstants.apiRoot}HouseholdMember/${this.state.deleteHouseholdMemberData.id}`,
          { headers }
        )
          .then(() => {
            this.refreshTableData();
            this.setState({
              isDeleteHouseholdMemberConfirmModalOpen: false,
              deleteHouseholdMemberData: {
                id: "",
                deleteMessage: "",
              },
            });
          })
          .catch((error) => {
            this.handleCatch(error, MessageForAction.Delete);
          });
      }
    });
  }

  editHouseholdMemberSetState(
    id: string,
    comment: string,
    person: any,
    household: any,
    isActive: boolean
  ) {
    let { editHouseholdMemberData } = this.state;
    editHouseholdMemberData.id = id;
    editHouseholdMemberData.comment = comment ?? "";
    editHouseholdMemberData.person = person;
    editHouseholdMemberData.household = household;
    editHouseholdMemberData.isActive = isActive;
    this.setState({
      editHouseholdMemberData,
      isEditHouseholdMemberModalOpen: !this.state
        .isEditHouseholdMemberModalOpen,
    });
  }

  deleteHouseholdMemberSetState(id: any, name: any) {
    let { deleteHouseholdMemberData } = this.state;
    deleteHouseholdMemberData.id = id;
    deleteHouseholdMemberData.deleteMessage = name;
    this.setState({
      deleteHouseholdMemberData,
      isDeleteHouseholdMemberConfirmModalOpen: !this.state
        .isDeleteHouseholdMemberConfirmModalOpen,
    });
  }

  handleChangePage(
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) {
    this.setState({ pageNumber: newPage });
    this.debounceRefreshHouseholdMembers();
  }

  public render() {
    const { classes } = this.props;
    let householdMembers = this.state.householdMembers.map(
      (householdMember) => {
        return (
          <TableRow key={householdMember.id}>
            <TableCell>
              <Tooltip title="Edit">
                <IconButton
                  onClick={this.editHouseholdMemberSetState.bind(
                    this,
                    householdMember.id,
                    householdMember.comment,
                    householdMember.person,
                    householdMember.household,
                    householdMember.isActive
                  )}
                >
                  <Edit style={{ marginRight: 2 }} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete">
                <IconButton
                  onClick={this.deleteHouseholdMemberSetState.bind(
                    this,
                    householdMember.id,
                    `${householdMember.person?.title} ${householdMember.household?.number}`
                  )}
                >
                  <Delete />
                </IconButton>
              </Tooltip>
            </TableCell>
            <TableCell>{householdMember.person?.title}</TableCell>
            <TableCell>
              {householdMember.person.birthDate === null
                ? null
                : new Date(householdMember.person.birthDate).toDateString()}
            </TableCell>
            <TableCell>{householdMember.household?.title}</TableCell>
            <TableCell>{householdMember.comment}</TableCell>
            <TableCell>
              <Switch
                checked={householdMember.isActive}
                color="primary"
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            </TableCell>
          </TableRow>
        );
      }
    );
    return (
      <Paper className={classes.paper}>
        <AppBar
          className={classes.searchBar}
          position="static"
          color="default"
          elevation={0}
        >
          <Toolbar>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <Search className={classes.block} color="inherit" />
              </Grid>
              <Grid item xs>
                <TextField
                  fullWidth
                  placeholder="Search by Person, Household PrimaryPerson or SecondaryPerson's first and last name or comment"
                  value={this.state.searchText}
                  onChange={(e) => {
                    this.setState({ searchText: e.target.value });
                  }}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      this.setState({ pageNumber: 0 });
                      this.refreshTableData();
                      e.preventDefault();
                    }
                  }}
                  InputProps={{
                    disableUnderline: true,
                    className: classes.searchInput,
                  }}
                />
              </Grid>
              <Grid item>
                <Tooltip title="Add">
                  <IconButton
                    onClick={this.toggleNewHouseholdMemberModal.bind(this)}
                  >
                    <Add />
                  </IconButton>
                </Tooltip>
                <Dialog
                  open={this.state.isNewHouseholdMemberModalOpen}
                  onClose={this.toggleNewHouseholdMemberModal.bind(this)}
                  aria-labelledby="form-dialog-title"
                >
                  <DialogTitle id="form-dialog-title">
                    Add HouseholdMember
                  </DialogTitle>
                  <DialogContent>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6}>
                        <ReferenceEntitySelector
                          componentId="addPersonSelector"
                          selectedItem={
                            this.state.newHouseholdMemberData.person
                          }
                          label="Person"
                          url="Person/GetItemsForReferenceControl"
                          required={true}
                          onReferenceEntitySelectEvent={
                            this.handleNewPersonSelect
                          }
                          handleException={(error) =>
                            this.handleCatch(error, MessageForAction.Create)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <ReferenceEntitySelector
                          componentId="addHouseholdSelector"
                          selectedItem={
                            this.state.newHouseholdMemberData.household
                          }
                          label="Household"
                          url="Household/GetItemsForReferenceControl"
                          required={true}
                          onReferenceEntitySelectEvent={
                            this.handleNewHouseholdSelect
                          }
                          handleException={(error) =>
                            this.handleCatch(error, MessageForAction.Create)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControlLabel
                          label="IsActive"
                          labelPlacement="start"
                          style={{ marginLeft: 0 }}
                          control={
                            <Switch
                              checked={
                                this.state.newHouseholdMemberData.isActive
                              }
                              value={this.state.newHouseholdMemberData.isActive}
                              onChange={(e) => {
                                let { newHouseholdMemberData } = this.state;
                                newHouseholdMemberData.isActive =
                                  e.target.checked;
                                this.setState({ newHouseholdMemberData });
                              }}
                              color="primary"
                              inputProps={{ "aria-label": "primary checkbox" }}
                            />
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          autoFocus
                          id="newComment"
                          label="Comment"
                          multiline
                          rows="2"
                          fullWidth
                          value={this.state.newHouseholdMemberData.comment}
                          onChange={(e) => {
                            let { newHouseholdMemberData } = this.state;
                            newHouseholdMemberData.comment = e.target.value;
                            this.setState({ newHouseholdMemberData });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        {Helpers.handleError(
                          MessageForAction.Create,
                          this.state.householdMemberMessages
                        )}
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      color="primary"
                      onClick={this.addNewHouseholdMember.bind(this)}
                    >
                      Add
                    </Button>
                    <Button
                      color="secondary"
                      onClick={this.toggleNewHouseholdMemberModal.bind(this)}
                    >
                      Cancel
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  open={this.state.isDeleteHouseholdMemberConfirmModalOpen}
                  onClose={this.toggleDeleteHouseholdMemberModal.bind(this)}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  fullWidth={true}
                  maxWidth={"sm"}
                >
                  <DialogTitle id="form-dialog-title">
                    Delete HouseholdMember
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Delete{" "}
                      {this.state.deleteHouseholdMemberData.deleteMessage}{" "}
                      HouseholdMember?
                    </DialogContentText>
                    {Helpers.handleError(
                      MessageForAction.Delete,
                      this.state.householdMemberMessages
                    )}
                  </DialogContent>
                  <DialogActions>
                    <Button
                      color="primary"
                      onClick={this.deleteHouseholdMember.bind(this)}
                    >
                      Yes, delete
                    </Button>
                    <Button
                      color="secondary"
                      onClick={this.toggleDeleteHouseholdMemberModal.bind(this)}
                    >
                      Cancel
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  open={this.state.isEditHouseholdMemberModalOpen}
                  onClose={this.toggleEditHouseholdMemberModal.bind(this)}
                  aria-labelledby="form-dialog-title"
                >
                  <DialogTitle id="form-dialog-title">
                    Edit HouseholdMember
                  </DialogTitle>
                  <DialogContent>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6}>
                        <ReferenceEntitySelector
                          componentId="editPersonSelector"
                          selectedItem={
                            this.state.editHouseholdMemberData.person
                          }
                          label="Person"
                          url="Person/GetItemsForReferenceControl"
                          required={true}
                          onReferenceEntitySelectEvent={
                            this.handleEditPersonSelect
                          }
                          handleException={(error) =>
                            this.handleCatch(error, MessageForAction.Update)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <ReferenceEntitySelector
                          componentId="editHouseholdSelector"
                          selectedItem={
                            this.state.editHouseholdMemberData.household
                          }
                          label="Household"
                          url="Household/GetItemsForReferenceControl"
                          required={true}
                          onReferenceEntitySelectEvent={
                            this.handleEditHouseholdSelect
                          }
                          handleException={(error) =>
                            this.handleCatch(error, MessageForAction.Update)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControlLabel
                          label="IsActive"
                          labelPlacement="start"
                          style={{ marginLeft: 0 }}
                          control={
                            <Switch
                              checked={
                                this.state.editHouseholdMemberData.isActive
                              }
                              value={
                                this.state.editHouseholdMemberData.isActive
                              }
                              onChange={(e) => {
                                let { editHouseholdMemberData } = this.state;
                                editHouseholdMemberData.isActive =
                                  e.target.checked;
                                this.setState({ editHouseholdMemberData });
                              }}
                              color="primary"
                              inputProps={{ "aria-label": "primary checkbox" }}
                            />
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          autoFocus
                          id="editComment"
                          label="Comment"
                          multiline
                          rows="2"
                          fullWidth
                          value={this.state.editHouseholdMemberData.comment}
                          onChange={(e) => {
                            let { editHouseholdMemberData } = this.state;
                            editHouseholdMemberData.comment = e.target.value;
                            this.setState({ editHouseholdMemberData });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        {Helpers.handleError(
                          MessageForAction.Update,
                          this.state.householdMemberMessages
                        )}
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      color="primary"
                      onClick={this.updateHouseholdMember.bind(this)}
                    >
                      Update
                    </Button>
                    <Button
                      color="secondary"
                      onClick={this.toggleEditHouseholdMemberModal.bind(this)}
                    >
                      Cancel
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  open={this.state.isErrorDialogOpen}
                  onClose={() => {
                    this.setState({
                      isErrorDialogOpen: false,
                      householdMemberMessages: [],
                    });
                  }}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  fullWidth={true}
                  maxWidth={"sm"}
                >
                  <DialogTitle id="form-dialog-title">
                    Error occurred
                  </DialogTitle>
                  <DialogContent>
                    {Helpers.handleError(
                      MessageForAction.Read,
                      this.state.householdMemberMessages
                    )}
                  </DialogContent>
                  <DialogActions>
                    <Button
                      color="secondary"
                      onClick={() => {
                        this.setState({
                          isErrorDialogOpen: false,
                          householdMemberMessages: [],
                        });
                      }}
                    >
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>
                <Tooltip title="Reload">
                  <IconButton onClick={this.refreshTableData.bind(this)}>
                    <Refresh className={classes.block} color="inherit" />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Action</TableCell>
                <TableCell>Person</TableCell>
                <TableCell>Birthday</TableCell>
                <TableCell>Household</TableCell>
                <TableCell>Comment</TableCell>
                <TableCell>IsActive</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{householdMembers}</TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10]}
                  count={this.state.householdMembersCount}
                  rowsPerPage={10}
                  page={this.state.pageNumber}
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    native: true,
                  }}
                  onChangePage={this.handleChangePage.bind(this)}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Paper>
    );
  }

  private handleCatch(error: any, messageForAction: MessageForAction) {
    let { householdMemberMessages } = this.state;
    if (error.toString().includes("Network Error")) {
      householdMemberMessages.push(
        new MessageInfo(
          messageForAction,
          "Server unavailable, try again later",
          503
        )
      );
    } else if (
      error.toString().includes("Request failed with status code 401")
    ) {
      this.authService.login();
    } else if (
      error.toString().includes("Request failed with status code 500")
    ) {
      householdMemberMessages.push(
        new MessageInfo(messageForAction, error.toString(), 500)
      );
    } else if (error.response.data.hasOwnProperty("errors")) {
      error.response.data.errors.forEach((errorItem: any) => {
        householdMemberMessages.push(
          new MessageInfo(
            messageForAction,
            errorItem.message,
            error.response.status
          )
        );
      });
    } else if (error.response.data.hasOwnProperty("Message")) {
      householdMemberMessages.push(
        new MessageInfo(
          messageForAction,
          error.response.data.Message,
          error.response.status
        )
      );
    } else {
      householdMemberMessages.push(
        new MessageInfo(messageForAction, error, error.response.status)
      );
    }
    this.setState({ householdMemberMessages });
  }
}

export default withStyles(styles)(HouseholdMember);
