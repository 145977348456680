import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  AppBar,
  Toolbar,
  Paper,
  Grid,
  Button,
  TextField,
  Tooltip,
  IconButton,
  Input,
  InputAdornment,
  FormControl,
  InputLabel,
  Switch,
  FormControlLabel,
  TableFooter,
  TablePagination,
} from "@material-ui/core";
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import {
  Delete,
  Edit,
  Visibility,
  VisibilityOff,
  Add,
  Search,
  Refresh,
} from "@material-ui/icons";
import Axios from "axios";
import { IdentityConstants } from "../../helpers/IdentityConstants";
import { AppConstants } from "../../helpers/AppConstants";
import { AuthService } from "../../services/AuthService";
import { MessageInfo } from "../MessageInfo";
import { MessageForAction } from "../MessageForAction";
import Helpers from "../../helpers/Helpers";
import { IAdministrativeUserState } from "./componentStates/IAdministrativeUserState";
import _ from "lodash";

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      maxWidth: 936,
      margin: "auto",
      overflow: "hidden",
    },
    searchBar: {
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    },
    searchInput: {
      fontSize: theme.typography.fontSize,
    },
    block: {
      display: "block",
    },
  });

export interface IAdministrativeUserComponentProps
  extends WithStyles<typeof styles> {}

class AdministrativeUser extends React.Component<
  IAdministrativeUserComponentProps,
  IAdministrativeUserState
> {
  private authService: AuthService;
  constructor(props: IAdministrativeUserComponentProps) {
    super(props);
    this.authService = new AuthService();
    this.state = {
      administrativeUsers: [],
      newAdministrativeUserData: {
        username: "",
        fullName: "",
        password: "",
        isActive: true,
      },
      editAdministrativeUserData: {
        id: "",
        username: "",
        fullName: "",
        password: "",
        isActive: true,
      },
      deleteAdministrativeUserData: {
        id: "",
        fullName: "",
      },
      isNewAdministrativeUserModalOpen: false,
      isEditAdministrativeUserModalOpen: false,
      isAdministrativeUserDeleteConfirmModalOpen: false,
      showPasswordCreate: false,
      showPasswordUpdate: false,
      pageNumber: 0,
      administrativeUsersCount: 0,
      searchText: "",
      AdministrativeUserMessages: [],
      isErrorDialogOpen: false,
    };
    this.debounceRefreshAdministrativeUsers = _.debounce(
      this.debounceRefreshAdministrativeUsers,
      1000
    );
  }

  debounceRefreshAdministrativeUsers() {
    this.refreshAdministrativeUsers();
  }

  componentDidMount() {
    this.refreshTableData();
  }

  removeMessages(actionType: MessageForAction) {
    let filteredMessages = this.state.AdministrativeUserMessages.filter(
      (m) => m.messageForAction !== actionType
    );
    this.setState({ AdministrativeUserMessages: filteredMessages });
  }

  refreshTableData() {
    this.refreshAdministrativeUsers();
  }

  refreshAdministrativeUsers() {
    this.setState({ AdministrativeUserMessages: [] });
    this.authService.getUser().then((user) => {
      if (user && user.access_token) {
        const headers = Helpers.getHeaders(user.access_token);
        let searchDTO = {
          searchText: this.state.searchText,
          pageNumber: this.state.pageNumber,
          pageSize: AppConstants.TablePageSize,
        };
        Axios.post(
          `${IdentityConstants.apiRoot}AdministrativeUser/GetItems`,
          searchDTO,
          { headers }
        )
          .then((response) => {
            this.setState({
              administrativeUsers: response.data.items,
              administrativeUsersCount: response.data.searchItemsCount,
            });
          })
          .catch((error) => {
            this.handleCatch(error, MessageForAction.Read);
            this.setState({ isErrorDialogOpen: true });
          });
      }
    });
  }

  toggleNewAdministrativeUserModal() {
    this.setState({
      isNewAdministrativeUserModalOpen: !this.state
        .isNewAdministrativeUserModalOpen,
    });
  }

  toggleEditAdministrativeUserModal() {
    this.setState({
      isEditAdministrativeUserModalOpen: !this.state
        .isEditAdministrativeUserModalOpen,
    });
    this.removeMessages(MessageForAction.Update);
  }

  toggleDeleteAdministrativeUserModal() {
    this.setState({
      isAdministrativeUserDeleteConfirmModalOpen: !this.state
        .isAdministrativeUserDeleteConfirmModalOpen,
    });
    this.removeMessages(MessageForAction.Delete);
  }

  addNewAdministrativeUser() {
    this.removeMessages(MessageForAction.Create);
    this.authService.getUser().then((user) => {
      if (user && user.access_token) {
        const headers = Helpers.getHeaders(user.access_token);
        Axios.post(
          `${IdentityConstants.apiRoot}AdministrativeUser`,
          this.state.newAdministrativeUserData,
          { headers }
        )
          .then((_) => {
            this.refreshTableData();
            this.setState({
              isNewAdministrativeUserModalOpen: false,
              newAdministrativeUserData: {
                username: "",
                fullName: "",
                password: "",
                isActive: true,
              },
            });
          })
          .catch((error) => {
            this.handleCatch(error, MessageForAction.Create);
          });
      }
    });
  }

  updateAdministrativeUser() {
    this.removeMessages(MessageForAction.Update);
    this.authService.getUser().then((user) => {
      if (user && user.access_token) {
        const headers = Helpers.getHeaders(user.access_token);
        let {
          username,
          fullName,
          password,
          isActive,
        } = this.state.editAdministrativeUserData;
        Axios.put(
          `${IdentityConstants.apiRoot}AdministrativeUser/${this.state.editAdministrativeUserData.id}`,
          {
            username,
            fullName,
            password,
            isActive,
          },
          { headers }
        )
          .then((response) => {
            this.refreshAdministrativeUsers();
            this.setState({
              isEditAdministrativeUserModalOpen: false,
              editAdministrativeUserData: {
                id: "",
                username: "",
                fullName: "",
                password: "",
                isActive: true,
              },
            });
          })
          .catch((error) => {
            this.handleCatch(error, MessageForAction.Update);
          });
      }
    });
  }

  deleteAdministrativeUser() {
    this.removeMessages(MessageForAction.Delete);
    this.authService.getUser().then((user) => {
      if (user && user.access_token) {
        const headers = Helpers.getHeaders(user.access_token);
        let { id } = this.state.deleteAdministrativeUserData;
        Axios.delete(`${IdentityConstants.apiRoot}AdministrativeUser/${id}`, {
          headers,
        })
          .then((response) => {
            this.refreshTableData();
            this.setState({
              isAdministrativeUserDeleteConfirmModalOpen: false,
              deleteAdministrativeUserData: {
                id: "",
                fullName: "",
              },
            });
          })
          .catch((error) => {
            this.handleCatch(error, MessageForAction.Delete);
          });
      }
    });
  }

  editAdministrativeUserSetState(
    id: any,
    username: any,
    fullName: any,
    isActive: boolean
  ) {
    let { editAdministrativeUserData } = this.state;
    editAdministrativeUserData.id = id;
    editAdministrativeUserData.username = username;
    editAdministrativeUserData.fullName = fullName;
    editAdministrativeUserData.password = "";
    editAdministrativeUserData.isActive = isActive;
    this.setState({
      editAdministrativeUserData,
      isEditAdministrativeUserModalOpen: !this.state
        .isEditAdministrativeUserModalOpen,
    });
  }

  deleteAdministrativeUserSetState(id: any, fullName: any) {
    let { deleteAdministrativeUserData } = this.state;
    deleteAdministrativeUserData.id = id;
    deleteAdministrativeUserData.fullName = fullName;
    this.setState({
      deleteAdministrativeUserData,
      isAdministrativeUserDeleteConfirmModalOpen: !this.state
        .isAdministrativeUserDeleteConfirmModalOpen,
    });
  }

  handleClickShowPasswordCreate = () => {
    this.setState({
      showPasswordCreate: !this.state.showPasswordCreate,
    });
  };

  handleClickShowPasswordUpdate = () => {
    this.setState({
      showPasswordUpdate: !this.state.showPasswordUpdate,
    });
  };

  handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  handleChangePage(
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) {
    this.setState({ pageNumber: newPage });
    this.debounceRefreshAdministrativeUsers();
  }

  public render() {
    const { classes } = this.props;
    let AdministrativeUsers = this.state.administrativeUsers.map(
      (AdministrativeUser) => {
        return (
          <TableRow key={AdministrativeUser.id}>
            <TableCell>
              <Tooltip title="Edit">
                <IconButton
                  onClick={this.editAdministrativeUserSetState.bind(
                    this,
                    AdministrativeUser.id,
                    AdministrativeUser.username,
                    AdministrativeUser.fullName,
                    AdministrativeUser.isActive
                  )}
                >
                  <Edit />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete">
                <IconButton
                  onClick={this.deleteAdministrativeUserSetState.bind(
                    this,
                    AdministrativeUser.id,
                    AdministrativeUser.fullName
                  )}
                >
                  <Delete />
                </IconButton>
              </Tooltip>
            </TableCell>
            <TableCell>{AdministrativeUser.username}</TableCell>
            <TableCell>{AdministrativeUser.fullName}</TableCell>
            <TableCell>
              <Switch
                checked={AdministrativeUser.isActive}
                color="primary"
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            </TableCell>
          </TableRow>
        );
      }
    );
    return (
      <Paper className={classes.paper}>
        <AppBar
          className={classes.searchBar}
          position="static"
          color="default"
          elevation={0}
        >
          <Toolbar>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <Search className={classes.block} color="inherit" />
              </Grid>
              <Grid item xs>
                <TextField
                  fullWidth
                  placeholder="Search by username, or full name"
                  value={this.state.searchText}
                  onChange={(e) => {
                    this.setState({ searchText: e.target.value });
                  }}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      this.setState({ pageNumber: 0 });
                      this.refreshTableData();
                      e.preventDefault();
                    }
                  }}
                  InputProps={{
                    disableUnderline: true,
                    className: classes.searchInput,
                  }}
                />
              </Grid>
              <Grid item>
                <Tooltip title="Add">
                  <IconButton
                    onClick={this.toggleNewAdministrativeUserModal.bind(this)}
                  >
                    <Add />
                  </IconButton>
                </Tooltip>
                <Dialog
                  open={this.state.isNewAdministrativeUserModalOpen}
                  onClose={this.toggleNewAdministrativeUserModal.bind(this)}
                  aria-labelledby="form-dialog-title"
                >
                  <DialogTitle id="form-dialog-title">
                    Add AdministrativeUser
                  </DialogTitle>
                  <DialogContent>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          autoFocus
                          margin="dense"
                          id="Username"
                          label="Username"
                          fullWidth
                          value={this.state.newAdministrativeUserData.username}
                          onChange={(e) => {
                            let { newAdministrativeUserData } = this.state;
                            newAdministrativeUserData.username = e.target.value;
                            this.setState({ newAdministrativeUserData });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          margin="dense"
                          id="FullName"
                          label="Full name"
                          fullWidth
                          value={this.state.newAdministrativeUserData.fullName}
                          onChange={(e) => {
                            let { newAdministrativeUserData } = this.state;
                            newAdministrativeUserData.fullName = e.target.value;
                            this.setState({ newAdministrativeUserData });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth margin="dense">
                          <InputLabel htmlFor="standard-adornment-password">
                            Password
                          </InputLabel>
                          <Input
                            id="standard-adornment-password"
                            type={
                              this.state.showPasswordCreate
                                ? "text"
                                : "password"
                            }
                            value={
                              this.state.newAdministrativeUserData.password
                            }
                            onChange={(e) => {
                              let { newAdministrativeUserData } = this.state;
                              newAdministrativeUserData.password =
                                e.target.value;
                              this.setState({ newAdministrativeUserData });
                            }}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={this.handleClickShowPasswordCreate}
                                  onMouseDown={this.handleMouseDownPassword}
                                >
                                  {this.state.showPasswordCreate ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControlLabel
                          label="IsActive"
                          labelPlacement="start"
                          style={{ marginLeft: 0 }}
                          control={
                            <Switch
                              checked={
                                this.state.newAdministrativeUserData.isActive
                              }
                              value={
                                this.state.newAdministrativeUserData.isActive
                              }
                              onChange={(e) => {
                                let { newAdministrativeUserData } = this.state;
                                newAdministrativeUserData.isActive =
                                  e.target.checked;
                                this.setState({ newAdministrativeUserData });
                              }}
                              color="primary"
                              inputProps={{ "aria-label": "primary checkbox" }}
                            />
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        {Helpers.handleError(
                          MessageForAction.Create,
                          this.state.AdministrativeUserMessages
                        )}
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      color="primary"
                      onClick={this.addNewAdministrativeUser.bind(this)}
                    >
                      Add
                    </Button>
                    <Button
                      color="secondary"
                      onClick={this.toggleNewAdministrativeUserModal.bind(this)}
                    >
                      Cancel
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  open={this.state.isAdministrativeUserDeleteConfirmModalOpen}
                  onClose={this.toggleDeleteAdministrativeUserModal.bind(this)}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  fullWidth={true}
                  maxWidth={"sm"}
                >
                  <DialogTitle id="form-dialog-title">
                    Delete AdministrativeUser
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Delete {this.state.deleteAdministrativeUserData.fullName}{" "}
                      AdministrativeUser?
                    </DialogContentText>
                    {Helpers.handleError(
                      MessageForAction.Delete,
                      this.state.AdministrativeUserMessages
                    )}
                  </DialogContent>
                  <DialogActions>
                    <Button
                      color="primary"
                      onClick={this.deleteAdministrativeUser.bind(this)}
                    >
                      Yes, delete
                    </Button>
                    <Button
                      color="secondary"
                      onClick={this.toggleDeleteAdministrativeUserModal.bind(
                        this
                      )}
                    >
                      Cancel
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  open={this.state.isEditAdministrativeUserModalOpen}
                  onClose={this.toggleEditAdministrativeUserModal.bind(this)}
                  aria-labelledby="form-dialog-title"
                >
                  <DialogTitle id="form-dialog-title">
                    Edit AdministrativeUser
                  </DialogTitle>
                  <DialogContent>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          autoFocus
                          margin="dense"
                          id="Username"
                          label="Username"
                          fullWidth
                          value={this.state.editAdministrativeUserData.username}
                          onChange={(e) => {
                            let { editAdministrativeUserData } = this.state;
                            editAdministrativeUserData.username =
                              e.target.value;
                            this.setState({ editAdministrativeUserData });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          margin="dense"
                          id="FullName"
                          label="Full name"
                          fullWidth
                          value={this.state.editAdministrativeUserData.fullName}
                          onChange={(e) => {
                            let { editAdministrativeUserData } = this.state;
                            editAdministrativeUserData.fullName =
                              e.target.value;
                            this.setState({ editAdministrativeUserData });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth margin="dense">
                          <InputLabel htmlFor="standard-adornment-password">
                            Password
                          </InputLabel>
                          <Input
                            id="standard-adornment-password"
                            type={
                              this.state.showPasswordUpdate
                                ? "text"
                                : "password"
                            }
                            value={
                              this.state.editAdministrativeUserData.password
                            }
                            onChange={(e) => {
                              let { editAdministrativeUserData } = this.state;
                              editAdministrativeUserData.password =
                                e.target.value;
                              this.setState({ editAdministrativeUserData });
                            }}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={this.handleClickShowPasswordUpdate}
                                  onMouseDown={this.handleMouseDownPassword}
                                >
                                  {this.state.showPasswordUpdate ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControlLabel
                          label="IsActive"
                          labelPlacement="start"
                          style={{ marginLeft: 0 }}
                          control={
                            <Switch
                              checked={
                                this.state.editAdministrativeUserData.isActive
                              }
                              value={
                                this.state.editAdministrativeUserData.isActive
                              }
                              onChange={(e) => {
                                let { editAdministrativeUserData } = this.state;
                                editAdministrativeUserData.isActive =
                                  e.target.checked;
                                this.setState({ editAdministrativeUserData });
                              }}
                              color="primary"
                              inputProps={{ "aria-label": "primary checkbox" }}
                            />
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        {Helpers.handleError(
                          MessageForAction.Update,
                          this.state.AdministrativeUserMessages
                        )}
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      color="primary"
                      onClick={this.updateAdministrativeUser.bind(this)}
                    >
                      Update
                    </Button>
                    <Button
                      color="secondary"
                      onClick={this.toggleEditAdministrativeUserModal.bind(
                        this
                      )}
                    >
                      Cancel
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  open={this.state.isErrorDialogOpen}
                  onClose={() => {
                    this.setState({
                      isErrorDialogOpen: false,
                      AdministrativeUserMessages: [],
                    });
                  }}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  fullWidth={true}
                  maxWidth={"sm"}
                >
                  <DialogTitle id="form-dialog-title">
                    Error occurred
                  </DialogTitle>
                  <DialogContent>
                    {Helpers.handleError(
                      MessageForAction.Read,
                      this.state.AdministrativeUserMessages
                    )}
                  </DialogContent>
                  <DialogActions>
                    <Button
                      color="secondary"
                      onClick={() => {
                        this.setState({
                          isErrorDialogOpen: false,
                          AdministrativeUserMessages: [],
                        });
                      }}
                    >
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>
                <Tooltip title="Reload">
                  <IconButton onClick={this.refreshTableData.bind(this)}>
                    <Refresh className={classes.block} color="inherit" />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Action</TableCell>
                <TableCell>Username</TableCell>
                <TableCell>Full name</TableCell>
                <TableCell>IsActive</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{AdministrativeUsers}</TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10]}
                  count={this.state.administrativeUsersCount}
                  rowsPerPage={10}
                  page={this.state.pageNumber}
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    native: true,
                  }}
                  onChangePage={this.handleChangePage.bind(this)}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Paper>
    );
  }

  private handleCatch(error: any, messageForAction: MessageForAction) {
    let { AdministrativeUserMessages } = this.state;
    if (error.toString().includes("Network Error")) {
      AdministrativeUserMessages.push(
        new MessageInfo(
          messageForAction,
          "Server unavailable, try again later",
          503
        )
      );
    } else if (
      error.toString().includes("Request failed with status code 401")
    ) {
      this.authService.login();
    } else if (
      error.toString().includes("Request failed with status code 500")
    ) {
      AdministrativeUserMessages.push(
        new MessageInfo(messageForAction, error.toString(), 500)
      );
    } else if (error.response.data.hasOwnProperty("errors")) {
      error.response.data.errors.forEach((errorItem: any) => {
        AdministrativeUserMessages.push(
          new MessageInfo(
            messageForAction,
            errorItem.message,
            error.response.status
          )
        );
      });
    } else if (error.response.data.hasOwnProperty("Message")) {
      AdministrativeUserMessages.push(
        new MessageInfo(
          messageForAction,
          error.response.data.Message,
          error.response.status
        )
      );
    } else if (error.response.data.hasOwnProperty("PasswordTooShort")) {
      error.response.data.PasswordTooShort.forEach(
        (passwordTooShort: string) => {
          AdministrativeUserMessages.push(
            new MessageInfo(
              messageForAction,
              passwordTooShort,
              error.response.status
            )
          );
        }
      );
    } else if (error.response.data.hasOwnProperty("PasswordRequiresDigit")) {
      error.response.data.PasswordRequiresDigit.forEach(
        (passwordRequiresDigit: string) => {
          AdministrativeUserMessages.push(
            new MessageInfo(
              messageForAction,
              passwordRequiresDigit,
              error.response.status
            )
          );
        }
      );
    } else if (error.response.data.hasOwnProperty("PasswordRequiresUpper")) {
      error.response.data.PasswordRequiresUpper.forEach(
        (passwordRequiresUpper: string) => {
          AdministrativeUserMessages.push(
            new MessageInfo(
              messageForAction,
              passwordRequiresUpper,
              error.response.status
            )
          );
        }
      );
    } else if (
      error.response.data.hasOwnProperty("PasswordRequiresNonAlphanumeric")
    ) {
      error.response.data.PasswordRequiresNonAlphanumeric.forEach(
        (passwordRequiresNonAlphanumeric: string) => {
          AdministrativeUserMessages.push(
            new MessageInfo(
              messageForAction,
              passwordRequiresNonAlphanumeric,
              error.response.status
            )
          );
        }
      );
    } else if (error.response.data.hasOwnProperty("PasswordRequiresLower")) {
      error.response.data.PasswordRequiresLower.forEach(
        (passwordRequiresLower: string) => {
          AdministrativeUserMessages.push(
            new MessageInfo(
              messageForAction,
              passwordRequiresLower,
              error.response.status
            )
          );
        }
      );
    } else {
      AdministrativeUserMessages.push(
        new MessageInfo(messageForAction, error, error.response.status)
      );
    }
    this.setState({ AdministrativeUserMessages: AdministrativeUserMessages });
  }
}

export default withStyles(styles)(AdministrativeUser);
