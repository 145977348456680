import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  AppBar,
  Toolbar,
  Paper,
  Grid,
  Button,
  TextField,
  Tooltip,
  IconButton,
  Switch,
  FormControlLabel,
  TableFooter,
  TablePagination,
} from "@material-ui/core";
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import { Delete, Edit, Add, Search, Refresh } from "@material-ui/icons";
import Axios from "axios";
import { IdentityConstants } from "../../helpers/IdentityConstants";
import { AuthService } from "../../services/AuthService";
import { MessageInfo } from "../MessageInfo";
import { MessageForAction } from "../MessageForAction";
import Helpers from "../../helpers/Helpers";
import { IMinistryTypeState } from "./componentStates/IMinistryTypeState";
import { AppConstants } from "../../helpers/AppConstants";
import _ from "lodash";

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      maxWidth: 936,
      margin: "auto",
      overflow: "hidden",
    },
    searchBar: {
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    },
    searchInput: {
      fontSize: theme.typography.fontSize,
    },
    block: {
      display: "block",
    },
  });

export interface IMinistryTypeComponentProps
  extends WithStyles<typeof styles> {}

class MinistryType extends React.Component<
  IMinistryTypeComponentProps,
  IMinistryTypeState
> {
  private authService: AuthService;
  constructor(props: IMinistryTypeComponentProps) {
    super(props);
    this.authService = new AuthService();
    this.state = {
      ministryTypes: [],
      newMinistryTypeData: {
        name: "",
        isActive: true,
      },
      editMinistryTypeData: {
        id: "",
        name: "",
        isActive: true,
      },
      deleteMinistryTypeData: {
        id: "",
        name: "",
      },
      isNewMinistryTypeModalOpen: false,
      isEditMinistryTypeModalOpen: false,
      isMinistryTypeDeleteConfirmModalOpen: false,
      pageNumber: 0,
      ministryTypesCount: 0,
      searchText: "",
      MinistryTypeMessages: [],
      isErrorDialogOpen: false,
    };
    this.debounceRefreshMinistryTypes = _.debounce(
      this.debounceRefreshMinistryTypes,
      1000
    );
  }

  debounceRefreshMinistryTypes() {
    this.refreshMinistryTypes();
  }

  componentDidMount() {
    this.refreshTableData();
  }

  refreshTableData() {
    this.refreshMinistryTypes();
  }

  refreshMinistryTypes() {
    this.setState({ MinistryTypeMessages: [] });
    this.authService.getUser().then((user) => {
      if (user && user.access_token) {
        const headers = Helpers.getHeaders(user.access_token);
        let searchDTO = {
          searchText: this.state.searchText,
          pageNumber: this.state.pageNumber,
          pageSize: AppConstants.TablePageSize,
        };
        Axios.post(
          `${IdentityConstants.apiRoot}MinistryType/GetItems`,
          searchDTO,
          { headers }
        )
          .then((response) => {
            this.setState({
              ministryTypes: response.data.items,
              ministryTypesCount: response.data.searchItemsCount,
            });
          })
          .catch((error) => {
            this.handleCatch(error, MessageForAction.Read);
            this.setState({ isErrorDialogOpen: true });
          });
      }
    });
  }

  removeMessages(actionType: MessageForAction) {
    let filteredMessages = this.state.MinistryTypeMessages.filter(
      (m) => m.messageForAction !== actionType
    );
    this.setState({ MinistryTypeMessages: filteredMessages });
  }

  toggleNewMinistryTypeModal() {
    this.setState({
      isNewMinistryTypeModalOpen: !this.state.isNewMinistryTypeModalOpen,
    });
  }

  toggleEditMinistryTypeModal() {
    this.setState({
      isEditMinistryTypeModalOpen: !this.state.isEditMinistryTypeModalOpen,
    });
    this.removeMessages(MessageForAction.Update);
  }

  toggleDeleteMinistryTypeModal() {
    this.setState({
      isMinistryTypeDeleteConfirmModalOpen: !this.state
        .isMinistryTypeDeleteConfirmModalOpen,
    });
    this.removeMessages(MessageForAction.Delete);
  }

  addNewMinistryType() {
    this.removeMessages(MessageForAction.Create);
    this.authService.getUser().then((user) => {
      if (user && user.access_token) {
        const headers = Helpers.getHeaders(user.access_token);
        Axios.post(
          `${IdentityConstants.apiRoot}MinistryType`,
          this.state.newMinistryTypeData,
          { headers }
        )
          .then((_) => {
            this.refreshTableData();
            this.setState({
              isNewMinistryTypeModalOpen: false,
              newMinistryTypeData: { name: "", isActive: true },
            });
          })
          .catch((error) => {
            this.handleCatch(error, MessageForAction.Create);
          });
      }
    });
  }

  updateMinistryType() {
    this.removeMessages(MessageForAction.Update);
    this.authService.getUser().then((user) => {
      if (user && user.access_token) {
        const headers = Helpers.getHeaders(user.access_token);
        let { id, name, isActive } = this.state.editMinistryTypeData;
        Axios.put(
          `${IdentityConstants.apiRoot}MinistryType/${id}`,
          { name, isActive },
          { headers }
        )
          .then((_) => {
            this.refreshMinistryTypes();
            this.setState({
              isEditMinistryTypeModalOpen: false,
              editMinistryTypeData: {
                id: "",
                name: "",
                isActive: true,
              },
            });
          })
          .catch((error) => {
            this.handleCatch(error, MessageForAction.Update);
          });
      }
    });
  }

  deleteMinistryType() {
    this.removeMessages(MessageForAction.Delete);
    this.authService.getUser().then((user) => {
      if (user && user.access_token) {
        const headers = Helpers.getHeaders(user.access_token);
        let { id } = this.state.deleteMinistryTypeData;
        Axios.delete(`${IdentityConstants.apiRoot}MinistryType/${id}`, {
          headers,
        })
          .then((response) => {
            this.refreshTableData();
            this.setState({
              isMinistryTypeDeleteConfirmModalOpen: false,
              deleteMinistryTypeData: {
                id: "",
                name: "",
              },
            });
          })
          .catch((error) => {
            this.handleCatch(error, MessageForAction.Delete);
          });
      }
    });
  }

  editMinistryTypeSetState(id: any, name: any, isActive: boolean) {
    let { editMinistryTypeData } = this.state;
    editMinistryTypeData.id = id;
    editMinistryTypeData.name = name;
    editMinistryTypeData.isActive = isActive;
    this.setState({
      editMinistryTypeData,
      isEditMinistryTypeModalOpen: !this.state.isEditMinistryTypeModalOpen,
    });
  }

  deleteMinistryTypeSetState(id: any, name: any) {
    let { deleteMinistryTypeData } = this.state;
    deleteMinistryTypeData.id = id;
    deleteMinistryTypeData.name = name;
    this.setState({
      deleteMinistryTypeData,
      isMinistryTypeDeleteConfirmModalOpen: !this.state
        .isMinistryTypeDeleteConfirmModalOpen,
    });
  }

  handleChangePage(
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) {
    this.setState({ pageNumber: newPage });
    this.debounceRefreshMinistryTypes();
  }

  public render() {
    const { classes } = this.props;
    let MinistryTypes = this.state.ministryTypes.map((MinistryType) => {
      return (
        <TableRow key={MinistryType.id}>
          <TableCell>
            <Tooltip title="Edit">
              <IconButton
                onClick={this.editMinistryTypeSetState.bind(
                  this,
                  MinistryType.id,
                  MinistryType.name,
                  MinistryType.isActive
                )}
              >
                <Edit cursor="pointer" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton
                onClick={this.deleteMinistryTypeSetState.bind(
                  this,
                  MinistryType.id,
                  MinistryType.name,
                  MinistryType.isActive
                )}
              >
                <Delete cursor="pointer" />
              </IconButton>
            </Tooltip>
          </TableCell>
          <TableCell>{MinistryType.name}</TableCell>
          <TableCell>
            <Switch
              checked={MinistryType.isActive}
              color="primary"
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </TableCell>
        </TableRow>
      );
    });
    return (
      <Paper className={classes.paper}>
        <AppBar
          className={classes.searchBar}
          position="static"
          color="default"
          elevation={0}
        >
          <Toolbar>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <Search className={classes.block} color="inherit" />
              </Grid>
              <Grid item xs>
                <TextField
                  fullWidth
                  placeholder="Search by name"
                  value={this.state.searchText}
                  onChange={(e) => {
                    this.setState({ searchText: e.target.value });
                  }}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      this.setState({ pageNumber: 0 });
                      this.refreshTableData();
                      e.preventDefault();
                    }
                  }}
                  InputProps={{
                    disableUnderline: true,
                    className: classes.searchInput,
                  }}
                />
              </Grid>
              <Grid item>
                <Tooltip title="Add">
                  <IconButton
                    onClick={this.toggleNewMinistryTypeModal.bind(this)}
                  >
                    <Add />
                  </IconButton>
                </Tooltip>
                <Dialog
                  open={this.state.isNewMinistryTypeModalOpen}
                  onClose={this.toggleNewMinistryTypeModal.bind(this)}
                  aria-labelledby="form-dialog-title"
                >
                  <DialogTitle id="form-dialog-title">
                    Add MinistryType
                  </DialogTitle>
                  <DialogContent>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <TextField
                          autoFocus
                          margin="dense"
                          id="MinistryName"
                          label="Ministry name"
                          fullWidth
                          value={this.state.newMinistryTypeData.name}
                          onChange={(e) => {
                            let { newMinistryTypeData } = this.state;
                            newMinistryTypeData.name = e.target.value;
                            this.setState({ newMinistryTypeData });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          label="IsActive"
                          labelPlacement="start"
                          style={{ marginLeft: 0 }}
                          control={
                            <Switch
                              checked={this.state.newMinistryTypeData.isActive}
                              value={this.state.newMinistryTypeData.isActive}
                              onChange={(e) => {
                                let { newMinistryTypeData } = this.state;
                                newMinistryTypeData.isActive = e.target.checked;
                                this.setState({ newMinistryTypeData });
                              }}
                              color="primary"
                              inputProps={{ "aria-label": "primary checkbox" }}
                            />
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        {Helpers.handleError(
                          MessageForAction.Create,
                          this.state.MinistryTypeMessages
                        )}
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      color="primary"
                      onClick={this.addNewMinistryType.bind(this)}
                    >
                      Add
                    </Button>
                    <Button
                      color="secondary"
                      onClick={this.toggleNewMinistryTypeModal.bind(this)}
                    >
                      Cancel
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  open={this.state.isMinistryTypeDeleteConfirmModalOpen}
                  onClose={this.toggleDeleteMinistryTypeModal.bind(this)}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  fullWidth={true}
                  maxWidth={"sm"}
                >
                  <DialogTitle id="form-dialog-title">
                    Delete MinistryType
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Delete {this.state.deleteMinistryTypeData.name}{" "}
                      MinistryType?
                    </DialogContentText>
                    {Helpers.handleError(
                      MessageForAction.Delete,
                      this.state.MinistryTypeMessages
                    )}
                  </DialogContent>
                  <DialogActions>
                    <Button
                      color="primary"
                      onClick={this.deleteMinistryType.bind(this)}
                    >
                      Yes, delete
                    </Button>
                    <Button
                      color="secondary"
                      onClick={this.toggleDeleteMinistryTypeModal.bind(this)}
                    >
                      Cancel
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  open={this.state.isEditMinistryTypeModalOpen}
                  onClose={this.toggleEditMinistryTypeModal.bind(this)}
                  aria-labelledby="form-dialog-title"
                >
                  <DialogTitle id="form-dialog-title">
                    Edit MinistryType
                  </DialogTitle>
                  <DialogContent>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <TextField
                          autoFocus
                          margin="dense"
                          id="MinistryName"
                          label="Ministry name"
                          fullWidth
                          value={this.state.editMinistryTypeData.name}
                          onChange={(e) => {
                            let { editMinistryTypeData } = this.state;
                            editMinistryTypeData.name = e.target.value;
                            this.setState({ editMinistryTypeData });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          label="IsActive"
                          labelPlacement="start"
                          style={{ marginLeft: 0 }}
                          control={
                            <Switch
                              checked={this.state.editMinistryTypeData.isActive}
                              value={this.state.editMinistryTypeData.isActive}
                              onChange={(e) => {
                                let { editMinistryTypeData } = this.state;
                                editMinistryTypeData.isActive =
                                  e.target.checked;
                                this.setState({ editMinistryTypeData });
                              }}
                              color="primary"
                              inputProps={{ "aria-label": "primary checkbox" }}
                            />
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        {Helpers.handleError(
                          MessageForAction.Update,
                          this.state.MinistryTypeMessages
                        )}
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      color="primary"
                      onClick={this.updateMinistryType.bind(this)}
                    >
                      Update
                    </Button>
                    <Button
                      color="secondary"
                      onClick={this.toggleEditMinistryTypeModal.bind(this)}
                    >
                      Cancel
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  open={this.state.isErrorDialogOpen}
                  onClose={() => {
                    this.setState({
                      isErrorDialogOpen: false,
                      MinistryTypeMessages: [],
                    });
                  }}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  fullWidth={true}
                  maxWidth={"sm"}
                >
                  <DialogTitle id="form-dialog-title">
                    Error occurred
                  </DialogTitle>
                  <DialogContent>
                    {Helpers.handleError(
                      MessageForAction.Read,
                      this.state.MinistryTypeMessages
                    )}
                  </DialogContent>
                  <DialogActions>
                    <Button
                      color="secondary"
                      onClick={() => {
                        this.setState({
                          isErrorDialogOpen: false,
                          MinistryTypeMessages: [],
                        });
                      }}
                    >
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>
                <Tooltip title="Reload">
                  <IconButton onClick={this.refreshTableData.bind(this)}>
                    <Refresh className={classes.block} color="inherit" />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Action</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>IsActive</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{MinistryTypes}</TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10]}
                  count={this.state.ministryTypesCount}
                  rowsPerPage={10}
                  page={this.state.pageNumber}
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    native: true,
                  }}
                  onChangePage={this.handleChangePage.bind(this)}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Paper>
    );
  }

  private handleCatch(error: any, messageForAction: MessageForAction) {
    let { MinistryTypeMessages } = this.state;
    if (error.toString().includes("Network Error")) {
      MinistryTypeMessages.push(
        new MessageInfo(
          messageForAction,
          "Server unavailable, try again later",
          503
        )
      );
    } else if (
      error.toString().includes("Request failed with status code 401")
    ) {
      this.authService.login();
    } else if (
      error.toString().includes("Request failed with status code 500")
    ) {
      MinistryTypeMessages.push(
        new MessageInfo(messageForAction, error.toString(), 500)
      );
    } else if (error.response.data.hasOwnProperty("errors")) {
      error.response.data.errors.forEach((errorItem: any) => {
        MinistryTypeMessages.push(
          new MessageInfo(
            messageForAction,
            errorItem.message,
            error.response.status
          )
        );
      });
    } else if (error.response.data.hasOwnProperty("Message")) {
      MinistryTypeMessages.push(
        new MessageInfo(
          messageForAction,
          error.response.data.Message,
          error.response.status
        )
      );
    } else {
      MinistryTypeMessages.push(
        new MessageInfo(messageForAction, error, error.response.status)
      );
    }
    this.setState({ MinistryTypeMessages: MinistryTypeMessages });
  }
}

export default withStyles(styles)(MinistryType);
