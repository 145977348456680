import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  AppBar,
  Toolbar,
  Paper,
  Grid,
  Button,
  TextField,
  Tooltip,
  IconButton,
  Switch,
  FormControlLabel,
  TableFooter,
  TablePagination,
} from "@material-ui/core";
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Delete, Edit, Add, Search, Refresh } from "@material-ui/icons";
import Axios from "axios";
import { IdentityConstants } from "../../helpers/IdentityConstants";
import { AuthService } from "../../services/AuthService";
import { MessageInfo } from "../MessageInfo";
import { MessageForAction } from "../MessageForAction";
import { ReferenceEntitySelector } from "../ReferenceEntitySelector";
import Helpers from "../../helpers/Helpers";
import { IMinistry } from "./dataTransferableObjects/IMinistry";
import { IMinistrySave } from "./dataTransferableObjects/IMinistrySave";
import { IMinistryState } from "./componentStates/IMinistryState";
import { AppConstants } from "../../helpers/AppConstants";
import _ from "lodash";

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      margin: "auto",
      overflow: "hidden",
    },
    searchBar: {
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    },
    searchInput: {
      fontSize: theme.typography.fontSize,
    },
    block: {
      display: "block",
    },
  });

export interface IMinistryComponentProps extends WithStyles<typeof styles> {}

class Ministry extends React.Component<
  IMinistryComponentProps,
  IMinistryState
> {
  private authService: AuthService;
  constructor(props: IMinistryComponentProps) {
    super(props);
    this.authService = new AuthService();
    this.state = {
      ministries: [],
      newMinistryData: this.resetMinistry(),
      editMinistryData: this.resetMinistry(),
      deleteMinistryData: {
        id: "",
        name: "",
      },
      isNewMinistryModalOpen: false,
      isEditMinistryModalOpen: false,
      isDeleteMinistryConfirmModalOpen: false,
      pageNumber: 0,
      ministriesCount: 0,
      searchText: "",
      ministryMessages: [],
      isErrorDialogOpen: false,
    };
    this.debounceRefreshMinistries = _.debounce(
      this.debounceRefreshMinistries,
      1000
    );
  }

  debounceRefreshMinistries() {
    this.refreshMinistries();
  }

  resetMinistry() {
    let data: IMinistry = {
      id: "",
      ordainedStartDate: null,
      ordainedEndDate: null,
      person: null,
      ministryType: null,
      building: null,
      isActive: true,
    };
    return data;
  }

  componentDidMount() {
    this.refreshTableData();
  }

  refreshTableData() {
    this.refreshMinistries();
  }

  refreshMinistries() {
    this.setState({ ministryMessages: [] });
    this.authService.getUser().then((user) => {
      if (user && user.access_token) {
        const headers = Helpers.getHeaders(user.access_token);
        let searchDTO = {
          searchText: this.state.searchText,
          pageNumber: this.state.pageNumber,
          pageSize: AppConstants.TablePageSize,
        };
        Axios.post(`${IdentityConstants.apiRoot}Ministry/GetItems`, searchDTO, {
          headers,
        })
          .then((response) => {
            this.setState({
              ministries: response.data.items,
              ministriesCount: response.data.searchItemsCount,
            });
          })
          .catch((error) => {
            this.handleCatch(error, MessageForAction.Read);
            this.setState({ isErrorDialogOpen: true });
          });
      }
    });
  }

  removeMessages(actionType: MessageForAction) {
    let filteredMessages = this.state.ministryMessages.filter(
      (m) => m.messageForAction !== actionType
    );
    this.setState({ ministryMessages: filteredMessages });
  }

  handleNewChurchBuildingSelect = (selectedChurchBuilding: any) => {
    let { newMinistryData } = this.state;
    newMinistryData.building = selectedChurchBuilding;
    this.setState({ newMinistryData });
  };

  handleNewMinistryTypeSelect = (selectedMinistryType: any) => {
    let { newMinistryData } = this.state;
    newMinistryData.ministryType = selectedMinistryType;
    this.setState({ newMinistryData });
  };

  handleNewPersonSelect = (selectedPerson: any) => {
    let { newMinistryData } = this.state;
    newMinistryData.person = selectedPerson;
    this.setState({ newMinistryData });
  };

  handleEditMinistryTypeSelect = (selectedMinistryType: any) => {
    let { editMinistryData } = this.state;
    editMinistryData.ministryType = selectedMinistryType;
    this.setState({ editMinistryData });
  };

  handleEditPersonSelect = (selectedPerson: any) => {
    let { editMinistryData } = this.state;
    editMinistryData.person = selectedPerson;
    this.setState({ editMinistryData });
  };

  toggleNewMinistryModal() {
    this.setState({
      isNewMinistryModalOpen: !this.state.isNewMinistryModalOpen,
    });
  }

  toggleEditMinistryModal() {
    this.setState({
      isEditMinistryModalOpen: !this.state.isEditMinistryModalOpen,
    });
    this.removeMessages(MessageForAction.Update);
  }

  toggleDeleteMinistryModal() {
    this.setState({
      isDeleteMinistryConfirmModalOpen: !this.state
        .isDeleteMinistryConfirmModalOpen,
    });
    this.removeMessages(MessageForAction.Delete);
  }

  addNewMinistry() {
    this.removeMessages(MessageForAction.Create);
    this.authService.getUser().then((user) => {
      if (user && user.access_token) {
        const headers = Helpers.getHeaders(user.access_token);
        Axios.post(
          `${IdentityConstants.apiRoot}Ministry`,
          this.CopyFrom(this.state.newMinistryData),
          { headers }
        )
          .then((_) => {
            this.refreshTableData();
            this.setState({
              isNewMinistryModalOpen: false,
              newMinistryData: this.resetMinistry(),
            });
          })
          .catch((error) => {
            this.handleCatch(error, MessageForAction.Create);
          });
      }
    });
  }

  CopyFrom(ministry: IMinistry): IMinistrySave {
    return {
      ordainedStartDate: ministry.ordainedStartDate,
      ordainedEndDate: ministry.ordainedEndDate,
      personId: ministry.person?.id,
      ministryTypeId: ministry.ministryType?.id,
      buildingId: ministry.building?.id,
      isActive: ministry.isActive,
    };
  }

  updateMinistry() {
    this.removeMessages(MessageForAction.Update);
    this.authService.getUser().then((user) => {
      if (user && user.access_token) {
        const headers = Helpers.getHeaders(user.access_token);
        Axios.put(
          `${IdentityConstants.apiRoot}Ministry/${this.state.editMinistryData.id}`,
          this.CopyFrom(this.state.editMinistryData),
          { headers }
        )
          .then((response) => {
            this.refreshMinistries();
            this.setState({
              isEditMinistryModalOpen: false,
              editMinistryData: this.resetMinistry(),
            });
          })
          .catch((error) => {
            this.handleCatch(error, MessageForAction.Update);
          });
      }
    });
  }

  deleteMinistry() {
    this.removeMessages(MessageForAction.Delete);
    this.authService.getUser().then((user) => {
      if (user && user.access_token) {
        const headers = Helpers.getHeaders(user.access_token);
        Axios.delete(
          `${IdentityConstants.apiRoot}Ministry/${this.state.deleteMinistryData.id}`,
          { headers }
        )
          .then(() => {
            this.refreshTableData();
            this.setState({
              isDeleteMinistryConfirmModalOpen: false,
              deleteMinistryData: { id: "", name: "" },
            });
          })
          .catch((error) => {
            this.handleCatch(error, MessageForAction.Delete);
          });
      }
    });
  }

  editMinistrySetState(
    id: string,
    ordainedStartDate: Date,
    ordainedEndDate: Date,
    person: any,
    ministryType: any,
    isActive: boolean
  ) {
    let { editMinistryData } = this.state;
    editMinistryData.id = id;
    editMinistryData.ordainedStartDate = ordainedStartDate;
    editMinistryData.ordainedEndDate = ordainedEndDate;
    editMinistryData.person = person;
    editMinistryData.ministryType = ministryType;
    editMinistryData.isActive = isActive;
    this.setState({
      editMinistryData,
      isEditMinistryModalOpen: !this.state.isEditMinistryModalOpen,
    });
  }

  deleteMinistrySetState(id: any, name: any) {
    let { deleteMinistryData } = this.state;
    deleteMinistryData.id = id;
    deleteMinistryData.name = name;
    this.setState({
      deleteMinistryData,
      isDeleteMinistryConfirmModalOpen: !this.state
        .isDeleteMinistryConfirmModalOpen,
    });
  }

  handleChangePage(
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) {
    this.setState({ pageNumber: newPage });
    this.debounceRefreshMinistries();
  }

  public render() {
    const { classes } = this.props;
    let ministries = this.state.ministries.map((ministry) => {
      return (
        <TableRow key={ministry.id}>
          <TableCell>
            <Tooltip title="Edit">
              <IconButton
                onClick={this.editMinistrySetState.bind(
                  this,
                  ministry.id,
                  ministry.ordainedStartDate,
                  ministry.ordainedEndDate,
                  ministry.person,
                  ministry.ministryType,
                  ministry.isActive
                )}
              >
                <Edit style={{ marginRight: 2 }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton
                onClick={this.deleteMinistrySetState.bind(
                  this,
                  ministry.id,
                  ministry.person?.title
                )}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          </TableCell>
          <TableCell>{`${ministry.person?.title}`}</TableCell>
          <TableCell>{ministry.ministryType?.name}</TableCell>
          <TableCell>
            {ministry.ordainedStartDate === null
              ? null
              : new Date(ministry.ordainedStartDate).toDateString()}
          </TableCell>
          <TableCell>
            {ministry.ordainedEndDate === null
              ? null
              : new Date(ministry.ordainedEndDate).toDateString()}
          </TableCell>
          <TableCell>
            <Switch
              checked={ministry.isActive}
              color="primary"
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </TableCell>
        </TableRow>
      );
    });
    return (
      <Paper className={classes.paper}>
        <AppBar
          className={classes.searchBar}
          position="static"
          color="default"
          elevation={0}
        >
          <Toolbar>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <Search className={classes.block} color="inherit" />
              </Grid>
              <Grid item xs>
                <TextField
                  fullWidth
                  placeholder="Search by Person's first and last name"
                  value={this.state.searchText}
                  onChange={(e) => {
                    this.setState({ searchText: e.target.value });
                  }}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      this.setState({ pageNumber: 0 });
                      this.refreshTableData();
                      e.preventDefault();
                    }
                  }}
                  InputProps={{
                    disableUnderline: true,
                    className: classes.searchInput,
                  }}
                />
              </Grid>
              <Grid item>
                <Tooltip title="Add">
                  <IconButton onClick={this.toggleNewMinistryModal.bind(this)}>
                    <Add />
                  </IconButton>
                </Tooltip>
                <Dialog
                  open={this.state.isNewMinistryModalOpen}
                  onClose={this.toggleNewMinistryModal.bind(this)}
                  aria-labelledby="form-dialog-title"
                >
                  <DialogTitle id="form-dialog-title">Add Ministry</DialogTitle>
                  <DialogContent>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6}>
                        <ReferenceEntitySelector
                          componentId="addPersonSelector"
                          selectedItem={this.state.newMinistryData.person}
                          label="Person"
                          url="Person/GetItemsForReferenceControl"
                          required={true}
                          onReferenceEntitySelectEvent={
                            this.handleNewPersonSelect
                          }
                          handleException={(error) =>
                            this.handleCatch(error, MessageForAction.Create)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <ReferenceEntitySelector
                          componentId="addMinistryTypeSelector"
                          selectedItem={this.state.newMinistryData.ministryType}
                          label="MinistryType"
                          url="MinistryType/GetItemsForReferenceControl"
                          required={true}
                          onReferenceEntitySelectEvent={
                            this.handleNewMinistryTypeSelect
                          }
                          handleException={(error) =>
                            this.handleCatch(error, MessageForAction.Create)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <ReferenceEntitySelector
                          componentId="newChurchBuildingSelector"
                          selectedItem={this.state.newMinistryData.building}
                          label="Building"
                          url="Building/GetAllActiveChurchesForReferenceControl"
                          onReferenceEntitySelectEvent={
                            this.handleNewChurchBuildingSelect
                          }
                          handleException={(error) =>
                            this.handleCatch(error, MessageForAction.Create)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            autoOk
                            disableFuture
                            openTo="year"
                            required
                            views={["year", "month", "date"]}
                            variant="inline"
                            format="MM/dd/yyyy"
                            id="addOrdainedStartDate"
                            label="OrdainedStartDate"
                            value={this.state.newMinistryData.ordainedStartDate}
                            onChange={(e) => {
                              let { newMinistryData } = this.state;
                              newMinistryData.ordainedStartDate = Helpers.getDateWithUTCZero(
                                e
                              );
                              this.setState({ newMinistryData });
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            autoOk
                            disableFuture
                            openTo="year"
                            views={["year", "month", "date"]}
                            variant="inline"
                            format="MM/dd/yyyy"
                            id="addOrdainedEndDate"
                            label="OrdainedEndDate"
                            value={this.state.newMinistryData.ordainedEndDate}
                            onChange={(e) => {
                              let { newMinistryData } = this.state;
                              newMinistryData.ordainedEndDate = Helpers.getDateWithUTCZero(
                                e
                              );
                              this.setState({ newMinistryData });
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          label="Is active"
                          labelPlacement="start"
                          style={{ marginLeft: 0 }}
                          control={
                            <Switch
                              checked={this.state.newMinistryData.isActive}
                              value={this.state.newMinistryData.isActive}
                              onChange={(e) => {
                                let { newMinistryData } = this.state;
                                newMinistryData.isActive = e.target.checked;
                                this.setState({ newMinistryData });
                              }}
                              color="primary"
                              inputProps={{ "aria-label": "primary checkbox" }}
                            />
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        {Helpers.handleError(
                          MessageForAction.Create,
                          this.state.ministryMessages
                        )}
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      color="primary"
                      onClick={this.addNewMinistry.bind(this)}
                    >
                      Add
                    </Button>
                    <Button
                      color="secondary"
                      onClick={this.toggleNewMinistryModal.bind(this)}
                    >
                      Cancel
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  open={this.state.isDeleteMinistryConfirmModalOpen}
                  onClose={this.toggleDeleteMinistryModal.bind(this)}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  fullWidth={true}
                  maxWidth={"sm"}
                >
                  <DialogTitle id="form-dialog-title">
                    Delete Ministry
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Delete {this.state.deleteMinistryData.name} Ministry?
                    </DialogContentText>
                    {Helpers.handleError(
                      MessageForAction.Delete,
                      this.state.ministryMessages
                    )}
                  </DialogContent>
                  <DialogActions>
                    <Button
                      color="primary"
                      onClick={this.deleteMinistry.bind(this)}
                    >
                      Yes, delete
                    </Button>
                    <Button
                      color="secondary"
                      onClick={this.toggleDeleteMinistryModal.bind(this)}
                    >
                      Cancel
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  open={this.state.isEditMinistryModalOpen}
                  onClose={this.toggleEditMinistryModal.bind(this)}
                  aria-labelledby="form-dialog-title"
                >
                  <DialogTitle id="form-dialog-title">
                    Edit Ministry
                  </DialogTitle>
                  <DialogContent>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6}>
                        <ReferenceEntitySelector
                          componentId="editPersonSelector"
                          selectedItem={this.state.editMinistryData.person}
                          label="Person"
                          url="Person/GetItemsForReferenceControl"
                          required={true}
                          onReferenceEntitySelectEvent={
                            this.handleEditPersonSelect
                          }
                          handleException={(error) =>
                            this.handleCatch(error, MessageForAction.Update)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <ReferenceEntitySelector
                          componentId="editMinistryTypeSelector"
                          selectedItem={
                            this.state.editMinistryData.ministryType
                          }
                          label="MinistryType"
                          url="MinistryType/GetItemsForReferenceControl"
                          required={true}
                          onReferenceEntitySelectEvent={
                            this.handleEditMinistryTypeSelect
                          }
                          handleException={(error) =>
                            this.handleCatch(error, MessageForAction.Update)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            autoOk
                            disableFuture
                            required
                            openTo="year"
                            views={["year", "month", "date"]}
                            variant="inline"
                            format="MM/dd/yyyy"
                            id="editOrdainedStartDate"
                            label="OrdainedStartDate"
                            value={
                              this.state.editMinistryData.ordainedStartDate
                            }
                            onChange={(e) => {
                              let { editMinistryData } = this.state;
                              editMinistryData.ordainedStartDate = Helpers.getDateWithUTCZero(
                                e
                              );
                              this.setState({ editMinistryData });
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            autoOk
                            disableFuture
                            openTo="year"
                            views={["year", "month", "date"]}
                            variant="inline"
                            format="MM/dd/yyyy"
                            id="editOrdainedEndDate"
                            label="OrdainedEndDate"
                            value={this.state.editMinistryData.ordainedEndDate}
                            onChange={(e) => {
                              let { editMinistryData } = this.state;
                              editMinistryData.ordainedEndDate = Helpers.getDateWithUTCZero(
                                e
                              );
                              this.setState({ editMinistryData });
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          label="Is active"
                          labelPlacement="start"
                          style={{ marginLeft: 0 }}
                          control={
                            <Switch
                              checked={this.state.editMinistryData.isActive}
                              value={this.state.editMinistryData.isActive}
                              onChange={(e) => {
                                let { editMinistryData } = this.state;
                                editMinistryData.isActive = e.target.checked;
                                this.setState({ editMinistryData });
                              }}
                              color="primary"
                              inputProps={{ "aria-label": "primary checkbox" }}
                            />
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        {Helpers.handleError(
                          MessageForAction.Update,
                          this.state.ministryMessages
                        )}
                      </Grid>
                    </Grid>
                  </DialogContent>{" "}
                  <DialogActions>
                    <Button
                      color="primary"
                      onClick={this.updateMinistry.bind(this)}
                    >
                      Update
                    </Button>
                    <Button
                      color="secondary"
                      onClick={this.toggleEditMinistryModal.bind(this)}
                    >
                      Cancel
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  open={this.state.isErrorDialogOpen}
                  onClose={() => {
                    this.setState({
                      isErrorDialogOpen: false,
                      ministryMessages: [],
                    });
                  }}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  fullWidth={true}
                  maxWidth={"sm"}
                >
                  <DialogTitle id="form-dialog-title">
                    Error occurred
                  </DialogTitle>
                  <DialogContent>
                    {Helpers.handleError(
                      MessageForAction.Read,
                      this.state.ministryMessages
                    )}
                  </DialogContent>
                  <DialogActions>
                    <Button
                      color="secondary"
                      onClick={() => {
                        this.setState({
                          isErrorDialogOpen: false,
                          ministryMessages: [],
                        });
                      }}
                    >
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>
                <Tooltip title="Reload">
                  <IconButton onClick={this.refreshTableData.bind(this)}>
                    <Refresh className={classes.block} color="inherit" />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Action</TableCell>
                <TableCell>Person</TableCell>
                <TableCell>MinistryType</TableCell>
                <TableCell>OrdainedStartDate</TableCell>
                <TableCell>OrdainedEndDate</TableCell>
                <TableCell>IsActive</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{ministries}</TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10]}
                  count={this.state.ministriesCount}
                  rowsPerPage={10}
                  page={this.state.pageNumber}
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    native: true,
                  }}
                  onChangePage={this.handleChangePage.bind(this)}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Paper>
    );
  }

  private handleCatch(error: any, messageForAction: MessageForAction) {
    let { ministryMessages } = this.state;
    if (error.toString().includes("Network Error")) {
      ministryMessages.push(
        new MessageInfo(
          messageForAction,
          "Server unavailable, try again later",
          503
        )
      );
    } else if (
      error.toString().includes("Request failed with status code 401")
    ) {
      this.authService.login();
    } else if (
      error.toString().includes("Request failed with status code 500")
    ) {
      ministryMessages.push(
        new MessageInfo(messageForAction, error.toString(), 500)
      );
    } else if (error.response.data.hasOwnProperty("errors")) {
      error.response.data.errors.forEach((errorItem: any) => {
        ministryMessages.push(
          new MessageInfo(
            messageForAction,
            errorItem.message,
            error.response.status
          )
        );
      });
    } else if (error.response.data.hasOwnProperty("Message")) {
      ministryMessages.push(
        new MessageInfo(
          messageForAction,
          error.response.data.Message,
          error.response.status
        )
      );
    } else {
      ministryMessages.push(
        new MessageInfo(messageForAction, error, error.response.status)
      );
    }
    this.setState({ ministryMessages });
  }
}

export default withStyles(styles)(Ministry);
