import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import { Switch, Route } from "react-router-dom";

const lightColor = "rgba(255, 255, 255, 0.7)";

const styles = (theme: Theme) =>
  createStyles({
    secondaryBar: {
      zIndex: 0,
    },
    menuButton: {
      marginLeft: -theme.spacing(1),
    },
    iconButtonAvatar: {
      padding: 4,
    },
    link: {
      textDecoration: "none",
      color: lightColor,
      "&:hover": {
        color: theme.palette.common.white,
      },
    },
    button: {
      borderColor: lightColor,
    },
  });

interface HeaderProps extends WithStyles<typeof styles> {
  onDrawerToggle: () => void;
  logout: () => void;
}

function Header(props: HeaderProps) {
  const { classes, onDrawerToggle, logout } = props;
  return (
    <React.Fragment>
      <AppBar color="primary" position="sticky" elevation={0}>
        <Toolbar>
          <Grid container spacing={1} alignItems="center">
            <Hidden smUp>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={onDrawerToggle}
                  className={classes.menuButton}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>
            <Grid item xs>
              <Switch>
                <Route
                  path="/eventtype"
                  exact
                  component={() => (
                    <Typography
                      color="inherit"
                      align="left"
                      variant="h5"
                      component="h1"
                    >
                      Event type
                    </Typography>
                  )}
                />
                <Route
                  path="/district"
                  exact
                  component={() => (
                    <Typography
                      color="inherit"
                      align="left"
                      variant="h5"
                      component="h1"
                    >
                      District
                    </Typography>
                  )}
                />
                <Route
                  path="/buildingtype"
                  exact
                  component={() => (
                    <Typography
                      color="inherit"
                      align="left"
                      variant="h5"
                      component="h1"
                    >
                      Building type
                    </Typography>
                  )}
                />
                <Route
                  path="/ministrytype"
                  exact
                  component={() => (
                    <Typography
                      color="inherit"
                      align="left"
                      variant="h5"
                      component="h1"
                    >
                      Ministry type
                    </Typography>
                  )}
                />
                <Route
                  path="/administrativeuser"
                  exact
                  component={() => (
                    <Typography
                      color="inherit"
                      align="left"
                      variant="h5"
                      component="h1"
                    >
                      Administrative user
                    </Typography>
                  )}
                />
                <Route
                  path="/building"
                  exact
                  component={() => (
                    <Typography
                      color="inherit"
                      align="left"
                      variant="h5"
                      component="h1"
                    >
                      Building
                    </Typography>
                  )}
                />
                <Route
                  path="/event"
                  exact
                  component={() => (
                    <Typography
                      color="inherit"
                      align="left"
                      variant="h5"
                      component="h1"
                    >
                      Event
                    </Typography>
                  )}
                />
                <Route
                  path="/person"
                  exact
                  component={() => (
                    <Typography
                      color="inherit"
                      align="left"
                      variant="h5"
                      component="h1"
                    >
                      Person
                    </Typography>
                  )}
                />
                <Route
                  path="/ministry"
                  exact
                  component={() => (
                    <Typography
                      color="inherit"
                      align="left"
                      variant="h5"
                      component="h1"
                    >
                      Ministry
                    </Typography>
                  )}
                />
                <Route
                  path="/buildingMinistry"
                  exact
                  component={() => (
                    <Typography
                      color="inherit"
                      align="left"
                      variant="h5"
                      component="h1"
                    >
                      BuildingMinistry
                    </Typography>
                  )}
                />
                <Route
                  path="/household"
                  component={() => (
                    <Typography
                      color="inherit"
                      align="left"
                      variant="h5"
                      component="h1"
                    >
                      Household
                    </Typography>
                  )}
                />
                <Route
                  path="/householdEditSuggestion"
                  exact
                  component={() => (
                    <Typography
                      color="inherit"
                      align="left"
                      variant="h5"
                      component="h1"
                    >
                      HouseholdEditSuggestion
                    </Typography>
                  )}
                />
                <Route
                  path="/householdMember"
                  exact
                  component={() => (
                    <Typography
                      color="inherit"
                      align="left"
                      variant="h5"
                      component="h1"
                    >
                      HouseholdMember
                    </Typography>
                  )}
                />
                <Route
                  path="/marriage"
                  exact
                  component={() => (
                    <Typography
                      color="inherit"
                      align="left"
                      variant="h5"
                      component="h1"
                    >
                      Marriage
                    </Typography>
                  )}
                />
                HouseholdMember
              </Switch>
            </Grid>
            <Grid item>
              <Button variant="contained" color="primary" onClick={logout}>
                Logout
              </Button>
            </Grid>
            <Grid item>
              <IconButton color="inherit" className={classes.iconButtonAvatar}>
                <Avatar src="/static/images/avatar/1.jpg" alt="My Avatar" />
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}

export default withStyles(styles)(Header);
