import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  AppBar,
  Toolbar,
  Paper,
  Grid,
  Button,
  TextField,
  Tooltip,
  IconButton,
  Switch,
  FormControlLabel,
  TableFooter,
  TablePagination,
} from "@material-ui/core";
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Delete, Edit, Add, Search, Refresh } from "@material-ui/icons";
import Axios from "axios";
import { IdentityConstants } from "../../helpers/IdentityConstants";
import { AuthService } from "../../services/AuthService";
import { MessageInfo } from "../MessageInfo";
import { MessageForAction } from "../MessageForAction";
import { ReferenceEntitySelector } from "../ReferenceEntitySelector";
import Helpers from "../../helpers/Helpers";
import { IBuildingMinistry } from "./dataTransferableObjects/IBuildingMinistry";
import { IBuildingMinistrySave } from "./dataTransferableObjects/IBuildingMinistrySave";
import { IBuildingMinistryState } from "./componentStates/IBuildingMinistryState";
import { AppConstants } from "../../helpers/AppConstants";
import _ from "lodash";

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      margin: "auto",
      overflow: "hidden",
    },
    searchBar: {
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    },
    searchInput: {
      fontSize: theme.typography.fontSize,
    },
    block: {
      display: "block",
    },
  });

export interface IBuildingMinistryComponentProps
  extends WithStyles<typeof styles> {}

class BuildingMinistry extends React.Component<
  IBuildingMinistryComponentProps,
  IBuildingMinistryState
> {
  private authService: AuthService;
  constructor(props: IBuildingMinistryComponentProps) {
    super(props);
    this.authService = new AuthService();
    this.state = {
      buildingMinistries: [],
      newBuildingMinistryData: this.resetBuildingMinistry(),
      editBuildingMinistryData: this.resetBuildingMinistry(),
      deleteBuildingMinistryData: {
        id: "",
        name: "",
      },
      isNewBuildingMinistryModalOpen: false,
      isEditBuildingMinistryModalOpen: false,
      isDeleteBuildingMinistryConfirmModalOpen: false,
      pageNumber: 0,
      buildingMinistriesCount: 0,
      searchText: "",
      buildingMinistryMessages: [],
      isErrorDialogOpen: false,
    };
    this.debounceRefreshBuildingMinistries = _.debounce(
      this.debounceRefreshBuildingMinistries,
      1000
    );
  }

  debounceRefreshBuildingMinistries() {
    this.refreshBuildingMinistries();
  }

  resetBuildingMinistry() {
    let data: IBuildingMinistry = {
      id: "",
      building: null,
      ministry: null,
      startDate: null,
      endDate: null,
      isActive: true,
    };
    return data;
  }

  componentDidMount() {
    this.refreshTableData();
  }

  refreshTableData() {
    this.refreshBuildingMinistries();
  }

  refreshBuildingMinistries() {
    this.setState({ buildingMinistryMessages: [] });
    this.authService.getUser().then((user) => {
      if (user && user.access_token) {
        const headers = Helpers.getHeaders(user.access_token);
        let searchDTO = {
          searchText: this.state.searchText,
          pageNumber: this.state.pageNumber,
          pageSize: AppConstants.TablePageSize,
        };
        Axios.post(
          `${IdentityConstants.apiRoot}BuildingMinistry/GetItems`,
          searchDTO,
          { headers }
        )
          .then((response) => {
            this.setState({
              buildingMinistries: response.data.items,
              buildingMinistriesCount: response.data.searchItemsCount,
            });
          })
          .catch((error) => {
            this.handleCatch(error, MessageForAction.Read);
            this.setState({ isErrorDialogOpen: true });
          });
      }
    });
  }

  removeMessages(actionType: MessageForAction) {
    let filteredMessages = this.state.buildingMinistryMessages.filter(
      (m) => m.messageForAction !== actionType
    );
    this.setState({ buildingMinistryMessages: filteredMessages });
  }

  handleNewBuildingSelect = (selectedBuilding: any) => {
    let { newBuildingMinistryData } = this.state;
    newBuildingMinistryData.building = selectedBuilding;
    this.setState({ newBuildingMinistryData });
  };

  handleNewMinistrySelect = (selectedMinistry: any) => {
    let { newBuildingMinistryData } = this.state;
    newBuildingMinistryData.ministry = selectedMinistry;
    this.setState({ newBuildingMinistryData });
  };

  handleEditBuildingSelect = (selectedBuilding: any) => {
    let { editBuildingMinistryData } = this.state;
    editBuildingMinistryData.building = selectedBuilding;
    this.setState({ editBuildingMinistryData });
  };

  handleEditMinistrySelect = (selectedMinistryType: any) => {
    let { editBuildingMinistryData } = this.state;
    editBuildingMinistryData.ministry = selectedMinistryType;
    this.setState({ editBuildingMinistryData });
  };

  toggleNewBuildingMinistryModal() {
    this.setState({
      isNewBuildingMinistryModalOpen: !this.state
        .isNewBuildingMinistryModalOpen,
    });
  }

  toggleEditBuildingMinistryModal() {
    this.setState({
      isEditBuildingMinistryModalOpen: !this.state
        .isEditBuildingMinistryModalOpen,
    });
    this.removeMessages(MessageForAction.Update);
  }

  toggleDeleteBuildingMinistryModal() {
    this.setState({
      isDeleteBuildingMinistryConfirmModalOpen: !this.state
        .isDeleteBuildingMinistryConfirmModalOpen,
    });
    this.removeMessages(MessageForAction.Delete);
  }

  addNewBuildingMinistry() {
    this.removeMessages(MessageForAction.Create);
    this.authService.getUser().then((user) => {
      if (user && user.access_token) {
        const headers = Helpers.getHeaders(user.access_token);
        Axios.post(
          `${IdentityConstants.apiRoot}BuildingMinistry`,
          this.CopyFrom(this.state.newBuildingMinistryData),
          { headers }
        )
          .then((_) => {
            this.refreshTableData();
            this.setState({
              isNewBuildingMinistryModalOpen: false,
              newBuildingMinistryData: this.resetBuildingMinistry(),
            });
          })
          .catch((error) => {
            this.handleCatch(error, MessageForAction.Create);
          });
      }
    });
  }

  CopyFrom(buildingMinistry: IBuildingMinistry): IBuildingMinistrySave {
    return {
      buildingId: buildingMinistry.building?.id,
      ministryId: buildingMinistry.ministry?.id,
      startDate: buildingMinistry.startDate,
      endDate: buildingMinistry.endDate,
      isActive: buildingMinistry.isActive,
    };
  }

  updateBuildingMinistry() {
    this.removeMessages(MessageForAction.Update);
    this.authService.getUser().then((user) => {
      if (user && user.access_token) {
        const headers = Helpers.getHeaders(user.access_token);
        Axios.put(
          `${IdentityConstants.apiRoot}BuildingMinistry/${this.state.editBuildingMinistryData.id}`,
          this.CopyFrom(this.state.editBuildingMinistryData),
          { headers }
        )
          .then((_) => {
            this.refreshBuildingMinistries();
            this.setState({
              isEditBuildingMinistryModalOpen: false,
              editBuildingMinistryData: this.resetBuildingMinistry(),
            });
          })
          .catch((error) => {
            this.handleCatch(error, MessageForAction.Update);
          });
      }
    });
  }

  deleteBuildingMinistry() {
    this.removeMessages(MessageForAction.Delete);
    this.authService.getUser().then((user) => {
      if (user && user.access_token) {
        const headers = Helpers.getHeaders(user.access_token);
        Axios.delete(
          `${IdentityConstants.apiRoot}BuildingMinistry/${this.state.deleteBuildingMinistryData.id}`,
          { headers }
        )
          .then(() => {
            this.refreshTableData();
            this.setState({
              isDeleteBuildingMinistryConfirmModalOpen: false,
              deleteBuildingMinistryData: {
                id: "",
                name: "",
              },
            });
          })
          .catch((error) => {
            this.handleCatch(error, MessageForAction.Delete);
          });
      }
    });
  }

  editBuildingMinistrySetState(
    id: string,
    building: any,
    ministry: any,
    startDate: Date,
    endDate: Date,
    isActive: boolean
  ) {
    let { editBuildingMinistryData } = this.state;
    editBuildingMinistryData.id = id;
    editBuildingMinistryData.building = building;
    editBuildingMinistryData.ministry = ministry;
    editBuildingMinistryData.startDate = startDate;
    editBuildingMinistryData.endDate = endDate;
    editBuildingMinistryData.isActive = isActive;
    this.setState({
      editBuildingMinistryData: editBuildingMinistryData,
      isEditBuildingMinistryModalOpen: !this.state
        .isEditBuildingMinistryModalOpen,
    });
  }

  deleteBuildingMinistrySetState(id: any, name: any) {
    let { deleteBuildingMinistryData } = this.state;
    deleteBuildingMinistryData.id = id;
    deleteBuildingMinistryData.name = name;
    this.setState({
      deleteBuildingMinistryData,
      isDeleteBuildingMinistryConfirmModalOpen: !this.state
        .isDeleteBuildingMinistryConfirmModalOpen,
    });
  }

  handleChangePage(
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) {
    this.setState({ pageNumber: newPage });
    this.debounceRefreshBuildingMinistries();
  }

  public render() {
    const { classes } = this.props;
    let buildingMinistries = this.state.buildingMinistries.map(
      (buildingMinistry) => {
        return (
          <TableRow key={buildingMinistry.id}>
            <TableCell>
              <Tooltip title="Edit">
                <IconButton
                  onClick={this.editBuildingMinistrySetState.bind(
                    this,
                    buildingMinistry.id,
                    buildingMinistry.building,
                    buildingMinistry.ministry,
                    buildingMinistry.startDate,
                    buildingMinistry.endDate,
                    buildingMinistry.isActive
                  )}
                >
                  <Edit />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete">
                <IconButton
                  onClick={this.deleteBuildingMinistrySetState.bind(
                    this,
                    buildingMinistry.id,
                    `${buildingMinistry.building?.name} ${buildingMinistry.ministry?.name}`
                  )}
                >
                  <Delete />
                </IconButton>
              </Tooltip>
            </TableCell>
            <TableCell>{buildingMinistry.building?.name}</TableCell>
            <TableCell>{buildingMinistry.ministry?.title}</TableCell>
            <TableCell>
              {buildingMinistry.startDate === null
                ? null
                : new Date(buildingMinistry.startDate).toDateString()}
            </TableCell>
            <TableCell>
              {buildingMinistry.endDate === null
                ? null
                : new Date(buildingMinistry.endDate).toDateString()}
            </TableCell>
            <TableCell>
              <Switch
                checked={buildingMinistry.isActive}
                color="primary"
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            </TableCell>
          </TableRow>
        );
      }
    );
    return (
      <Paper className={classes.paper}>
        <AppBar
          className={classes.searchBar}
          position="static"
          color="default"
          elevation={0}
        >
          <Toolbar>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <Search className={classes.block} color="inherit" />
              </Grid>
              <Grid item xs>
                <TextField
                  fullWidth
                  placeholder="Search by Ministry first and last name, ministry type, or building name"
                  value={this.state.searchText}
                  onChange={(e) => {
                    this.setState({ searchText: e.target.value });
                  }}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      this.setState({ pageNumber: 0 });
                      this.refreshTableData();
                      e.preventDefault();
                    }
                  }}
                  InputProps={{
                    disableUnderline: true,
                    className: classes.searchInput,
                  }}
                />
              </Grid>
              <Grid item>
                <Tooltip title="Add">
                  <IconButton
                    onClick={this.toggleNewBuildingMinistryModal.bind(this)}
                  >
                    <Add />
                  </IconButton>
                </Tooltip>
                <Dialog
                  open={this.state.isNewBuildingMinistryModalOpen}
                  onClose={this.toggleNewBuildingMinistryModal.bind(this)}
                  aria-labelledby="form-dialog-title"
                >
                  <DialogTitle id="form-dialog-title">
                    Add BuildingMinistry
                  </DialogTitle>
                  <DialogContent>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6}>
                        <ReferenceEntitySelector
                          componentId="addBuildingSelector"
                          selectedItem={
                            this.state.newBuildingMinistryData.building
                          }
                          label="Building"
                          url="Building/GetAllActiveChurchesForReferenceControl"
                          required={true}
                          onReferenceEntitySelectEvent={
                            this.handleNewBuildingSelect
                          }
                          handleException={(error) =>
                            this.handleCatch(error, MessageForAction.Create)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <ReferenceEntitySelector
                          componentId="addMinistrySelector"
                          selectedItem={
                            this.state.newBuildingMinistryData.ministry
                          }
                          label="Ministry"
                          url="Ministry/GetItemsForReferenceControl"
                          required={true}
                          onReferenceEntitySelectEvent={
                            this.handleNewMinistrySelect
                          }
                          handleException={(error) =>
                            this.handleCatch(error, MessageForAction.Create)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            autoOk
                            disableFuture
                            openTo="year"
                            views={["year", "month", "date"]}
                            variant="inline"
                            format="MM/dd/yyyy"
                            id="addStartDate"
                            label="StartDate"
                            value={this.state.newBuildingMinistryData.startDate}
                            onChange={(e) => {
                              let { newBuildingMinistryData } = this.state;
                              newBuildingMinistryData.startDate = Helpers.getDateWithUTCZero(
                                e
                              );
                              this.setState({ newBuildingMinistryData });
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            autoOk
                            disableFuture
                            openTo="year"
                            views={["year", "month", "date"]}
                            variant="inline"
                            format="MM/dd/yyyy"
                            id="addEndDate"
                            label="EndDate"
                            value={this.state.newBuildingMinistryData.endDate}
                            onChange={(e) => {
                              let { newBuildingMinistryData } = this.state;
                              newBuildingMinistryData.endDate = Helpers.getDateWithUTCZero(
                                e
                              );
                              this.setState({ newBuildingMinistryData });
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          label="Is active"
                          labelPlacement="start"
                          style={{ marginLeft: 0 }}
                          control={
                            <Switch
                              checked={
                                this.state.newBuildingMinistryData.isActive
                              }
                              value={
                                this.state.newBuildingMinistryData.isActive
                              }
                              onChange={(e) => {
                                let { newBuildingMinistryData } = this.state;
                                newBuildingMinistryData.isActive =
                                  e.target.checked;
                                this.setState({ newBuildingMinistryData });
                              }}
                              color="primary"
                              inputProps={{ "aria-label": "primary checkbox" }}
                            />
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        {Helpers.handleError(
                          MessageForAction.Create,
                          this.state.buildingMinistryMessages
                        )}
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      color="primary"
                      onClick={this.addNewBuildingMinistry.bind(this)}
                    >
                      Add
                    </Button>
                    <Button
                      color="secondary"
                      onClick={this.toggleNewBuildingMinistryModal.bind(this)}
                    >
                      Cancel
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  open={this.state.isDeleteBuildingMinistryConfirmModalOpen}
                  onClose={this.toggleDeleteBuildingMinistryModal.bind(this)}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  fullWidth={true}
                  maxWidth={"sm"}
                >
                  <DialogTitle id="form-dialog-title">
                    Delete BuildingMinistry
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Delete {this.state.deleteBuildingMinistryData.name}{" "}
                      BuildingMinistry?
                    </DialogContentText>
                    {Helpers.handleError(
                      MessageForAction.Delete,
                      this.state.buildingMinistryMessages
                    )}
                  </DialogContent>
                  <DialogActions>
                    <Button
                      color="primary"
                      onClick={this.deleteBuildingMinistry.bind(this)}
                    >
                      Yes, delete
                    </Button>
                    <Button
                      color="secondary"
                      onClick={this.toggleDeleteBuildingMinistryModal.bind(
                        this
                      )}
                    >
                      Cancel
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  open={this.state.isEditBuildingMinistryModalOpen}
                  onClose={this.toggleEditBuildingMinistryModal.bind(this)}
                  aria-labelledby="form-dialog-title"
                >
                  <DialogTitle id="form-dialog-title">
                    Edit BuildingMinistry
                  </DialogTitle>
                  <DialogContent>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6}>
                        <ReferenceEntitySelector
                          componentId="editBuildingSelector"
                          selectedItem={
                            this.state.editBuildingMinistryData.building
                          }
                          label="Building"
                          url="Building/GetAllActiveChurchesForReferenceControl"
                          required={true}
                          onReferenceEntitySelectEvent={
                            this.handleEditBuildingSelect
                          }
                          handleException={(error) =>
                            this.handleCatch(error, MessageForAction.Update)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <ReferenceEntitySelector
                          componentId="editMinistrySelector"
                          selectedItem={
                            this.state.editBuildingMinistryData.ministry
                          }
                          label="Ministry"
                          url="Ministry/GetItemsForReferenceControl"
                          required={true}
                          onReferenceEntitySelectEvent={
                            this.handleEditMinistrySelect
                          }
                          handleException={(error) =>
                            this.handleCatch(error, MessageForAction.Update)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            autoOk
                            disableFuture
                            openTo="year"
                            views={["year", "month", "date"]}
                            variant="inline"
                            format="MM/dd/yyyy"
                            id="addStartDate"
                            label="StartDate"
                            value={
                              this.state.editBuildingMinistryData.startDate
                            }
                            onChange={(e) => {
                              let { editBuildingMinistryData } = this.state;
                              editBuildingMinistryData.startDate = Helpers.getDateWithUTCZero(
                                e
                              );
                              this.setState({ editBuildingMinistryData });
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            autoOk
                            disableFuture
                            openTo="year"
                            views={["year", "month", "date"]}
                            variant="inline"
                            format="MM/dd/yyyy"
                            id="addEndDate"
                            label="EndDate"
                            value={this.state.editBuildingMinistryData.endDate}
                            onChange={(e) => {
                              let { editBuildingMinistryData } = this.state;
                              editBuildingMinistryData.endDate = Helpers.getDateWithUTCZero(
                                e
                              );
                              this.setState({ editBuildingMinistryData });
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          label="Is active"
                          labelPlacement="start"
                          style={{ marginLeft: 0 }}
                          control={
                            <Switch
                              checked={
                                this.state.editBuildingMinistryData.isActive
                              }
                              value={
                                this.state.editBuildingMinistryData.isActive
                              }
                              onChange={(e) => {
                                let { editBuildingMinistryData } = this.state;
                                editBuildingMinistryData.isActive =
                                  e.target.checked;
                                this.setState({ editBuildingMinistryData });
                              }}
                              color="primary"
                              inputProps={{ "aria-label": "primary checkbox" }}
                            />
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        {Helpers.handleError(
                          MessageForAction.Update,
                          this.state.buildingMinistryMessages
                        )}
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      color="primary"
                      onClick={this.updateBuildingMinistry.bind(this)}
                    >
                      Update
                    </Button>
                    <Button
                      color="secondary"
                      onClick={this.toggleEditBuildingMinistryModal.bind(this)}
                    >
                      Cancel
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  open={this.state.isErrorDialogOpen}
                  onClose={() => {
                    this.setState({
                      isErrorDialogOpen: false,
                      buildingMinistryMessages: [],
                    });
                  }}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  fullWidth={true}
                  maxWidth={"sm"}
                >
                  <DialogTitle id="form-dialog-title">
                    Error occurred
                  </DialogTitle>
                  <DialogContent>
                    {Helpers.handleError(
                      MessageForAction.Read,
                      this.state.buildingMinistryMessages
                    )}
                  </DialogContent>
                  <DialogActions>
                    <Button
                      color="secondary"
                      onClick={() => {
                        this.setState({
                          isErrorDialogOpen: false,
                          buildingMinistryMessages: [],
                        });
                      }}
                    >
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>
                <Tooltip title="Reload">
                  <IconButton onClick={this.refreshTableData.bind(this)}>
                    <Refresh className={classes.block} color="inherit" />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Action</TableCell>
                <TableCell>Building</TableCell>
                <TableCell>Ministry</TableCell>
                <TableCell>Start date</TableCell>
                <TableCell>End date</TableCell>
                <TableCell>IsActive</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{buildingMinistries}</TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10]}
                  count={this.state.buildingMinistriesCount}
                  rowsPerPage={10}
                  page={this.state.pageNumber}
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    native: true,
                  }}
                  onChangePage={this.handleChangePage.bind(this)}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Paper>
    );
  }

  private handleCatch(error: any, messageForAction: MessageForAction) {
    let { buildingMinistryMessages } = this.state;
    if (error.toString().includes("Network Error")) {
      buildingMinistryMessages.push(
        new MessageInfo(
          messageForAction,
          "Server unavailable, try again later",
          503
        )
      );
    } else if (
      error.toString().includes("Request failed with status code 401")
    ) {
      this.authService.login();
    } else if (
      error.toString().includes("Request failed with status code 500")
    ) {
      buildingMinistryMessages.push(
        new MessageInfo(messageForAction, error.toString(), 500)
      );
    } else if (error.response.data.hasOwnProperty("errors")) {
      error.response.data.errors.forEach((errorItem: any) => {
        buildingMinistryMessages.push(
          new MessageInfo(
            messageForAction,
            errorItem.message,
            error.response.status
          )
        );
      });
    } else if (error.response.data.hasOwnProperty("Message")) {
      buildingMinistryMessages.push(
        new MessageInfo(
          messageForAction,
          error.response.data.Message,
          error.response.status
        )
      );
    } else {
      buildingMinistryMessages.push(
        new MessageInfo(messageForAction, error, error.response.status)
      );
    }
    this.setState({ buildingMinistryMessages });
  }
}

export default withStyles(styles)(BuildingMinistry);
